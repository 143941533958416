import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React from "react";

const CardDropdown = ({ optionList, data, iconColor, displayIconOfAdded }) => {
  return (
    <div>
      <div
        className="text-end p-0 position-absolute"
        style={{
          right: "10px",
          top: "7px",
        }}>
        <div
          className={`dropdown dropdownClub`}
          style={{
            pointerEvents: displayIconOfAdded ? "none" : "",
            cursor: displayIconOfAdded ? "not-allowed" : "pointer",
          }}>
          <span
            className={`float-end text-body iconColor`}
            data-bs-toggle="dropdown">
            <FeatherIcon
              icon={"more-horizontal"}
              size={24}
              // className="text-black"
              className={
                iconColor
                  ? `text-${iconColor}`
                  : `text-black ${displayIconOfAdded ? "text-muted" : ""}`
              }
            />
          </span>
          <ul className="dropdown-menu dropdownClubMenu">
            {optionList &&
              optionList.map((item, i) => {
                return (
                  <li
                    key={i + 1}
                    className="dropdown-item dropdownItem poppins fw-bold px-2 mx-2"
                    data-bs-toggle={item?.attribute && "modal"}
                    data-bs-target={item?.attribute && "#myModalEditProfile"}
                    onClick={() => {
                      item?.onClick && item?.onClick(data);
                    }}>
                    {/* <Link className="dropdown-item dropdownItem poppins fw-bold px-2 mx-2"> */}
                    <FeatherIcon
                      icon={item?.icon && item?.icon}
                      size={15}
                      className="me-2"
                    />
                    {item?.title}
                    {/* </Link> */}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CardDropdown;
