export const actions = {
  USER_AUTH_REQUEST: "USER_AUTH_REQUEST",
  USER_AUTH_SUCCESS: "USER_AUTH_SUCCESS",

  USER_TOKEN_REQUEST: "USER_TOKEN_REQUEST",
  USER_TOKEN_SUCCESS: "USER_TOKEN_SUCCESS",

  USER_REGISTRATION_REQUEST: "USER_REGISTRATION_REQUEST",
  USER_REGISTRATION_SUCCESS: "USER_REGISTRATION_SUCCESS",

  USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",

  USER_LOGOUT_REQUEST: "USER_LOGOUT_REQUEST",
  USER_LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",

  USER_DETAIL_REQUEST: "USER_DETAIL_REQUEST",
  USER_DETAIL_SUCCESS: "USER_DETAIL_SUCCESS",

  USER_UPDATE_REQUEST: "USER_UPDATE_REQUEST",
  USER_UPDATE_SUCCESS: "USER_UPDATE_SUCCESS",

  USER_UPDATE_PASSWORD_REQUEST: "USER_UPDATE_PASSWORD_REQUEST",
  USER_UPDATE_PASSWORD_SUCCESS: "USER_UPDATE_PASSWORD_SUCCESS",

  CATCH_ERROR: "CATCH_ERROR",

  userRegistration: (data, navigate) => {
    return {
      type: actions.USER_REGISTRATION_REQUEST,
      data,
      navigate,
    };
  },

  userLogin: (data, navigate) => {
    return {
      type: actions.USER_LOGIN_REQUEST,
      data,
      navigate,
    };
  },

  userDetail: (data, navigate) => {
    return {
      type: actions.USER_DETAIL_REQUEST,
      data,
      navigate,
    };
  },

  userUpdate: (data, endpoint) => {
    return {
      type: actions.USER_UPDATE_REQUEST,
      data,
      endpoint,
    };
  },

  updatePassword: (data, navigate) => {
    return {
      type: actions.USER_UPDATE_PASSWORD_REQUEST,
      data,
      navigate,
    };
  },

  userLogout: (navigate) => {
    return {
      type: actions.USER_LOGOUT_REQUEST,
      navigate,
    };
  },
};
