import { all, fork, put, takeLatest } from "redux-saga/effects";
import { getQuery, postQuery } from "utils/api";
import { Toast } from "utils/toast";
import { actions } from "./action";
import { actions as loaderAction } from "redux/Loader/action";

export function* sendMessage() {
  yield takeLatest(actions.SEND_MESSAGE_REQUEST, function* ({ data }) {
    try {
      const response = yield postQuery(`sendMessage`, data);
      if (!response.data.error) {
        yield put({
          type: actions.SEND_MESSAGE_SUCCESS,
          payload: response.data.result,
        });
        Toast("success", "Message sent successfully");
      }
    } catch (e) {
      console.log(e);
    }
  });
}

export function* getMessages() {
  yield takeLatest(actions.GET_MESSAGES_REQUEST, function* ({ data }) {
    try {
      const response = yield getQuery(`getMessage?userId=${data.id}`);
      if (!response.data.error) {
        yield put({
          type: actions.GET_MESSAGES_SUCCESS,
          payload: response.data.result,
        });
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    } catch (e) {
      console.log(e);
    }
  });
}

export default function* messageSaga() {
  yield all([fork(getMessages), fork(sendMessage)]);
}
