import React from "react";
import { useSelector } from "react-redux";

function SelectionCourse({ player, setPlayer }) {
  const { myCourses } = useSelector((state) => state.course);
  const { workExperiences } = useSelector((state) => state.workExperience);

  const onSetCourse = (e, data, courseId) => {
    let playersDataIndex = player.findIndex(
      (player) => player?.email === data?.email
    );
    if (e.target.checked) {
      player[playersDataIndex].coursesId = [...data.coursesId, courseId];
      setPlayer([...player]);
    } else {
      const courseIds = data.coursesId.filter((course) => {
        return course !== courseId;
      });
      player[playersDataIndex].coursesId = courseIds;
      setPlayer([...player]);
    }
  };

  const onSetWorkExperience = (e, data, workExpId) => {
    let playersDataIndex = player.findIndex(
      (player) => player?.email === data?.email
    );
    if (e.target.checked) {
      player[playersDataIndex].workExperiencesId = [
        ...data.workExperiencesId,
        workExpId,
      ];
      setPlayer([...player]);
    } else {
      const workExpIds = data.workExperiencesId.filter((workExp) => {
        return workExp !== workExpId;
      });
      player[playersDataIndex].workExperiencesId = workExpIds;
      setPlayer([...player]);
    }
  };

  return (
    <div>
      <p className="poppins fw-bold">
        Please select the courses that the learner will study.
      </p>
      {
        <div className="playerList my-3 overflow-auto scrollable-element">
          <table className="table table-hover table-striped text-center table-bordered">
            <thead>
              <tr>
                <th scope="col">Forename</th>
                <th scope="col">Email</th>
                {myCourses?.length > 0 &&
                  myCourses?.map((item) => {
                    return (
                      <th scope="col" key={item?.id}>
                        {item?.title}
                      </th>
                    );
                  })}
                {workExperiences?.length > 0 &&
                  workExperiences?.map((item) => {
                    return (
                      <th scope="col" key={item?.id}>
                        <p className="wrkExpP">WORK EXP</p>
                        <hr className="m-0" />
                        {item?.title}
                      </th>
                    );
                  })}
                {/* <th scope="col">{userType} Status</th> */}
              </tr>
            </thead>
            <tbody>
              {player &&
                player?.length > 0 &&
                player?.map((data) => {
                  return (
                    <tr key={data?.email}>
                      <td>{data?.name ? data?.name.split(" ")[0] : ""}</td>
                      <td>{data?.email ? data?.email : ""}</td>
                      {myCourses?.length > 0 &&
                        myCourses?.map((item) => {
                          return (
                            <td>
                              <label className="container-checkbox">
                                <input
                                  type="checkbox"
                                  onChange={(e) => {
                                    onSetCourse(e, data, item?.id);
                                  }}
                                />
                                <div className="checkmark"></div>
                              </label>
                              {/* <input
                                type="checkbox"
                                onChange={(e) => {
                                  onSetCourse(e, data, item?.id);
                                }}
                              /> */}
                            </td>
                          );
                        })}
                      {workExperiences?.length > 0 &&
                        workExperiences?.map((item) => {
                          return (
                            <td>
                              <label className="container-checkbox">
                                <input
                                  type="checkbox"
                                  onChange={(e) => {
                                    onSetWorkExperience(e, data, item?.id);
                                  }}
                                />
                                <div className="checkmark"></div>
                              </label>
                              {/* <input
                                type="checkbox"
                                onChange={(e) => {
                                  onSetCourse(e, data, item?.id);
                                }}
                              /> */}
                            </td>
                          );
                        })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      }
    </div>
  );
}

export default SelectionCourse;
