import React, { useState } from "react";
import Button from "components/Button";
import Heading from "components/Heading";
import Input from "components/Input";
import Navbar from "components/Navbar";
import "components/CustomLogin/CustomLogin.css";
//redux
import axios from "axios";
import { Toast } from "utils/toast";

function ForgotPassword() {
  const [emailData, setEmailData] = useState({
    email: "",
  });
  const [error, setError] = useState("");

  const handleChange = (event) => {
    setEmailData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = !emailData?.email;
    if (errors) {
      setError("Email is required!");
    } else {
      axios
        .post("/password-reset", emailData)
        .then((res) => {
          if (res.data) Toast("success", "Send a link to your email!");
          setEmailData({ ...emailData, email: "" });
        })
        .catch((error) => {
          console.log(error);
          setError(error.response.data.message);
        });
    }
  };

  return (
    <>
      <Navbar page="login" />
      <div id="controlCentreContainer" className="routes_background">
        <div id="playerCentre" className="loginSection">
          <div className="shadow-sm rounded-2 w-50 p-5 my-5 bg-light mx-auto loginFormDiv">
            <Heading
              type="h3"
              label="Forgot Your Password"
              className="alternate-gothi text-center"
            />
            <p className="text-muted text-center mb-4 poppins">
              To reset your password, please provide your email.
            </p>

            <form onSubmit={handleSubmit}>
              <div id="createSection" className="poppins">
                <Input
                  type="email"
                  className="inputField mt-2 px-2"
                  id="tutorEmail"
                  name="email"
                  label="Email Address"
                  divStyle={{ fontSize: "18px" }}
                  value={emailData?.email}
                  onChange={(e) => handleChange(e)}
                />
                <p
                  id="login_validation"
                  style={{ color: "red", fontSize: "small" }}
                  className="fw-bold"
                >
                  {error}
                </p>
                <Button
                  className="buttonClass greenButton mt-3 w-100"
                  label="Forgot Password"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
