import React, { useCallback, useEffect, useState } from "react";
import "./Profile.css";
import Button from "components/Button";
import Input from "components/Input";
import { useDispatch } from "react-redux";
// import FeatherIcon from "feather-icons-react";
import Label from "components/Label";
// import Image from "components/Image";
import { actions } from "redux/User/action";
import { actions as loaderAction } from "redux/Loader/action";
import CropImage from "components/CropImage";
import jwt_decode from "jwt-decode";

const Profile = ({ user }) => {
  const token = localStorage.getItem("TOKEN");
  let decoded;
  if (token) {
    decoded = jwt_decode(token);
  }
  const dispatch = useDispatch();
  // const imgRef = useRef(null);
  const [contentEditable, setContentEditable] = useState(
    user?.name === "" ? true : false
  );
  // eslint-disable-next-line
  const [imgValue, setImgValue] = useState("");
  const [imgValueData, setImgValueData] = useState("");
  const [fileName, setFileName] = useState("");

  const [croppedImage, setCroppedImage] = useState(null);
  const [openCropModal, setCropModal] = useState(false);

  // const [tutorImg, setTutorImg] = useState(user?.image ? user.image : null);
  const [userData, setUserData] = useState({
    name: user?.name ? user?.name : "",
    email: user?.email ? user?.email : "",
    image: user?.image ? user?.image : "",
    designation: user?.designation ? user?.designation : "",
  });
  const [error, setError] = useState("");

  const setCroppedImageCallback = useCallback(
    async (imageFileStream) => {
      setCroppedImage(imageFileStream);
      const formData = new FormData();
      formData.append("image", imageFileStream);
    },
    // eslint-disable-next-line
    [croppedImage]
  );

  const handleChangeImage = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    setFileName(file.name);
    reader.onload = (e) => {
      const result = e.target.result;
      // setImgValue(result);
      // setTutorImgValue(result);
      setImgValueData(result);
      setCropModal(true);
      setUserData({ ...userData, image: result });
    };

    reader.readAsDataURL(file);
  };

  // const handleClick = (event) => {
  //   imgRef.current.click();
  // };

  const handleChange = (event) => {
    setUserData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = !userData?.name || !userData?.designation;

    if (errors) {
      // setError("Email, Name, Designation, and Image are required!");
      setError("All fields are required!");
    } else {
      const formData = new FormData();
      formData.append("image", croppedImage ? croppedImage : userData?.image);
      formData.append("name", userData.name);
      formData.append("email", userData.email);
      formData.append("designation", userData.designation);

      setUserData({ ...userData });
      dispatch(loaderAction.startLoader());
      dispatch(
        actions.userUpdate(
          formData,
          decoded?.role !== "admin" ? "tutor" : "update/admin"
        )
      );
      setUserData({
        name: "",
        email: "",
        image: "",
        designation: "",
      });
      // setTutorImg(null);
      setError("");
      setContentEditable(false);
      // navigate("/");
    }
  };

  useEffect(() => {
    setUserData({
      name: user?.name ? user?.name : "",
      email: user?.email ? user?.email : "",
      image: user?.image ? user?.image : "",
      designation: user?.designation ? user?.designation : "",
    });
    // setTutorImg(user?.image ? user.image : null);
    setContentEditable(user?.name === "" ? true : false);
  }, [user]);

  return (
    <>
      <div className="puppins">
        <div>
          <form onSubmit={handleSubmit}>
            {/* <div className="mb-3 mt-1">
                        <div className="profilepic">
                          <Image
                            className="profilepic__image"
                            src={tutorImg}
                            // width="150"
                            // height="150"
                            alt="Profibild"
                          />
                          <div className="profilepic__content">
                            <FeatherIcon
                              icon="camera"
                              size={24}
                              className="profilepic__icon"
                            />
                            <span className="profilepic__text">
                              Change Photo
                            </span>
                          </div>
                        </div>

                        <input
                          type="file"
                          ref={imgRef}
                          // id="clubImage"
                          // name="clubImage"
                          className="d-none"
                          value={tutorImgValue}
                          onChange={handleChangeImage}
                          accept="image/*"
                        />
                        <Label className="mt-2 ms-4" title="Your Image" />
                      </div> */}
            {contentEditable && (
              <>
                <div className="mb-3">
                  <Label
                    className="mb-2"
                    title="Your Image"
                    style={{ fontSize: "18px" }}
                  />
                  <div className="upload w-100">
                    <Input
                      type="button"
                      className="uploadButtonImage"
                      value="Browse"
                      divClass="d-inline-block"
                      style={{ fontSize: "16px" }}
                    />
                    <input
                      type="file"
                      name="upload"
                      accept="image/*"
                      id="fileUpload"
                      value={imgValue}
                      onChange={handleChangeImage}
                    />
                    <span className="fileName">
                      {fileName ? fileName : "Select Image..."}
                    </span>
                  </div>
                </div>

                {imgValueData && (
                  <CropImage
                    src={imgValueData}
                    setCroppedImage={setCroppedImageCallback}
                    openCropModal={openCropModal}
                    setCropModal={setCropModal}
                  />
                )}
              </>
            )}
            <div className="mb-3">
              <Input
                type="text"
                className={`inputField mt-2 px-2 ${
                  !contentEditable ? "readOnly text-secondary" : ""
                }`}
                id="tutorName"
                label="Your Name"
                name="name"
                divStyle={{ fontSize: "18px" }}
                value={userData?.name}
                onChange={(e) => handleChange(e)}
                readOnly={contentEditable ? false : true}
                style={{ fontSize: "16px" }}
              />
            </div>
            <div className="w-100">
              <Input
                type="email"
                className={`inputField mt-2 px-2 ${
                  user ? "readOnly text-secondary" : ""
                }`}
                id="tutorEmail"
                name="email"
                label="Your Email"
                divStyle={{ fontSize: "18px" }}
                value={user.email}
                readOnly={user && true}
                style={{ fontSize: "16px" }}
              />
            </div>
            <div className="d-md-flex">
              <div className="ms-md-0 ms-0 w-100">
                <Input
                  type="text"
                  className={`inputField mt-2 px-2 ${
                    !contentEditable ? "readOnly  text-secondary" : ""
                  }`}
                  id="tutorDesignation"
                  name="designation"
                  label="Your Role"
                  divStyle={{ fontSize: "18px" }}
                  value={userData?.designation}
                  onChange={(e) => handleChange(e)}
                  readOnly={contentEditable ? false : true}
                  style={{ fontSize: "16px" }}
                />
              </div>
            </div>
            <p style={{ fontSize: "small" }} className="fw-bold text-danger">
              {contentEditable && error}
            </p>

            <div className="my-3">
              {!contentEditable && (
                <Button
                  type={"button"}
                  className="btn greenButton"
                  label={"Edit Profile"}
                  handleClick={() => {
                    setContentEditable(true);
                  }}
                />
              )}
              {contentEditable && (
                <>
                  <Button
                    type={"submit"}
                    className="btn greenButton"
                    label={"Save Changes"}
                  />
                  <Button
                    type={"button"}
                    className="btn btn-outline-secondary ms-3"
                    label={"Cancel"}
                    handleClick={() => setContentEditable(false)}
                  />
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Profile;
