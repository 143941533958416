import { all, fork, put, takeLatest } from "redux-saga/effects";
import { getQuery, postQuery } from "utils/api";
import { actions } from "./action";

export function* viewCV() {
  yield takeLatest(actions.VIEW_CV_REQUEST, function* ({ data }) {
    try {
      const response = yield getQuery(
        `${process.env.REACT_APP_CV_TEMPLATE_BASE_URL}cv?type=html&email=${data.email}&cvType=interactive&role=admin`,
        {
          headers: { authorization: "" },
        }
      );
      if (!response.data.error) {
        yield put({
          type: actions.VIEW_CV_SUCCESS,
          payload: response.data.result,
        });
        window.open(response.data.result);
      } else {
        alert(response.data.message);
      }
    } catch (e) {
      console.log(e);
    }
  });
}

export function* viewAllCV() {
  yield takeLatest(actions.VIEW_ALL_CV_REQUEST, function* ({ data }) {
    try {
      const response = yield postQuery(
        `${process.env.REACT_APP_CV_TEMPLATE_BASE_URL}cv/all`,
        data,
        {
          headers: { authorization: "" },
        }
      );
      if (!response.data.error) {
        yield put({
          type: actions.VIEW_ALL_CV_SUCCESS,
          payload: response.data.result,
        });
        if (response.data.result.length > 0) {
          response.data.result.forEach((cv) => {
            window.open(cv.cv);
          });
        }
        // window.open(response.data.result);
      } else {
        alert(response.data.message);
      }
    } catch (e) {
      console.log(e);
    }
  });
}

export default function* cvSaga() {
  yield all([fork(viewCV), fork(viewAllCV)]);
}
