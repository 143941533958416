import React, { useContext, useEffect, useState } from "react";
import Input from "components/Input";
import "./QualificationGrading.css";
import { Dropdown } from "primereact/dropdown";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { UserContext } from "components/contexts/User.context";
import { useDispatch } from "react-redux";
import { actions } from "redux/Player/action";
import { actions as loaderActions } from "redux/Loader/action";

// const status = Status.map((status) => {
//   return { name: status?.name, value: status?.id };
// });

const QualificationGrading = ({
  contentEditable,
  player,
  setPlayer,
  editCourses,
  setEditCourses,
}) => {
  const [collapsible, setCollapsible] = useState(false);
  const [collapsibleCourse, setCollapsibleCourse] = useState({
    id: "",
    collapsible: false,
  });
  let token = localStorage.getItem("TOKEN");
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const { user } = useSelector((state) => state.user);
  const { networkProgressDialog } = useSelector((state) => state.loader);
  let decoded;
  if (token) {
    decoded = jwt_decode(token);
  }

  const { playerStatus: playerStatuses, coursesOfUser } = useSelector(
    (state) => state.players
  );

  useEffect(() => {
    if (coursesOfUser.courses.length > 0) {
      if (player.id === coursesOfUser.userId) {
        setPlayer({
          ...player,
          courses: coursesOfUser.courses,
        });
      }
    }
  }, [coursesOfUser]);

  useEffect(() => {
    if (coursesOfUser.courses.length > 0 && collapsible) {
      const height_ = getHeightOfHiddenElement();
      const contents = document.getElementsByClassName(
        "qualificationGradingContent"
      );
      Array.from(contents).forEach((content) => {
        content.style.maxHeight = content.scrollHeight + 80 + height_ + "px";
      });
    }
  }, [player]);

  const handleCollapsible = () => {
    // setCollapsible(!collapsible);
    const contents = document.getElementsByClassName(
      "qualificationGradingContent"
    );
    Array.from(contents).forEach((content) => {
      if (content.style.maxHeight) {
        content.style.maxHeight = null;
      } else {
        content.style.maxHeight = content.scrollHeight + height + 30 + "px";
      }
    });
  };

  const handleCollapsibleCourse = (id) => {
    // setCollapsible(!collapsible);
    const content = document.getElementById(id);
    if (content.style.maxHeight) {
      content.style.maxHeight = null;
    } else {
      content.style.maxHeight = content.scrollHeight + height + 30 + "px";
    }
    // Array.from(contents).forEach((content) => {
    //   if (content.style.maxHeight) {
    //     content.style.maxHeight = null;
    //   } else {
    //     content.style.maxHeight = content.scrollHeight + 30 + "px";
    //   }
    // });
  };

  // useEffect(() => {
  //   handleCollapsibleAgain();
  // }, [collapsibleCourse]);
  const [height, setHeight] = useState(0);
  function getHeightOfHiddenElement() {
    const hiddenElement = document.getElementsByClassName(
      "qualificationCoursesContent"
    );
    let height = 0;
    Array.from(hiddenElement).forEach((data) => {
      data.classList.remove("courseContent"); // Temporarily remove d-none class
      height += data.scrollHeight; // Get the height of the element
      data.classList.add("courseContent"); // Add d-none class back
    });
    setHeight(height);
    return height;
  }

  return (
    <>
      <div
        id="qualificationGradingSection"
        className="my-5 qualificationGrading">
        <div
          id="qualificationGrading"
          className={`sectionTitle collapsible ${collapsible ? "active2" : ""}`}
          onClick={() => {
            setCollapsible(!collapsible);
            handleCollapsible();
            if ((!collapsible && !player.courses) || editCourses) {
              dispatch(loaderActions.startLoader());
              dispatch(
                actions.getCoursesOfUser({
                  userId: player.id,
                })
              );
              setEditCourses(false);
            }
          }}>
          Qualifications & Grading
        </div>

        {/* {networkProgressDialog ? (
          <>
            <div id="cover-spin" className="LoaderSpin">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </>
        ) : ( */}
        <div
          id="dannyRoutesSection"
          className="qualificationGradingContent content"
          // style={{ maxHeight: collapsible ? "100%" : "0" }}
        >
          <div className="my-3">
            {player?.courses &&
              player?.courses?.length > 0 &&
              player?.courses?.map((data, i) => {
                return (
                  <div className="course-1 my-4 text-green">
                    <h5
                      className={`text-uppercase courseTitleQualification m-0  courseCollapsible ${
                        collapsibleCourse.id === data.id &&
                        collapsibleCourse.collapsible
                          ? "activeCourse"
                          : ""
                      }`}
                      onClick={() => {
                        setCollapsibleCourse({
                          id: data.id,
                          collapsible:
                            collapsibleCourse.id === data.id
                              ? !collapsibleCourse.collapsible
                              : true,
                        });
                        // handleCollapsibleAgain();
                        handleCollapsibleCourse(
                          `qualificationCoursesContent${i}`
                        );
                      }}>
                      Course {i + 1}: {data?.courseName ? data?.courseName : ""}
                    </h5>
                    <hr />

                    <div
                      id={`qualificationCoursesContent${i}`}
                      className={`qualificationCoursesContent ${
                        collapsibleCourse.id === data.id &&
                        collapsibleCourse.collapsible
                          ? "courseContent"
                          : "courseContent"
                      }`}>
                      {data?.modules &&
                        data?.modules?.length > 0 &&
                        data?.modules?.map((item, j) => {
                          return (
                            <>
                              <div
                                className="mb-4 qualificationGradingDiv"
                                style={{ marginTop: j >= 1 ? "40px" : "8px" }}>
                                <div className="row">
                                  <div className="col-xxl-8 col-lg-12 col-md-8">
                                    <h5
                                      className="text-uppercase unitTitle m-0"
                                      // style={{ whiteSpace: "nowrap" }}
                                    >
                                      Module {j + 1}:{" "}
                                      {item?.name ? item?.name : ""}
                                    </h5>
                                  </div>
                                  <div className="col-xxl-3 col-lg-12 col-md-3 qualificationUnitGrading">
                                    <Dropdown
                                      value={item?.achievedGrade}
                                      name="status"
                                      options={data?.gradingOptions}
                                      // onChange={(e) => setPlayerStatus(e.value)}
                                      onChange={(e) => {
                                        const course =
                                          player?.courses?.findIndex(
                                            (course) => course.id === data.id
                                          );
                                        const unit = player?.courses[
                                          course
                                        ]?.modules.findIndex(
                                          (module) => module.id === item.id
                                        );

                                        player.courses[course].modules[
                                          unit
                                        ].achievedGrade = e.value;
                                        setPlayer({ ...player });
                                      }}
                                      optionLabel="name"
                                      disabled={contentEditable ? false : true}
                                      placeholder={
                                        "Select a grade"
                                        // player?.status
                                        //   ? player?.status &&
                                        //     player?.status?.map((status) => {
                                        //       return (
                                        //         status?.current === true &&
                                        //         playerStatuses &&
                                        //         playerStatuses?.length > 0 &&
                                        //         playerStatuses?.map((data) => {
                                        //           return (
                                        //             status?.id === data?.id &&
                                        //             data?.name
                                        //           );
                                        //         })
                                        //       );
                                        //     })
                                        //   : "Select a Status"
                                      }
                                      className="w-full border dropdownfield m-0"
                                    />
                                  </div>
                                </div>
                              </div>

                              {item?.units &&
                                item?.units?.length > 0 &&
                                item?.units?.map((module, index) => {
                                  return (
                                    <div className="my-3 qualificationGradingDiv">
                                      <div className="row">
                                        <div className="col-xxl-8 col-lg-12 col-md-8">
                                          <h6 className="m-0 moduleTitle">
                                            Module {j + 1}: Unit {index + 1}:{" "}
                                            <span className="text-uppercase">
                                              {module?.name ? module?.name : ""}
                                            </span>
                                          </h6>
                                        </div>
                                        <div className="col-xxl-3 col-lg-12 col-md-3 qualificationModuleGrading">
                                          <Dropdown
                                            value={module?.achievedGrade}
                                            name="status"
                                            options={data?.gradingOptions}
                                            // onChange={(e) => setPlayerStatus(e.value)}
                                            onChange={(e) => {
                                              const course =
                                                player?.courses?.findIndex(
                                                  (course) =>
                                                    course.id === data.id
                                                );
                                              const unit = player?.courses[
                                                course
                                              ]?.modules.findIndex(
                                                (unit) => unit.id === item.id
                                              );
                                              const moduleIndex =
                                                player?.courses[
                                                  course
                                                ]?.modules[
                                                  unit
                                                ]?.units.findIndex(
                                                  (module_) =>
                                                    module_.id === module.id
                                                );
                                              player.courses[course].modules[
                                                unit
                                              ].units[
                                                moduleIndex
                                              ].achievedGrade = e.value;
                                              setPlayer({ ...player });
                                            }}
                                            optionLabel="name"
                                            disabled={
                                              contentEditable ? false : true
                                            }
                                            placeholder={
                                              "Select a grade"
                                              // player?.status
                                              //   ? player?.status &&
                                              //     player?.status?.map((status) => {
                                              //       return (
                                              //         status?.current === true &&
                                              //         playerStatuses &&
                                              //         playerStatuses?.length > 0 &&
                                              //         playerStatuses?.map((data) => {
                                              //           return (
                                              //             status?.id === data?.id &&
                                              //             data?.name
                                              //           );
                                              //         })
                                              //       );
                                              //     })
                                              //   : "Select a Status"
                                            }
                                            className="w-full border dropdownfield m-0"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
          </div>

          {/* <div className="createField">
            <p>Name</p>
            {!contentEditable && (
              <p className="fieldText" id="playerName" required>
                {player?.name ? player?.name : ""}
              </p>
            )}
            {contentEditable && (
              <Input
                id="playerName"
                className="fieldText border"
                value={player?.name}
                name="name"
                onChange={(e) => handleChange(e)}
                type="text"
              />
            )}
          </div> */}
          {/* <div className="createField">
            <p>Status</p>
            {!contentEditable &&
              player?.status &&
              player?.status?.map((status) => {
                return (
                  status?.current === true && (
                    <p className="fieldText" id="playerStatus" key={status?.id}>
                      {playerStatuses &&
                        playerStatuses?.length > 0 &&
                        playerStatuses?.map((data) => {
                          return status?.id === data?.id && data?.name;
                        })}
                    </p>
                  )
                );
              })}
            {contentEditable && (
              <Dropdown
                value={playerStatus}
                name="status"
                options={status}
                // onChange={(e) => setPlayerStatus(e.value)}
                onChange={handleChangePlayerStatus}
                optionLabel="name"
                placeholder={
                  player?.status
                    ? player?.status &&
                      player?.status?.map((status) => {
                        return (
                          status?.current === true &&
                          playerStatuses &&
                          playerStatuses?.length > 0 &&
                          playerStatuses?.map((data) => {
                            return status?.id === data?.id && data?.name;
                          })
                        );
                      })
                    : "Select a Status"
                }
                className="w-100 border dropdownfield"
              />
            )}
          </div> */}
        </div>
        {/* )} */}
      </div>
    </>
  );
};

export default QualificationGrading;
