import { Navigate, Outlet } from "react-router-dom";
import jwt_decode from "jwt-decode";

export const AuthRoute = () => {
  const token = localStorage.getItem("TOKEN");
  let decoded;
  let emptyFields;
  if (token) {
    decoded = jwt_decode(token);
    if (
      decoded?.name === "" ||
      decoded?.email === "" ||
      decoded?.designation === ""
    ) {
      emptyFields = true;
    }
  }
  return token ? (
    emptyFields ? (
      <Navigate to="/profile" replace />
    ) : (
      <Navigate to="/" replace />
    )
  ) : (
    <Outlet />
  );
};
