export const actions = {
  GET_PLAYERS_REQUEST: "GET_PLAYERS_REQUEST",
  GET_PLAYERS_SUCCESS: "GET_PLAYERS_SUCCESS",

  CREATE_PLAYERS_REQUEST: "CREATE_PLAYERS_REQUEST",
  CREATE_PLAYERS_SUCCESS: "CREATE_PLAYERS_SUCCESS",

  UPDATE_PLAYER_REQUEST: "UPDATE_PLAYER_REQUEST",
  UPDATE_PLAYER_SUCCESS: "UPDATE_PLAYER_SUCCESS",

  GET_ALL_PLAYERS_REQUEST: "GET_ALL_PLAYERS_REQUEST",
  GET_ALL_PLAYERS_SUCCESS: "GET_ALL_PLAYERS_SUCCESS",

  GET_ARCHIVED_PLAYERS_REQUEST: "GET_ARCHIVED_PLAYERS_REQUEST",
  GET_ARCHIVED_PLAYERS_SUCCESS: "GET_ARCHIVED_PLAYERS_SUCCESS",

  GET_CLUB_REQUEST: "GET_CLUB_REQUEST",
  GET_CLUB_SUCCESS: "GET_CLUB_SUCCESS",

  GET_PLAYER_COURSES_REQUEST: "GET_PLAYER_COURSES_REQUEST",
  GET_PLAYER_COURSES_SUCCESS: "GET_PLAYER_COURSES_SUCCESS",

  GET_PLAYER_STATUS_REQUEST: "GET_PLAYER_STATUS_REQUEST",
  GET_PLAYER_STATUS_SUCCESS: "GET_PLAYER_STATUS_SUCCESS",

  GET_PLAYER_CATEGORY_REQUEST: "GET_PLAYER_CATEGORY_REQUEST",
  GET_PLAYER_CATEGORY_SUCCESS: "GET_PLAYER_CATEGORY_SUCCESS",

  REMOVE_PLAYER_REQUEST: "REMOVE_PLAYER_REQUEST",
  REMOVE_PLAYER_SUCCESS: "REMOVE_PLAYER_SUCCESS",

  UNREMOVE_PLAYER_REQUEST: "UNREMOVE_PLAYER_REQUEST",
  UNREMOVE_PLAYER_SUCCESS: "UNREMOVE_PLAYER_SUCCESS",

  DELETE_PLAYER_REQUEST: "DELETE_PLAYER_REQUEST",
  DELETE_PLAYER_SUCCESS: "DELETE_PLAYER_SUCCESS",

  ADD_TRANSFERABLE_REQUEST: "ADD_TRANSFERABLE_REQUEST",
  ADD_TRANSFERABLE_SUCCESS: "ADD_TRANSFERABLE_SUCCESS",

  CATCH_ERROR: "CATCH_ERROR",

  getPlayers: (data) => {
    return {
      type: actions.GET_PLAYERS_REQUEST,
      data,
    };
  },

  createPlayers: (data, navigate) => {
    return {
      type: actions.CREATE_PLAYERS_REQUEST,
      data,
      navigate,
    };
  },

  updatePlayer: (data) => {
    return {
      type: actions.UPDATE_PLAYER_REQUEST,
      data,
    };
  },

  getClub: () => {
    return {
      type: actions.GET_CLUB_REQUEST,
    };
  },

  getAllPlayers: (data) => {
    return {
      type: actions.GET_ALL_PLAYERS_REQUEST,
      data,
    };
  },

  getArchivedPlayers: (data) => {
    return {
      type: actions.GET_ARCHIVED_PLAYERS_REQUEST,
      data,
    };
  },

  getPlayerStatus: () => {
    return {
      type: actions.GET_PLAYER_STATUS_REQUEST,
    };
  },

  getPlayerCategory: () => {
    return {
      type: actions.GET_PLAYER_CATEGORY_REQUEST,
    };
  },

  deletePlayer: (data) => {
    return {
      type: actions.DELETE_PLAYER_REQUEST,
      data,
    };
  },

  getCoursesOfUser: (data) => {
    return {
      type: actions.GET_PLAYER_COURSES_REQUEST,
      data,
    };
  },

  createTransferableSkill: (data) => {
    return {
      type: actions.ADD_TRANSFERABLE_REQUEST,
      data,
    };
  },
};
