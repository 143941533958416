import React from "react";

const Label = (props) => {
  const { title = "", className = "", style, htmlFor = "" } = props;
  return (
    <>
      <label htmlFor={htmlFor} style={style} className={className}>
        {title}
      </label>
    </>
  );
};

export default Label;
