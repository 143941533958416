import setToken from "utils/setToken";
import { actions } from "./action";

const initialState = {
  clubs: [],
  club: {},
  archivedClubs: [],
  error: false,
};

const clubReducer = (state = initialState, action) => {
  const token = localStorage.getItem("TOKEN");
  if (token) {
    setToken(token);
  }
  switch (action.type) {
    case actions.CREATE_CLUB_SUCCESS:
      return {
        ...state,
        clubs: [...state.clubs, ...action.payload].sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
      };
    case actions.UPDATE_CLUB_SUCCESS:
      const indexOfClub = state.clubs.findIndex(
        (data) => data.id === action.payload.id
      );
      state.clubs[indexOfClub] = action.payload;
      return {
        ...state,
        club: action.payload,
        clubs: state.clubs,
      };

    case actions.GET_CLUBS_SUCCESS:
      return {
        ...state,
        clubs: action.payload.filter((club) => {
          return club.isEnable && club;
        }),
        archivedClubs: action.payload.filter((club) => {
          return !club.isEnable && club;
        }),
      };

    case actions.REMOVE_CLUB_SUCCESS:
      return {
        ...state,
        club: action.payload,
        clubs: state.clubs.filter((club) => {
          return club.id !== action.payload.id && club;
        }),
        archivedClubs: [...state.archivedClubs, action.payload].sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
      };

    case actions.UNREMOVE_CLUB_SUCCESS:
      return {
        ...state,
        club: action.payload,
        clubs: [...state.clubs, action.payload].sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
        archivedClubs: state.archivedClubs.filter((club) => {
          return club.id !== action.payload.id && club;
        }),
      };

    case actions.CATCH_ERROR:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default clubReducer;
