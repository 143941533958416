import React from "react";
import "./Dropdown.css";
import FeatherIcon from "feather-icons-react";
import AddClub from "components/AddClub";
import AddTutor from "components/AddTutor";
import { Link } from "react-router-dom";
import { actions as courseActions } from "redux/Course/action";
import { useDispatch } from "react-redux";

const Dropdown = (props) => {
  const {
    icon,
    list,
    className,
    club,
    setClubDataUpdate,
    // clubDataUpdate,
    tutor,
    setTutorDataUpdate,
    isArchive,
    player,
  } = props;
  const dispatch = useDispatch();
  return (
    <>
      <div
        className={`dropdown dropdownClub ${
          icon === "more-vertical" ? "menu_vertical_icon" : ""
        } ${className ? className : ""}`}
      >
        <span
          className={`float-end text-body iconColor ${
            icon === "more-vertical" ? "menu_vertical_icon" : ""
          }`}
          data-bs-toggle="dropdown"
        >
          <FeatherIcon icon={icon} size={24} />
        </span>
        <ul className="dropdown-menu dropdownClubMenu">
          {list &&
            list.map((item, i) => {
              return item?.title !== "Unarchive" && !isArchive ? (
                <li key={i + 1}>
                  <Link
                    className="dropdown-item dropdownItem poppins fw-bold px-2 mx-2"
                    // to={
                    //   item?.navigate === "/club/info"
                    //     ? `${item?.navigate}/${club?.id}`
                    //     : item?.navigate
                    // }
                    to={
                      item?.navigate === "/club/dashboard"
                        ? `${item?.navigate}/${club?.id}`
                        : item?.navigate
                    }
                    onClick={() => {
                      if (club && club?.instituteId) {
                        dispatch(
                          courseActions.getInstitutionTypes({
                            instituteId: club?.instituteId,
                          })
                        );
                      }
                      item.onClick &&
                        item.onClick(
                          club
                            ? club?.id
                            : tutor
                            ? tutor?.email
                            : player
                            ? player?.email
                            : ""
                        );
                    }}
                    data-bs-toggle={item.attribute && "modal"}
                    data-bs-target={
                      item.attribute && club
                        ? `#myModal${club?.name
                            ?.split(" ")[0]
                            .replace(/[^a-zA-Z ]/g, "")}`
                        : tutor
                        ? `#myModal${tutor?.email
                            ?.split("@")[0]
                            .replace(/[^a-zA-Z ]/g, "")}`
                        : ""
                    }
                  >
                    <FeatherIcon
                      icon={item?.icon && item?.icon}
                      size={15}
                      className="me-2"
                    />
                    {item?.title}
                  </Link>

                  {list.length - 1 !== i && (
                    <hr className="dropdown-divider dropdownDivider mx-3 my-1" />
                  )}
                </li>
              ) : (item?.title === "Unarchive" && isArchive) ||
                (isArchive && item?.title === "Remove Permanent") ? (
                <li key={i + 1}>
                  <Link
                    className="dropdown-item dropdownItem poppins fw-bold px-2 mx-2"
                    href={
                      item?.navigate === "/club/info"
                        ? item?.navigate + `/${club?.id}`
                        : item?.navigate
                    }
                    onClick={() =>
                      item?.onClick &&
                      item?.onClick(
                        club
                          ? club?.id
                          : tutor
                          ? tutor?.email
                          : player
                          ? player?.email
                          : "",
                        (tutor || player) && item?.title === "Remove Permanent"
                          ? tutor
                            ? tutor?.id
                            : player
                            ? player?.id
                            : ""
                          : ""
                      )
                    }
                    data-bs-toggle={item?.attribute && "modal"}
                    data-bs-target={
                      item.attribute && club
                        ? `#myModal${club?.name
                            ?.split(" ")[0]
                            .replace(/[^a-zA-Z ]/g, "")}`
                        : tutor
                        ? `#myModal${tutor?.email
                            ?.split("@")[0]
                            .replace(/[^a-zA-Z ]/g, "")}`
                        : ""
                    }
                  >
                    <FeatherIcon
                      icon={item?.icon && item?.icon}
                      size={15}
                      className="me-2"
                    />
                    {item?.title}
                  </Link>
                </li>
              ) : (
                ""
              );
            })}
        </ul>
      </div>

      <div
        className="modal fade"
        id={
          club
            ? `myModal${club?.name?.split(" ")[0].replace(/[^a-zA-Z ]/g, "")}`
            : tutor
            ? `myModal${tutor?.email?.split("@")[0].replace(/[^a-zA-Z ]/g, "")}`
            : ""
        }
        data-bs-keyboard="false"
        data-bs-backdrop="static"
      >
        <div
          className={`modal-dialog modal-dialog-centered ${
            tutor ? "modal-lg" : ""
          }`}
        >
          <div className="modal-content">
            {tutor ? (
              <AddTutor tutor={tutor} setTutorDataUpdate={setTutorDataUpdate} />
            ) : (
              <AddClub
                club={club && club}
                setClubDataUpdate={setClubDataUpdate}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dropdown;
