export const actions = {
  GET_WORK_EXPERIENCE_REQUEST: "GET_WORK_EXPERIENCE_REQUEST",
  GET_WORK_EXPERIENCE_SUCCESS: "GET_WORK_EXPERIENCE_SUCCESS",

  CREATE_WORK_EXPERIENCE_REQUEST: "CREATE_WORK_EXPERIENCE_REQUEST",
  CREATE_WORK_EXPERIENCE_SUCCESS: "CREATE_WORK_EXPERIENCE_SUCCESS",

  UPDATE_WORK_EXPERIENCE_REQUEST: "UPDATE_WORK_EXPERIENCE_REQUEST",
  UPDATE_WORK_EXPERIENCE_SUCCESS: "UPDATE_WORK_EXPERIENCE_SUCCESS",

  GET_WORK_EXPERIENCE_TILES_REQUEST: "GET_WORK_EXPERIENCE_TILES_REQUEST",
  GET_WORK_EXPERIENCE_TILES_SUCCESS: "GET_WORK_EXPERIENCE_TILES_SUCCESS",

  CATCH_ERROR: "CATCH_ERROR",

  getWorkExperience: (data, navigate) => {
    return {
      type: actions.GET_WORK_EXPERIENCE_REQUEST,
      data,
      navigate,
    };
  },

  createWorkExperience: (data) => {
    return {
      type: actions.CREATE_WORK_EXPERIENCE_REQUEST,
      data,
    };
  },

  getWorkExperienceTiles: (data) => {
    return {
      type: actions.GET_WORK_EXPERIENCE_TILES_REQUEST,
      data,
    };
  },
  updateWorkExperience: (data) => {
    return {
      type: actions.UPDATE_WORK_EXPERIENCE_REQUEST,
      data,
    };
  },
};
