// import Button from "components/Button";
import Player from "components/Player";
import RegisterPlayer from "components/RegisterPlayer";
import "./PlayerCentre.css";
// import { useDispatch } from "react-redux";
// import Heading from "components/Heading";
// import { Status } from "assets/js/status";
// import { Dropdown } from "primereact/dropdown";
import Image from "components/Image";
import MultiplePlayers from "components/MultiplePlayers";
// import { actions as playersAction } from "redux/Player/action";

// const status = Status.map((status) => {
//   return { name: status?.name, value: status.id };
// });

const PlayerCenter = ({
  playerCentre,
  setPlayerCentre,
  player,
  setPlayer,
  fileName,
}) => {
  // const dispatch = useDispatch();
  // const uploadFile = () => {
  //   dispatch(playersAction.createPlayers(player));
  // };
  // eslint-disable-next-line
  // const [playerStatus, setPlayerStatus] = useState("");

  // const handleChange = (play, e) => {
  //   let playersData = player.map((player) => {
  //     if (player?.email === play?.email) {
  //       player.status = e.value;
  //     }
  //     return player;
  //   });
  //   setPlayerStatus(e.value);
  //   setPlayer([...playersData]);
  // };

  // useEffect(() => {
  //   dispatch(playersAction.getPlayerStatus());
  // }, [dispatch]);

  return (
    <>
      <div
        id="playerCentre"
        // className={`${playerCentre === "multiplePlayer" ? "w-100" : ""}`}
        className={`${playerCentre === "multiplePlayer" ? "flex-grow-1" : ""}`}>
        {playerCentre === "viewPlayer" && (
          <div className="d-flex align-items-end mb-3 play-center-div">
            <div className="">
              <Image
                src={
                  player?.profileImage
                    ? player?.profileImage
                    : "http://s3.amazonaws.com/37assets/svn/765-default-avatar.png"
                }
                // src="http://s3.amazonaws.com/37assets/svn/765-default-avatar.png"
                alt=""
                className="full-width userProfile"
              />
            </div>
            <div className="puppins ms-3 profileInfoMain">
              <p className="fw-bold">{player?.name ? player?.name : ""}</p>
              <p className="text-secondary fw-bold">
                {player?.email ? player?.email : ""}
              </p>
            </div>
          </div>
        )}

        {playerCentre === "newPlayer" && <RegisterPlayer />}
        {playerCentre === "viewPlayer" && (
          <Player player={player} setPlayer={setPlayer} />
        )}

        {playerCentre === "multiplePlayer" && (
          <MultiplePlayers
            player={player}
            setPlayer={setPlayer}
            fileName={fileName}
            setPlayerCentre={setPlayerCentre}
          />
          // <SelectionCourse />

          // <div id="uploadFileSection" className="">
          //   <div className="uploadFileName">
          //     <Heading type="h4" label="File Name: " />
          //     <span id="uploadFileName">{fileName}</span>

          //     <div className="playerList my-5">
          //       <table className="table table-hover">
          //         <thead>
          //           <tr>
          //             <th scope="col">Name</th>
          //             <th scope="col">Email</th>
          //             <th scope="col">Status</th>
          //           </tr>
          //         </thead>
          //         <tbody>
          //           {player?.map((player) => {
          //             return (
          //               <tr key={player?.email}>
          //                 <th scope="row">
          //                   {player?.name ? player?.name : ""}
          //                 </th>
          //                 <td>{player?.email ? player?.email : ""}</td>
          //                 <td>
          //                   <Dropdown
          //                     value={player?.status}
          //                     options={status}
          //                     onChange={(e) => handleChange(player, e)}
          //                     optionLabel="name"
          //                     placeholder="Select a Status"
          //                     className="w-100"
          //                   />
          //                 </td>
          //               </tr>
          //             );
          //           })}
          //         </tbody>
          //       </table>
          //     </div>

          //     <div className="multipleDataButtonDiv">
          //       <Button
          //         id="upload"
          //         className="uploadButton"
          //         handleClick={uploadFile}
          //         label="Upload"
          //       />
          //       <Button
          //         id="cancel"
          //         className="uploadButton"
          //         handleClick={() => setPlayerCentre("")}
          //         label="Cancel"
          //       />
          //     </div>
          //   </div>
          // </div>
        )}
      </div>
    </>
  );
};

export default PlayerCenter;
