import React from "react";
import "./Textarea.css";

const Textarea = (props) => {
  const {
    name = "",
    label = "",
    value = "",
    handleChange,
    placeholder = "",
    className = "",
    id = "",
    maxlength,
    rows,
    cols,
    disabled,
  } = props;
  return (
    <>
      <div>
        <label>{label}</label>
        <textarea
          id={id}
          className={className}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          maxLength={maxlength}
          rows={rows}
          cols={cols}
          disabled={disabled}
        />
      </div>
    </>
  );
};

export default Textarea;
