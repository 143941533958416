import setToken from "utils/setToken";
import { actions } from "./action";

const initialState = {
  institutions: [],
  institutionType: null,
  qualification: null,
  institutionTypes: [],
  qualifications: [],
  gradingStructure: {
    suggestedGrades: [],
    unSuggestedGrades: [],
    allGrades: [],
  },
  course: null,
  courses: [],
  myCourses: [],
  units: [],
  unit: [],
  previousFilterOfCourse: null,
  error: false,
};

const courseReducer = (state = initialState, action) => {
  const token = localStorage.getItem("TOKEN");
  if (token) {
    setToken(token);
  }
  switch (action.type) {
    case actions.GET_COURSE_SUCCESS:
      return {
        ...state,
        courses: action.payload,
      };
    case actions.GET_MY_COURSE_SUCCESS:
      return {
        ...state,
        myCourses: action.payload,
      };
    case actions.CREATE_COURSE_SUCCESS:
      const existingCourses = state.courses;
      return {
        ...state,
        course: action.payload.course,
        courses: [...existingCourses, action.payload.course],
      };
    case actions.ADD_TO_MY_COURSE_SUCCESS:
      // const existingCourses = state.courses;
      return {
        ...state,
        course: action.payload,
      };
    case actions.UPDATE_COURSE_SUCCESS:
      const existCourseIndex = state.courses.findIndex((item) => {
        return item.id === action.payload.id;
      });
      state.courses[existCourseIndex] = action.payload;
      return {
        ...state,
        course: action.payload,
        courses: state.courses,
      };

    case actions.GET_SINGLE_COURSE_SUCCESS:
      return {
        ...state,
        course: action.payload,
        unit: action.payload.unit,
      };
    case actions.GET_UNIT_SUCCESS:
      return {
        ...state,
        units: action.payload,
      };

    case actions.GET_INSTITUTION_SUCCESS:
      return {
        ...state,
        institutions: action.payload,
      };

    case actions.GET_INSTITUTION_TYPES_SUCCESS:
      return {
        ...state,
        institutionTypes: action.payload,
      };

    case actions.GET_QUALIFICATION_SUCCESS:
      return {
        ...state,
        qualifications: action.payload,
      };

    case actions.GET_GRADE_SUCCESS:
      return {
        ...state,
        gradingStructure: action.payload,
      };

    case actions.CREATE_INSTITUTION_TYPE_SUCCESS:
      return {
        ...state,
        institutionTypes: [...state.institutionTypes, action.payload],
      };

    case actions.UPDATE_INSTITUTION_TYPE_SUCCESS:
      return {
        ...state,
        institutionType: action.payload,
      };

    case actions.CREATE_QUALIFICATION_SUCCESS:
      return {
        ...state,
        qualifications: [...state.qualifications, action.payload],
      };

    case actions.UPDATE_QUALIFICATION_SUCCESS:
      return {
        ...state,
        qualification: action.payload,
      };

    case actions.CREATE_GRADE_STRUCTURE_SUCCESS:
      return {
        ...state,
        gradingStructure: {
          ...state.gradingStructure,
          suggestedGrades: [
            action.payload,
            ...state.gradingStructure.suggestedGrades,
          ],
        },
        // gradingStructure: [...state.gradingStructure, action.payload],
      };

    // case actions.REMOVE_CLUB_SUCCESS:
    //   return {
    //     ...state,
    //     club: action.payload,
    //     clubs: state.clubs.filter((club) => {
    //       return club.id !== action.payload.id && club;
    //     }),
    //     archivedClubs: [...state.archivedClubs, action.payload].sort((a, b) =>
    //       a.name.localeCompare(b.name)
    //     ),
    //   };

    case actions.ADD_TO_PREVIOUS_FILTER_COURSE_SUCCESS:
      return {
        ...state,
        previousFilterOfCourse: action.payload,
      };

    case actions.CLEAR_COURSE_SUCCESS:
      return {
        ...state,
        course: null,
      };
    case actions.CLEAR_COURSES_SUCCESS:
      return {
        ...state,
        courses: [],
      };
    case actions.CATCH_ERROR:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default courseReducer;
