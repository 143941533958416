import React, { useEffect, useState } from "react";
import "./CourseCard.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import Image from "components/Image";
import plus1 from "assets/images/plus1.png";
import Heading from "components/Heading";
import { uuid } from "utils/uuid";
import FeatherIcon from "feather-icons-react";
import { actions } from "redux/Course/action";
import { SweetAlert } from "utils/SweetAlert";
import Swal from "sweetalert2";
import AddClub from "components/AddClub";

const CourseCard = ({
  institutionTypes,
  setPlayerCentre,
  playerCentre,
  instituteId,
  categoryName,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  let token = localStorage.getItem("TOKEN");
  let decoded;
  if (token) {
    // eslint-disable-next-line
    decoded = jwt_decode(token);
  }
  const { networkProgressDialog } = useSelector((state) => state.loader);
  const [instituteType, setInstituteType] = useState(institutionTypes);
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);
  const handleCard = (data) => {
    let playerCenter_ = playerCentre;
    setPlayerCentre && setPlayerCentre("courseUnitCenter");
    // console.log("data", data);
    // navigate(
    //   decoded?.role === "admin"
    //     ? !playerCenter_
    //       ? `/courses/course/${data?.id}`
    //       : `/${location.pathname}/course/${data?.id}`
    //     : `/course/${data?.id}`,
    //   {
    //     state: {
    //       step: data?.name ? data?.name : "",
    //       playerCenter: playerCenter_,
    //     },
    //   }
    // );
    navigate(`/courses/qualification-type/${data?.id}`, {
      state: {
        step_0: categoryName?.name ? categoryName?.name : "",
        step: data?.name ? data?.name : "",
        instituteId: data?.instituteId ? data?.instituteId : "",
        playerCenter: playerCenter_,
      },
    });
  };

  const optionList = [
    {
      id: 1,
      title: "Update",
      // navigate: "/",
      icon: "edit-3",
      onClick: (id, data) => {
        const indexOfInstituteType = instituteType.findIndex((data) => {
          return data.id === id;
        });
        instituteType[indexOfInstituteType].createNew = true;
        instituteType[indexOfInstituteType].updateAt = true;
        setInstituteType([...instituteType]);
      },
    },
    {
      id: 2,
      title: "Delete",
      // navigate: "/",
      icon: "trash-2",
      onClick: async (id, data) => {
        const result = await SweetAlert({
          title: "Are you sure? ",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
          confirmButtonColor: "#006b00",
        });
        if (result.isConfirmed) {
          const filterInstituteType = instituteType.filter((data) => {
            return data.id !== id;
          });
          setInstituteType([...filterInstituteType]);
          if (!data?.createNew) {
            dispatch(
              actions.updateInstitutionType({
                active: false,
                id: id,
              })
            );
          }
          Swal.fire({
            title: "Deleted!",
            text: "Your organisation has been deleted.",
            icon: "success",
            confirmButtonColor: "#006b00",
          });
        }
      },
    },
    {
      id: 3,
      title: "Create Organisation",
      // navigate: "/",
      icon: "plus-circle",
      attribute: {
        "data-bs-toggle": "modal",
        "data-bs-target": "#myModalAddOrganisation",
      },
    },
    {
      id: 3,
      title: "More Details",
      // navigate: "/",
      icon: "more-horizontal",
      onClick: (id, data) => {
        // dispatch()
        navigate("/common-user-profile", {
          state: {
            steps: {
              step_0: categoryName?.name ? categoryName?.name : "",
              step_1: data?.name ? data?.name : "",
            },
            instituteId: data?.instituteId,
            instituteTypeId: data?.id,
          },
        });
      },
    },
  ];

  useEffect(() => {
    const institutionTypes_ = structuredClone(institutionTypes);
    setInstituteType([...institutionTypes_]);
  }, [institutionTypes]);

  return (
    <>
      {networkProgressDialog ? (
        <>
          <div id="cover-spin" className="LoaderSpin">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </>
      ) : (
        <div className="cards-list">
          <div
            className="CourseCard"
            onClick={() => {
              setInstituteType([
                { id: uuid(), name: "", createNew: true },
                ...instituteType,
              ]);
            }}
          >
            <div className="title-white">
              <div className={`flex-shrink-0 d-flex align-items-center`}>
                <Image
                  src={plus1}
                  alt=""
                  className="img-fluid w-100 club_image_card cardBodyAddCard"
                  style={{ borderRadius: "10px", filter: "invert(1)" }}
                />
              </div>
              <div className="w-100">
                <Heading
                  type="h5"
                  className={`mb-0 poppins text-truncate text-center text-white`}
                  label={"Add Organisation Type"}
                />
              </div>
            </div>
          </div>

          {instituteType?.length > 0 ? (
            instituteType &&
            instituteType.map((item, index) => {
              // setInstituteValue(item.name)
              return (
                <div className="CourseCard" key={index}>
                  <div
                    className="text-end p-0 position-absolute"
                    style={{ right: "10px", top: "7px" }}
                  >
                    <div className={`dropdown dropdownClub`}>
                      <span
                        className={`float-end text-body iconColor`}
                        data-bs-toggle="dropdown"
                      >
                        <FeatherIcon
                          icon={"more-horizontal"}
                          size={24}
                          className="text-white"
                        />
                      </span>
                      <ul className="dropdown-menu dropdownClubMenu">
                        {optionList &&
                          optionList.map((data, i) => {
                            return data.id === 3 && item?.createNew ? (
                              ""
                            ) : (
                              <li
                                key={i + 1}
                                className="dropdown-item dropdownItem poppins fw-bold px-2 mx-2"
                                onClick={() => {
                                  data?.onClick &&
                                    data?.onClick(item?.id, item);
                                  setSelectedOrganisation(item);
                                }}
                                data-bs-toggle={data?.attribute && "modal"}
                                data-bs-target={
                                  data?.attribute && "#myModalAddOrganisation"
                                }
                              >
                                <FeatherIcon
                                  icon={data?.icon && data?.icon}
                                  size={15}
                                  className="me-2"
                                />
                                {data?.title}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>

                  <div
                    className="card_title title-white poppins"
                    onClick={() => {
                      if (!item?.createNew && !item?.isCancel) {
                        handleCard(item);
                      } else {
                        const indexOfInstituteType = institutionTypes.findIndex(
                          (data) => {
                            return data.id === item?.id;
                          }
                        );
                        if (indexOfInstituteType !== -1) {
                          instituteType[indexOfInstituteType].isCancel = false;
                          setInstituteType([...instituteType]);
                        }
                      }
                    }}
                  >
                    {item?.createNew ? (
                      <>
                        <form
                          onSubmit={async (e) => {
                            e.preventDefault();
                            if (!item?.updateAt) {
                              const result = await SweetAlert({
                                title:
                                  "Would you like to fill up more details about this property!",
                                showCancelButton: true,
                                cancelButtonText: "May be later",
                                cancelButtonColor: "#aaaaaa",
                                confirmButtonText: "Yes, let's do it!",
                                confirmButtonColor: "#006b00",
                                reverseButtons: true,
                              });
                              if (result.isConfirmed) {
                                navigate("/common-user-profile", {
                                  state: {
                                    steps: {
                                      step_0: categoryName?.name
                                        ? categoryName?.name
                                        : "",
                                      step_1: item?.name ? item?.name : "",
                                    },
                                    instituteId: item?.instituteId,
                                    instituteTypeId: item?.id,
                                  },
                                });
                              }
                            }
                            const indexOfInstituteType =
                              instituteType.findIndex((data) => {
                                return data.id === item.id;
                              });
                            instituteType[
                              indexOfInstituteType
                            ].createNew = false;
                            setInstituteType([...instituteType]);
                            !item?.updateAt &&
                              dispatch(
                                actions.createInstitutionType({
                                  name: instituteType[indexOfInstituteType]
                                    .name,
                                  instituteId: instituteId,
                                  userId: user.id,
                                })
                              );
                            item?.updateAt &&
                              dispatch(
                                actions.updateInstitutionType({
                                  name: instituteType[indexOfInstituteType]
                                    .name,
                                  id: instituteType[indexOfInstituteType].id,
                                })
                              );
                          }}
                          className="position-relative"
                        >
                          <div
                            className={
                              "createField flex-row align-items-center"
                            }
                            style={{ backgroundColor: "white" }}
                          >
                            <input
                              type="text"
                              className="inputField"
                              id="clubName"
                              // label="Club Name"
                              name="name"
                              style={{ fontSize: "18px", width: "85%" }}
                              value={item.name}
                              onChange={(e) => {
                                const indexOfInstituteType =
                                  instituteType.findIndex((data) => {
                                    return data.id === item.id;
                                  });
                                instituteType[indexOfInstituteType].name =
                                  e.target.value;
                                setInstituteType([...instituteType]);
                              }}
                            />
                            {item?.updateAt && (
                              <div
                                onClick={() => {
                                  const indexOfInstituteType =
                                    institutionTypes.findIndex((data) => {
                                      return data.id === item?.id;
                                    });
                                  instituteType[
                                    indexOfInstituteType
                                  ].createNew = false;
                                  instituteType[
                                    indexOfInstituteType
                                  ].isCancel = true;
                                  instituteType[indexOfInstituteType].name =
                                    institutionTypes[indexOfInstituteType].name;
                                  setInstituteType([...instituteType]);
                                }}
                              >
                                <FeatherIcon
                                  icon="x"
                                  size={20}
                                  className="removeInputIcon pl-1"
                                />
                              </div>
                            )}
                          </div>
                        </form>
                      </>
                    ) : (
                      <p style={{ whiteSpace: "break-spaces" }}>
                        {item?.name
                          ? item?.name
                              .replaceAll(" ", "\n")
                              .replaceAll(/\n\//gm, "/")
                          : ""}
                      </p>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
      )}

      <div
        className="modal fade"
        id="myModalAddOrganisation"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
      >
        <div className={`modal-dialog modal-dialog-centered`}>
          <div className="modal-content">
            {
              <AddClub
                club={{
                  instituteId: selectedOrganisation?.instituteId,
                  instituteTypeId: selectedOrganisation?.id,
                }}
              />
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseCard;
