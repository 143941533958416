import React, { useEffect, useState } from "react";
import Input from "components/Input";
import { Dropdown } from "primereact/dropdown";
import "./ProfileFieldArray.css";
import { uuid } from "utils/uuid";
import Label from "components/Label";
import { PROFILE_FIELD_TYPES } from "utils/constants";
import Button from "components/Button";

function ProfileFieldArray({ arrayField, setArrayField }) {
  const [selectedProfileField, setSelectedProfileField] = useState(null);
  const [noOfField, setNoOfField] = useState(0);
  const [disableGoButton, setDisableGoButton] = useState(false);
  const [userAddable, setUserAddable] = useState(false);
  const [placeholderBool, setPlaceholderBool] = useState("");
  const [ojectField, setObjectField] = useState("");
  const [ojectFieldType, setObjectFieldType] = useState("");
  const [ojectFields, setObjectFields] = useState(null);
  const [ojectFieldsArray, setObjectFieldsArray] = useState([]);
  const [placeholder, setPlaceholder] = useState({});
  const [ojectOfArray, setObjectOfArray] = useState([]);
  const [field, setField] = useState({
    title: "",
    value: "",
    defaultValue: "",
    type: "",
    field: "",
  });

  const profileFieldTypes = [
    {
      name: "String",
      value: "string",
    },
    {
      name: "Numeric",
      value: "numeric",
    },
    {
      name: "Object",
      value: "object",
    },
  ];

  const booleanArray = [
    {
      name: "Yes",
      value: 1,
    },
    {
      name: "No",
      value: 0,
    },
  ];

  const fieldsNumberOptions = [];
  for (let i = 1; i <= 10; i++) {
    fieldsNumberOptions.push({ name: i.toString(), value: i });
  }

  function createDynamicObject(num) {
    var dynamicObject = {};
    for (var i = 1; i <= Number(num); i++) {
      var key = "field" + i;
      dynamicObject[key] = "";
    }
    return dynamicObject;
  }
  // useEffect(() => {
  //   if (
  //     editProfileFieldArray &&
  //     arrayField?.arrayType?.toLowerCase() === "object"
  //   ) {
  //     setSelectedProfileField(arrayField?.arrayType?.toLowerCase());
  //     setObjectFieldsArray([...arrayField?.objectFields]);
  //     setNoOfField(arrayField?.objectFields?.length);
  //     setPlaceholder(arrayField?.placeholder);
  //     setPlaceholderBool(arrayField?.placeholder ? 1 : null);
  //   }
  // }, [arrayField]);

  const optionsProfileFields =
    PROFILE_FIELD_TYPES &&
    PROFILE_FIELD_TYPES?.length > 0 &&
    PROFILE_FIELD_TYPES?.map((data) => {
      return { name: data?.typeName, value: data?.typeName?.toLowerCase() };
    });

  optionsProfileFields.push({ name: "Media (Picture / File)", value: "media" });
  return (
    <div>
      <div className="profile-fields-dropdown">
        <p className="poppins my-2">Which type of data contains in array?</p>
        <Dropdown
          value={selectedProfileField}
          onChange={(e) => {
            setSelectedProfileField(e.value);
            setArrayField({ ...arrayField, arrayType: e.value });
          }}
          options={profileFieldTypes}
          optionLabel="name"
          placeholder="Select a field type"
          className="profile-fields-dropdown"
        />
      </div>

      {selectedProfileField === "object" && (
        <div className="profile-fields-dropdown">
          <p className="poppins my-2">Value of array is addable by user?</p>
          <Dropdown
            value={userAddable}
            onChange={(e) => {
              setUserAddable(e.value);
              setArrayField({
                ...arrayField,
                userAddable: e.value === 1 ? true : false,
              });
            }}
            options={booleanArray}
            optionLabel="name"
            placeholder="Select"
            className="profile-fields-dropdown"
          />
        </div>
      )}

      {selectedProfileField === "object" && (
        <div className="profile-fields-dropdown">
          <p className="poppins my-2">
            How many {userAddable === 0 ? "objects" : "fields"} do you want to
            add in {userAddable === 0 ? "array" : "object"}?
          </p>
          {userAddable === 1 && (
            <p className="poppins my-2">
              For Example, <br />
              Field 1: Title, Field 2: Description
            </p>
          )}
          <Dropdown
            value={noOfField}
            onChange={(e) => {
              setNoOfField(e.value);
              setObjectFields(createDynamicObject(e.value));
              setObjectFieldsArray([]);
              // setArrayField({ ...arrayField, objectFields: [] });
              // setUpdateProfileField &&
              //   setUpdateProfileField({
              //     ...updateProfileField,
              //     objectFields: [],
              //   });
            }}
            options={fieldsNumberOptions}
            optionLabel="name"
            placeholder="Select a field type"
            className="profile-fields-dropdown"
          />
        </div>
      )}

      {ojectFieldsArray.length > 0 && userAddable === 1 && (
        <div className="card card-object-field w-auto mb-2">
          <div className="card-body text-secondary poppins">
            <p className="mb-2">Object Fields</p>
            <div className="d-flex gap-2">
              {ojectFieldsArray?.map((data) => {
                return (
                  <div className="card shadow-none my-2 w-auto">
                    <div className="card-body py-2">
                      <div>
                        <p className="text-secondary">{data?.title}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            {/* <div className="card my-2 shadow-none">
            <div className="card-body py-2">
              <div>
                <p>Description</p>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      )}

      {ojectFieldsArray?.length < noOfField && userAddable === 1 && (
        <div className="card w-25 p-3">
          <div className="mt-2 poppins">
            <span className="fs-5">Field {ojectFieldsArray?.length + 1}</span>
          </div>
          <div className="d-flex mt-2 gap-2 profileFieldType">
            <>
              <Input
                type="text"
                id="numberOfOptionInputField"
                className={`profileField-inputField px-2 ${
                  disableGoButton === true ? "disable" : ""
                } `}
                name="numberOfOption"
                divStyle={{ fontSize: "18px", width: "100%" }}
                value={ojectField}
                onChange={(e) => {
                  setObjectField(e.target.value);
                }}
                divClass="poppins numberOfOptionInputField"
                placeholder={`Enter Field ${ojectFieldsArray?.length + 1}`}
              />
            </>
          </div>
          <div className="profile-field-type mt-2">
            <Label title="Filed Type" className="fs-5 mb-2 poppins" />
            <Dropdown
              value={ojectFieldType?.toLowerCase()}
              onChange={(e) => {
                setObjectFieldType(e.value);
              }}
              options={optionsProfileFields}
              optionLabel="name"
              placeholder="Select a type"
              className="w-full text-left"
              style={{ textTransform: "capitalize" }}
            />
          </div>
          <div className="numberOfOptionButtons mt-2">
            <button
              type="button"
              // className="btn user-profile-saveBtn"
              id="numberOfOptionGoBtn"
              className={`btn profile-field-goBtn ${
                disableGoButton === true ? "go-btn-disable" : ""
              }`}
              onClick={() => {
                const objField = {
                  id: uuid(),
                  title:
                    ojectFieldType?.toLowerCase() === "media"
                      ? "media"
                      : ojectField?.toLowerCase(),
                  value: ojectField,
                  type: ojectFieldType?.toLowerCase(),
                  field:
                    ojectFieldType?.toLowerCase() === "array"
                      ? "list"
                      : [
                          "select",
                          "Multi-Select",
                          "multi-select",
                          "radio",
                        ].includes(ojectFieldType?.toLowerCase())
                      ? "select"
                      : ojectFieldType?.toLowerCase() === "media"
                      ? "media"
                      : "textview",
                };
                setObjectFieldsArray([...ojectFieldsArray, objField]);
                setPlaceholder({
                  ...placeholder,
                  [ojectField.replace(/[\/\s&]/g, "_").toLowerCase()]: "",
                });
                setArrayField({
                  ...arrayField,
                  objectFields: [...arrayField.objectFields, objField],
                });
                setObjectField("");
              }}
              disabled={disableGoButton ? true : false}
            >
              Add
            </button>
          </div>
        </div>
      )}

      {/* {selectedProfileField && (
        <div className="profile-fields-dropdown">
          <p className="poppins my-2">Do you want to add placeholder?</p>
          <Dropdown
            value={placeholderBool}
            onChange={(e) => {
              setPlaceholderBool(e.value);
            }}
            options={booleanArray}
            optionLabel="name"
            placeholder="Select"
            className="profile-fields-dropdown"
          />
        </div>
      )} */}

      <div className="d-flex gap-5">
        {ojectOfArray?.length < noOfField && userAddable === 0 && (
          <div className="w-25">
            <Input
              type="text"
              className={`profile-inputField mt-2 px-2`}
              name="fieldName"
              label="Field Name"
              value={field?.title}
              divStyle={{ fontSize: "18px" }}
              onChange={(e) => {
                setField({
                  ...field,
                  title: e.target.value,
                });
              }}
              divClass="poppins createField"
            />

            <div className="profile-field-type">
              <Label title="Filed Type" className="mb-2 poppins" />
              <Dropdown
                value={field?.type?.toLowerCase()}
                onChange={(e) => {
                  setField({
                    ...field,
                    type: e.value,
                    field:
                      e.value?.toLowerCase() === "array"
                        ? "list"
                        : [
                            "select",
                            "Multi-Select",
                            "multi-select",
                            "radio",
                          ].includes(ojectFieldType?.toLowerCase())
                        ? "select"
                        : e.value?.toLowerCase() === "media"
                        ? "media"
                        : ojectFieldType?.toLowerCase() === "date"
                        ? "date"
                        : "textview",
                  });
                }}
                options={optionsProfileFields}
                optionLabel="name"
                placeholder="Select a type"
                className="w-full text-left"
                style={{ textTransform: "capitalize" }}
              />
            </div>

            <Input
              type="text"
              className={`profile-inputField mt-2 px-2`}
              name="fieldName"
              label="Default Value"
              value={field?.defaultValue}
              divStyle={{ fontSize: "18px" }}
              onChange={(e) => {
                setField({
                  ...field,
                  defaultValue: e.target.value,
                });
              }}
              divClass="poppins createField"
            />

            <Button
              label="Add"
              className="btn btn-success"
              handleClick={() => {
                setObjectOfArray([...ojectOfArray, field]);
                setArrayField({
                  ...arrayField,
                  ojectOfArray: [...ojectOfArray, { ...field, id: uuid() }],
                });
                setField({
                  title: "",
                  value: "",
                  defaultValue: "",
                  type: "",
                });
              }}
            />
          </div>
        )}
        {ojectOfArray?.length > 0 && (
          <div className="card w-25 poppins mt-3">
            <div className="card-body">
              {ojectOfArray?.map((data) => {
                return (
                  <div className="mb-2">
                    <p className="fw-bold">{data?.title}</p>
                    <p>{data?.defaultValue}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>

      {placeholderBool
        ? ojectFieldsArray?.length > 0 &&
          ojectFieldsArray?.map((data, i) => {
            return (
              <div>
                <div className="mt-2 poppins">
                  <span className="fs-5">{data?.key} </span>
                </div>
                <div className="mt-2 profileFieldType">
                  <>
                    <Input
                      type="text"
                      id="numberOfOptionInputField"
                      className={`profileField-inputField px-2 ${
                        disableGoButton === true ? "disable" : ""
                      } `}
                      name="numberOfOption"
                      divStyle={{
                        fontSize: "18px",
                        width: "19%",
                      }}
                      value={ojectFields["field" + i]}
                      onChange={(e) => {
                        setObjectFields({
                          ...ojectFields,
                          ["field" + i]: e.target.value,
                        });
                        setPlaceholder({
                          ...placeholder,
                          [data.key.replace(/[\/\s&]/g, "_").toLowerCase()]:
                            e.target.value,
                        });
                        setArrayField({
                          ...arrayField,
                          placeholder: {
                            ...placeholder,
                            [data.key.replace(/[\/\s&]/g, "_").toLowerCase()]:
                              e.target.value,
                          },
                        });
                      }}
                      divClass="poppins numberOfOptionInputField"
                      placeholder={`Enter ${data.key}`}
                    />
                    {/* <div className="numberOfOptionButtons">
                      <button
                        type="button"
                        // className="btn user-profile-saveBtn"
                        id="numberOfOptionGoBtn"
                        className={`btn profile-field-goBtn mx-2 ${
                          disableGoButton === true ? "go-btn-disable" : ""
                        }`}
                        onClick={() => {
                          setObjectFieldsArray([
                            ...ojectFieldsArray,
                            ojectField,
                          ]);
                          setObjectField("");
                        }}
                        disabled={disableGoButton ? true : false}
                      >
                        Add
                      </button>
                    </div> */}
                  </>
                </div>
              </div>
            );
          })
        : ""}
    </div>
  );
}

export default ProfileFieldArray;
