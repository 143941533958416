import { all, fork, put, takeLatest } from "redux-saga/effects";
import { getQuery, postQuery, putQuery, deleteQuery } from "utils/api";
import { Toast } from "utils/toast";
import { actions } from "./action";
// import loaderAction from "redux/Loader/action";

export function* createTutor() {
  yield takeLatest(
    actions.CREATE_TUTOR_REQUEST,
    function* ({ data, navigate }) {
      try {
        const response = yield postQuery(`tutor`, data);

        if (!response.data.error) {
          yield put({
            type: actions.CREATE_TUTOR_SUCCESS,
            payload: [response?.data?.result],
          });
          Toast("success", "Tutor Created Successfully!");
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e?.response?.data?.message);
      }
    }
  );
}

export function* updateTutor() {
  yield takeLatest(actions.UPDATE_TUTOR_REQUEST, function* ({ data, alert }) {
    try {
      const response = yield putQuery(`tutor`, data);
      if (!response.data.error) {
        if (data.get("role") === "clubAdmin") {
          yield put({
            type: actions.UPDATE_TUTOR_SUCCESS,
            payload: response?.data?.result,
          });
        }
        alert && Toast("success", "Tutor Updated Successfully!");

        if (
          (!alert && data?.isDelete === true) ||
          data.get("isDelete") === "true"
        ) {
          yield put({
            type: actions.REMOVE_TUTOR_SUCCESS,
            payload: response?.data?.result,
          });
        }
        if (
          (!alert && data?.isDelete === false) ||
          data.get("isDelete") === "false"
        ) {
          yield put({
            type: actions.UNREMOVE_TUTOR_SUCCESS,
            payload: response?.data?.result,
          });
        }
      }
    } catch (e) {
      console.log(e);
      yield put({
        type: actions.CATCH_ERROR,
        payload: true,
      });
      Toast("error", e.response.data.message);
    }
  });
}

export function* deleteTutor() {
  yield takeLatest(
    actions.DELETE_TUTOR_REQUEST,
    function* ({ data, navigate }) {
      try {
        const response = yield deleteQuery(`tutor/remove?tutorId=${data}`);

        if (!response.data.error) {
          yield put({
            type: actions.DELETE_TUTOR_SUCCESS,
            payload: data,
          });
          Toast("success", "Tutor Deleted Successfully!");
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
      }
    }
  );
}

export function* getTutors() {
  yield takeLatest(actions.GET_TUTORS_REQUEST, function* ({ data }) {
    try {
      const response = yield getQuery(`tutor?clubId=${data}`);
      if (!response?.data?.error) {
        // response.data.result.sort((a, b) => {
        //   return a.isDelete - b.isDelete;
        // });
        const resData = response?.data?.result?.find((data) => {
          return data?.role === "clubAdmin";
        });

        const filterData = response?.data?.result?.filter((data) => {
          return data?.role !== "clubAdmin";
        });

        const sortData = filterData?.sort((a, b) => {
          return a?.name.localeCompare(b?.name);
        });
        yield put({
          type: actions.GET_TUTORS_SUCCESS,
          // payload: response.data.result,
          payload:
            response?.data?.result?.length > 0 ? [resData, ...sortData] : [],
        });
      }
    } catch (e) {
      console.log(e);
    }
  });
}

export function* getClubAdmins() {
  yield takeLatest(actions.GET_CLUB_ADMINS_REQUEST, function* ({ data }) {
    try {
      const response = yield getQuery(`clubAdmin`);
      if (!response?.data?.error) {
        yield put({
          type: actions.GET_CLUB_ADMINS_SUCCESS,
          payload: response?.data?.result,
        });
      }
    } catch (e) {
      console.log(e);
    }
  });
}

export function* clearTutorsState() {
  yield takeLatest(actions.CLEAR_TUTORS_STATE_REQUEST, function* () {
    try {
      yield put({
        type: actions.CLEAR_TUTORS_STATE_SUCCESS,
        payload: [],
      });
    } catch (e) {
      console.log(e);
    }
  });
}

export default function* tutorSaga() {
  yield all([
    fork(createTutor),
    fork(getTutors),
    fork(updateTutor),
    fork(getClubAdmins),
    fork(deleteTutor),
    fork(clearTutorsState),
    // fork(getClub),
  ]);
}
