import Input from "components/Input";
import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import Button from "components/Button";
import { actions } from "redux/User/action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actions as loaderAction } from "redux/Loader/action";

const Password = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error: err } = useSelector((state) => state.user);
  const [viewPassword, setViewPassword] = useState(false);
  const [passwordValid, setPasswordValid] = useState(true);
  const [error, setError] = useState("");
  const [userData, setUserData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleChange = (event) => {
    if (
      event.target.name !== "currentPassword" &&
      event.target.name !== "confirmPassword"
    ) {
      setPasswordValid(checkPassword(event.target.value));
      // if (checkPassword(event.target.value));
    }

    setUserData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const generatePassword = (length = 8) => {
    const Allowed = {
      Uppers: "QWERTYUIOPASDFGHJKLZXCVBNM",
      Lowers: "qwertyuiopasdfghjklzxcvbnm",
      Numbers: "1234567890",
      Symbols: "!@#$%^&`:|.;*",
    };

    const getRandomCharFromString = (str) =>
      str.charAt(Math.floor(Math.random() * str.length));

    let pwd = "";
    pwd += getRandomCharFromString(Allowed.Uppers); // pwd will have at least one upper
    pwd += getRandomCharFromString(Allowed.Lowers); // pwd will have at least one lower
    pwd += getRandomCharFromString(Allowed.Numbers); // pwd will have at least one number
    pwd += getRandomCharFromString(Allowed.Symbols); // pwd will have at least one symbol
    for (let i = pwd.length; i < length; i++)
      pwd += getRandomCharFromString(Object.values(Allowed).join("")); // fill the rest of the pwd with random characters
    return pwd;
  };

  function checkPassword(str) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&`:|.;*])(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
    return re.test(str);
  }

  const handleSubmit = () => {
    const errors =
      !userData?.currentPassword ||
      !userData?.confirmPassword ||
      !userData?.newPassword;
    if (!errors) {
      if (userData?.newPassword === userData?.confirmPassword) {
        dispatch(loaderAction.startLoader());
        dispatch(actions?.updatePassword(userData, navigate));
        setPasswordValid(true);
        setError("");
      } else {
        setError("Password does't match!");
      }
    } else {
      setError("All fields are required!");
    }
  };

  return (
    <div>
      <div className="mb-3 mx-auto">
        <div className="">
          <div className="w-100">
            <Input
              type="password"
              className="inputField mt-2 px-2"
              id="currentPassword"
              name="currentPassword"
              label="Current Password"
              divStyle={{ fontSize: "18px" }}
              value={userData?.currentPassword}
              onChange={(e) => handleChange(e)}
            />
          </div>

          <div className="createField" style={{ fontSize: "18px" }}>
            <p>New Password</p>
            <div className="input-group mt-2">
              <input
                type={viewPassword ? "text" : "password"}
                className="form-control border-end-0"
                name="newPassword"
                // placeholder="Password"
                value={userData?.newPassword}
                // onChange={(e) => handleChange(e)}
                onChange={handleChange}
                maxLength="16"
              />
              <span className="input-group-text bg-white border-start-0 text-secondary">
                <FeatherIcon
                  icon={!viewPassword ? "eye" : "eye-off"}
                  size={20}
                  onClick={() => setViewPassword(!viewPassword)}
                />
              </span>
              <span
                className="input-group-text"
                id="basic-addon2"
                onClick={() => {
                  let pass = generatePassword(8);
                  setUserData({
                    ...userData,
                    newPassword: pass,
                  });
                  setPasswordValid(checkPassword(pass));
                }}
              >
                Generate
              </span>
            </div>
          </div>

          <div className="w-100">
            <Input
              type="password"
              className="inputField mt-2 px-2"
              id="tutorCPassword"
              name="confirmPassword"
              label="Confirm Password"
              divStyle={{ fontSize: "18px" }}
              value={userData?.confirmPassword}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        {!passwordValid && (
          <p style={{ fontSize: "small" }} className="fw-bold text-danger">
            Your Password is must include at least one uppercase, one lowercase,
            one special character and 8 to 16 characters long.
          </p>
        )}
        <p style={{ fontSize: "small" }} className="fw-bold text-danger">
          {error ? error : err ? err : ""}
        </p>

        <Button
          type={"button"}
          className="btn greenButton w-100 mt-3"
          label={"Change Password"}
          handleClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default Password;
