import ControlButton from "components/ControlButton";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import "./GradingCategory.css";
import React from "react";
import { useState } from "react";
import NewCategory from "components/NewCategory";
import Button from "components/Button";
import { uuid } from "utils/uuid";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/Course/action";
import { actions as loaderActions } from "redux/Loader/action";

const GradingCategory = ({
  inputDisable,
  setInputDisable,
  setNewCategory,
  label,
  showIcon,
  error,
  setError,
  qualificationId,
  instituteId,
}) => {
  const dispatch = useDispatch();
  const { networkProgressDialog } = useSelector((state) => state.loader);
  const GradingLabelId = uuid();
  const [gradOption, setGradOption] = useState([]);
  const [isShowOption, setIsShowOption] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [gradingCategoryLabel, setGradingCategoryLabel] = useState({
    id: GradingLabelId,
    name: "",
  });
  const [gradingValue, setGradingValue] = useState([
    {
      name: "",
    },
  ]);

  const handleAddOption = (data) => {
    if (gradingCategoryLabel?.name !== "") {
      setIsShowOption(true);
      setGradOption([...gradOption, data]);
    } else {
      setError({
        id: gradingCategoryLabel.id,
        message: `Please Enter Label`,
      });
    }
  };

  const handleChangeGradingLabel = (event) => {
    setGradingCategoryLabel((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    setError({ message: "" });
    // setDisableSave(true);
  };

  const handleSaveBtn = (data) => {
    dispatch(loaderActions.startLoader());
    dispatch(actions.createGrade({ ...data, qualificationId, instituteId }));
  };

  return (
    <>
      <div>
        <div className="position-absolute MainGradCategory">
          <div className="GradingCategory">
            <p className="GradingCategoryTitle">Enter Label</p>
            <div className="SaveButton d-flex">
              <FeatherIcon
                className={`Chevron_Right`}
                icon={`chevron-right`}
                size={35}
                strokeWidth={1}
              />

              <input
                className="GradingCategoryInput "
                type="text"
                name="name"
                value={gradingCategoryLabel?.name}
                onChange={handleChangeGradingLabel}
              />
            </div>
            {error.id === gradingCategoryLabel.id && error.message && (
              <p
                id="login_validation"
                style={{ fontSize: "small", marginLeft: "34px" }}
                className="text-danger mt-1 GradingLabel"
              >
                {error.message}
              </p>
            )}
            {isShowOption &&
              gradOption?.map((data) => (
                <div key={data.id} className="GradOption">
                  <NewCategory
                    data={data}
                    buttonLabel={"GO"}
                    showIcon={showIcon}
                    error={error}
                    setError={setError}
                    gradOption={gradOption}
                    setGradOption={setGradOption}
                    gradingValue={gradingValue}
                    setGradingValue={setGradingValue}
                    disableSave={disableSave}
                    setDisableSave={setDisableSave}
                    // inputDisable={inputDisable}
                    // setInputDisable={setInputDisable}
                  />
                </div>
              ))}
            <div className="d-flex justify-content-between AddBtn">
              <ControlButton
                className="AddOption click"
                label="+ add grade Option"
                onClick={() =>
                  handleAddOption({
                    id: uuid(),
                    name: gradingValue?.name,
                    // value: gradOption?.length + 1,
                    value:
                      gradOption.length > 0 ? gradOption?.length - 1 + 1 : 0,
                  })
                }
                classNameLabel="label"
              />

              {!networkProgressDialog ? (
                <>
                  <Button
                    // className={`SaveOption click text-center`}
                    className={`SaveOption text-center ${
                      disableSave === false ? "disabled" : ""
                    }`}
                    label="Save"
                    classNameLabel="SaveLabel"
                    disabled={disableSave === false ? !disableSave : ""}
                    handleClick={() => {
                      handleSaveBtn({
                        id: "",
                        label: gradingCategoryLabel?.name,
                        grading: gradOption,
                      });
                      setNewCategory(false);
                    }}
                  />
                </>
              ) : (
                <>
                  <button className="saveLoader">
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GradingCategory;
