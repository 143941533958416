import Card from "components/Card";
import Navbar from "components/Navbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as tutorsAction } from "redux/Tutor/action";
import { useNavigate, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

function ClubAdminDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tutors } = useSelector((state) => state.tutor);
  const { user } = useSelector((state) => state.user);
  const [tutorDataUpdate, setTutorDataUpdate] = useState(null);
  const params = useParams();
  let optionList = [
    // {
    //   title: "Tutor info",
    //   navigate: "/",
    //   icon: "eye",
    // },
    {
      title: "Make as admin",
      // navigate: "/",
      icon: "user",
      // attribute: { "data-bs-toggle": "modal", "data-bs-target": "#myModal" },
      onClick: (email) => {
        const formData = new FormData();
        formData.append("email", email);
        formData.append("role", "clubAdmin");
        dispatch(tutorsAction.updateTutor(formData, false));
      },
    },
    {
      title: "Update",
      // navigate: "/",
      icon: "edit-3",
      attribute: { "data-bs-toggle": "modal", "data-bs-target": "#myModal" },
    },
    // {
    //   title: "Unarchive",
    //   icon: "archive",
    //   onClick: (email) => {
    //     dispatch(
    //       tutorsAction.updateTutor({ isDelete: false, email: email }, false)
    //     );
    //   },
    // },
    {
      title: "Archive",
      // navigate: "/",
      icon: "archive",
      onClick: (email) => {
        const formData = new FormData();
        formData.append("email", email);
        formData.append("isDelete", true);
        dispatch(tutorsAction.updateTutor(formData, false));
      },
    },
  ];

  useEffect(() => {
    if (user && !params?.id) {
      // user?.club?.id && dispatch(playersAction.getPlayers(user));
      user &&
        user?.club?.id &&
        dispatch(tutorsAction.getTutors(user?.club?.id));
    } else if (user && params?.id) {
      dispatch(tutorsAction.clearTutorsState());
      dispatch(tutorsAction.getTutors(params?.id));
    }
    // eslint-disable-next-line
  }, [user, dispatch]);

  return (
    <>
      <Navbar />

      <div className="container-fluid text-end">
        <div className="d-flex justify-content-between align-items-center">
          <div
            className="d-flex align-items-center text-secondary fw-bold"
            onClick={() => {
              params?.id
                ? navigate("/club/dashboard/" + params?.id)
                : navigate("/");
            }}
          >
            <FeatherIcon
              icon="arrow-left"
              size={16}
              className="cursor-pointer"
            />
            <span className="ps-1 cursor-pointer">Back</span>
          </div>

          <div className="text-end d-inline-block ms-auto">
            <div
              className="d-flex justify-content-end mt-3 btn btn-outline-success rounded-0 border-green archivedButton text-green"
              onClick={() => navigate("/tutors/archived")}
            >
              <span>
                <FeatherIcon icon="archive" size={16} />
              </span>
              <p className="ps-2">Archived</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5">
        <div
          className="d-flex h-100 flex-wrap justify-content-center"
          id="card"
        >
          <Card cardType="addItemCard" label="Add New Tutor" form="AddTutor" />
          <div></div>
          {tutors &&
            tutors.map((tutor) => {
              const optionListData =
                tutor && tutor?.role === "clubAdmin"
                  ? optionList?.filter((option) => {
                      return option?.title !== "Make as admin";
                    })
                  : optionList;
              return (
                <Card
                  image={tutor.image}
                  name={tutor.name}
                  designation={tutor.designation}
                  menuIcon={tutor}
                  optionList={optionListData}
                  cardType="tutorCard"
                  key={tutor.id}
                  tutor={tutor}
                  setTutorDataUpdate={setTutorDataUpdate}
                  tutorDataUpdate={tutorDataUpdate}
                />
              );
            })}
        </div>
      </div>
    </>
  );
}

export default ClubAdminDashboard;
