import React, { useEffect, useState } from "react";
import Button from "../Button";
import Heading from "../Heading";
import Input from "../Input";
import Navbar from "../Navbar";
import "./CustomLogin.css";
import { Link, useNavigate } from "react-router-dom";
//redux
import { useDispatch, useSelector } from "react-redux";

import { actions } from "../../redux/User/action";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { error: loginError } = useSelector((state) => state.user);

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");

  const handleChange = (event) => {
    setLoginData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = !loginData?.email || !loginData?.password;
    if (errors) {
      setError("Email and password are required!");
    } else {
      dispatch(
        actions.userLogin(
          { email: loginData?.email, password: loginData?.password },
          navigate
        )
      );
    }
  };

  useEffect(() => {
    setError(loginError);
    // setTimeout(function () {
    //   setError("");
    // }, 10000);
  }, [loginError]);

  return (
    <>
      <Navbar page="login" />
      <div id="controlCentreContainer" className="routes_background">
        <div id="playerCentre" className="loginSection">
          <div className="shadow-sm rounded-2 w-50 p-5 my-5 bg-light mx-auto loginFormDiv">
            <Heading
              type="h3"
              label="Login"
              className="alternate-gothi text-center"
            />
            <p className="text-muted text-center mb-4 poppins">
              Enter your credentials to access your account.
            </p>

            <form onSubmit={handleSubmit}>
              <div id="createSection" className="poppins">
                {/* <Input
                  name="email"
                  type="email"
                  className="fieldBox"
                  id="tutorEmail"
                  value={loginData.email}
                  label="Email Address"
                  onChange={(e) => handleChange(e)}
                />
                <Input
                  name="password"
                  type="password"
                  className="fieldBox"
                  id="tutorPassword"
                  value={loginData.password}
                  label="Password"
                  onChange={(e) => handleChange(e)}
                /> */}
                <Input
                  type="email"
                  className="inputField mt-2 px-2"
                  id="tutorEmail"
                  name="email"
                  label="Email Address"
                  divStyle={{ fontSize: "18px" }}
                  value={loginData?.email}
                  onChange={(e) => handleChange(e)}
                />
                <Input
                  name="password"
                  type="password"
                  className="inputField mt-2 px-2"
                  id="tutorPassword"
                  label="Password"
                  divStyle={{ fontSize: "18px" }}
                  value={loginData?.password}
                  onChange={(e) => handleChange(e)}
                />
                <p
                  id="login_validation"
                  style={{ color: "red", fontSize: "small" }}
                  className="fw-bold"
                >
                  {error}
                </p>
                <Link
                  to="/auth/forgot-password"
                  className="text-end text-success"
                >
                  Forgot Password?
                </Link>
                <Button
                  className="buttonClass greenButton mt-3"
                  label="Login"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
