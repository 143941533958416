import { all, fork, put, takeLatest } from "redux-saga/effects";
import { getQuery, postQuery, putQuery } from "utils/api";
import { Toast } from "utils/toast";
import { actions } from "./action";
import { actions as loaderAction } from "redux/Loader/action";

export function* getWorkExperience() {
  yield takeLatest(
    actions.GET_WORK_EXPERIENCE_REQUEST,
    function* ({ data, navigate }) {
      try {
        const response = yield postQuery(`get-work-experience-by-admin`, data);
        if (!response.data.error) {
          yield put({
            type: actions.GET_WORK_EXPERIENCE_SUCCESS,
            payload: response.data.result,
          });
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
        Toast("error", e.response.data.message);
      }
    }
  );
}

export function* createWorkExperience() {
  yield takeLatest(
    actions.CREATE_WORK_EXPERIENCE_REQUEST,
    function* ({ data }) {
      try {
        const response = yield postQuery(`create/work-experience`, data);
        if (!response.data.error) {
          yield put({
            type: actions.CREATE_WORK_EXPERIENCE_SUCCESS,
            payload: response.data.result,
          });
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
        Toast("error", e.response.data.message);
      }
    }
  );
}

export function* updateWorkExperience() {
  yield takeLatest(
    actions.UPDATE_WORK_EXPERIENCE_REQUEST,
    function* ({ data }) {
      try {
        const response = yield putQuery(`update/work-experience`, data);
        if (!response.data.error) {
          yield put({
            type: actions.UPDATE_WORK_EXPERIENCE_SUCCESS,
            payload: response.data.result,
          });
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
        Toast("error", e.response.data.message);
      }
    }
  );
}

export function* getWorkExperienceTiles() {
  yield takeLatest(
    actions.GET_WORK_EXPERIENCE_TILES_REQUEST,
    function* ({ data }) {
      try {
        const response = yield getQuery(
          `get-work-experience-tiles?clubId=${data.clubId}`
        );
        if (!response.data.error) {
          yield put({
            type: actions.GET_WORK_EXPERIENCE_TILES_SUCCESS,
            payload: response.data.result,
          });
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
        Toast("error", e.response.data.message);
      }
    }
  );
}

export default function* workExperienceSaga() {
  yield all([fork(getWorkExperience)]);
  yield all([fork(createWorkExperience)]);
  yield all([fork(updateWorkExperience)]);
  yield all([fork(getWorkExperienceTiles)]);
}
