import setToken from "utils/setToken";
import { actions } from "./action";

const initialState = {
  cv: null,
  cvs: [],
};

const cvReducer = (state = initialState, action) => {
  const token = localStorage.getItem("TOKEN");
  if (token) {
    setToken(token);
  }
  switch (action.type) {
    case actions.VIEW_CV_SUCCESS:
      return {
        ...state,
        cv: action.payload,
      };
    case actions.VIEW_ALL_CV_SUCCESS:
      return {
        ...state,
        cvs: action.payload,
      };
    default:
      return state;
  }
};

export default cvReducer;
