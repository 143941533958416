import { all, fork, put, takeLatest } from "redux-saga/effects";
import { getQuery, postQuery, putQuery } from "utils/api";
import { Toast } from "utils/toast";
import { actions } from "./action";
// import loaderAction from "redux/Loader/action";

export function* createClub() {
  yield takeLatest(actions.CREATE_CLUB_REQUEST, function* ({ data, navigate }) {
    try {
      const response = yield postQuery(`club`, data);
      if (!response.data.error) {
        response.data.result.club.email = response.data.result.tutor.email;
        yield put({
          type: actions.CREATE_CLUB_SUCCESS,
          payload: [response.data.result.club],
        });
        Toast("success", "Organisation Created Successfully!");
      }
    } catch (e) {
      console.log(e);
      yield put({
        type: actions.CATCH_ERROR,
        payload: true,
      });
      Toast("error", e.response.data.message);
    }
  });
}

export function* updateClub() {
  yield takeLatest(actions.UPDATE_CLUB_REQUEST, function* ({ data, alert }) {
    try {
      const response = yield putQuery(`club`, data);
      if (!response.data.error) {
        yield put({
          type: actions.UPDATE_CLUB_SUCCESS,
          payload: response.data.result,
        });
        alert && Toast("success", "Organisation Updated Successfully!");
        if (!alert && data.isEnable === false)
          yield put({
            type: actions.REMOVE_CLUB_SUCCESS,
            payload: response.data.result,
          });
        if (!alert && data.isEnable === true) {
          yield put({
            type: actions.UNREMOVE_CLUB_SUCCESS,
            payload: response.data.result,
          });
        }
      }
    } catch (e) {
      console.log(e);
      yield put({
        type: actions.CATCH_ERROR,
        payload: true,
      });
      Toast("error", e.response.data.message);
    }
  });
}

export function* getClubs() {
  yield takeLatest(actions.GET_CLUBS_REQUEST, function* () {
    try {
      const response = yield getQuery(`club`);

      if (!response.data.error) {
        // response.data.result.data.club.sort((a, b) => {
        //   return b.isEnable - a.isEnable;
        // });
        response.data.result.data.club.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        yield put({
          type: actions.GET_CLUBS_SUCCESS,
          payload: response.data.result.data.club,
        });
      }
    } catch (e) {
      console.log(e);
    }
  });
}

export default function* clubSaga() {
  yield all([
    // fork(getPlayers),
    fork(createClub),
    fork(updateClub),
    fork(getClubs),
  ]);
}
