import setToken from "utils/setToken";
import { actions } from "./action";

const initialState = {
  profile: null,
  profileFields: null,
  profileField: null,
  dynamicProfileFields: null,
  dynamicProfileField: null,
  organizationProfileFields: null,
  organizationProfileField: null,
  priorityProfileFields: null,
  error: null,
};

const profileReducer = (state = initialState, action) => {
  const token = localStorage.getItem("TOKEN");
  if (token) {
    setToken(token);
  }
  switch (action.type) {
    case actions.GET_PROFILE_FIELDS_SUCCESS:
      return {
        ...state,
        profileFields: action.payload,
      };
    case actions.CREATE_PROFILE_FIELD_SUCCESS:
      return {
        ...state,
        profileField: action.payload,
        profileFields: action.payload,
      };
    case actions.UPDATE_PROFILE_FIELD_SUCCESS:
      return {
        ...state,
        profileFields: action.payload,
      };
    case actions.DELETE_PROFILE_FIELD_SUCCESS:
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          profileFields: state.profileFields.profileFields.filter((profile) => {
            return profile?.id !== action?.payload?.profileFieldId;
          }),
        },
      };
    case actions.GET_DYNAMIC_PROFILE_FIELDS_SUCCESS:
      return {
        ...state,
        dynamicProfileFields: action.payload,
      };
    case actions.CREATE_NEW_DYNAMIC_PROFILE_SUCCESS:
      return {
        ...state,
        dynamicProfileField: action.payload,
        dynamicProfileFields: action.payload,
      };
    case actions.CREATE_DYNAMIC_PROFILE_FIELD_SUCCESS:
      return {
        ...state,
        dynamicProfileField: action.payload,
        dynamicProfileFields: action.payload,
      };
    case actions.UPDATE_DYNAMIC_PROFILE_FIELD_SUCCESS:
      return {
        ...state,
        dynamicProfileFields: action.payload,
      };
    case actions.DELETE_DYNAMIC_PROFILE_FIELD_SUCCESS:
      return {
        ...state,
        dynamicProfileFields: {
          ...state.dynamicProfileFields,
          profileFields: state.dynamicProfileFields.profileFields.filter(
            (profile) => {
              return profile?.id !== action?.payload?.profileFieldId;
            }
          ),
        },
      };
    case actions.GET_ORGANIZATION_PROFILE_FIELDS_SUCCESS:
      return {
        ...state,
        organizationProfileFields: action.payload,
      };
    case actions.CREATE_NEW_ORGANIZATION_PROFILE_SUCCESS:
      return {
        ...state,
        organizationProfileField: action.payload,
        organizationProfileFields: action.payload,
      };
    case actions.CREATE_ORGANIZATION_PROFILE_FIELD_SUCCESS:
      return {
        ...state,
        organizationProfileField: action.payload,
        organizationProfileFields: action.payload,
      };
    case actions.UPDATE_ORGANIZATION_PROFILE_FIELD_SUCCESS:
      return {
        ...state,
        organizationProfileFields: action.payload,
      };
    case actions.DELETE_ORGANIZATION_PROFILE_FIELD_SUCCESS:
      return {
        ...state,
        organizationProfileFields: {
          ...state.organizationProfileFields,
          profileFields: state.organizationProfileFields.profileFields.filter(
            (profile) => {
              return profile?.id !== action?.payload?.profileFieldId;
            }
          ),
        },
      };

    case actions.GET_PRIORITY_PROFILE_FIELDS_SUCCESS:
      return {
        ...state,
        priorityProfileFields: action.payload,
      };

    // case actions.ADD_PRIORITY_PROFILE_FIELDS_SUCCESS:
    //   return {
    //     ...state,
    //     priorityProfileFields: {
    //       ...state.priorityProfileFields,
    //       profileFields: [
    //         ...state.priorityProfileFields.profileFields,

    //         {
    //           id: action.payload.id,
    //           title: action.payload.title,
    //           type: "dynamic",
    //           priority: state.priorityProfileFields.profileFields.length,
    //         },
    //       ],
    //     },
    //   };
    case actions.ADD_PRIORITY_PROFILE_FIELDS_SUCCESS:
      if (Array.isArray(action.payload)) {
        // If multiple data items are present in the array
        const currentProfileFields = state.priorityProfileFields?.profileFields;

        const newDataWithoutDuplicates = action.payload.filter(
          (dataItem) =>
            !currentProfileFields.some((item) => item.id === dataItem.id)
        );
        const newProfileFields = newDataWithoutDuplicates.map(
          (dataItem, index) => ({
            id: dataItem?.id,
            title: dataItem?.title,
            type: "dynamic",
            priority: currentProfileFields?.length + (index + 1),
          })
        );

        return {
          ...state,
          priorityProfileFields: {
            ...state.priorityProfileFields,
            profileFields: [...currentProfileFields, ...newProfileFields],
          },
        };
      } else {
        // Single data addition
        return {
          ...state,
          priorityProfileFields: {
            ...state.priorityProfileFields,
            profileFields: [
              ...state.priorityProfileFields.profileFields,
              {
                id: action.payload?.id,
                title: action.payload?.title,
                type: "dynamic",
                priority:
                  state.priorityProfileFields?.profileFields?.length + 1,
              },
            ],
          },
        };
      }

    case actions.REMOVE_PRIORITY_PROFILE_FIELDS_SUCCESS:
      return {
        ...state,
        priorityProfileFields: {
          ...state.priorityProfileFields,
          profileFields: state.priorityProfileFields?.profileFields?.filter(
            (profile) => {
              return profile?.id !== action?.payload?.profileFieldId;
            }
          ),
        },
      };

    case actions.UPDATE_PRIORITY_PROFILE_FIELDS_SUCCESS:
      return {
        ...state,
        priorityProfileFields: action.payload,
      };

    case actions.CATCH_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default profileReducer;
