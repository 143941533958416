import React, { useEffect, useState } from "react";
import "./WorkExperience.css";
import { Carousel } from "primereact/carousel";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { actions } from "redux/WorkExperience/action";
import { actions as loaderAction } from "redux/Loader/action";

function WorkExperience({ player }) {
  const dispatch = useDispatch();
  const [courses, setCourses] = useState([]);
  const { networkProgressDialog } = useSelector((state) => state.loader);
  const { workExperience } = useSelector((state) => state.workExperience);
  const [workLogs, setWorkLogs] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("all");
  const [readMore, setReadMore] = useState({ id: "" });
  const [page, setPage] = useState(1);
  // const courses = [
  //   "ALL",
  //   "UEFA",
  //   "SEP",
  //   "Diploma",
  //   "Diploma",
  //   "Diploma",
  //   "Diploma",
  //   "Diploma",
  //   "Diploma",
  // ];

  const responsiveOptions = [
    {
      breakpoint: "1799px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const getWorkExperience = (data) => {
    dispatch(loaderAction?.startLoader());
    dispatch(
      actions?.getWorkExperience({
        userId: player?.id,
        page: data?.page,
        courseId: data?.courseId,
      })
    );
  };

  useEffect(() => {
    getWorkExperience({ courseId: "all", page: 1 });
  }, []);

  useEffect(() => {
    if (workExperience) {
      let courses =
        workExperience?.courses?.length > 0
          ? workExperience?.courses?.map((course) => {
              return course?.name;
            })
          : [];
      setCourses([...courses]);

      if (page > 1) {
        setWorkLogs([...workLogs, ...workExperience?.logs]);
      } else if (page === 1) {
        setWorkLogs(workExperience?.logs ? workExperience?.logs : []);
      }
    }
  }, [workExperience]);

  return (
    <div className="workExperience">
      {/* <div className="d-flex gap-2 coursesTab">
        <div className="courseTab">
          <p>ALL</p>
        </div>
        <div className="courseTab">
          <p>UEFA</p>
        </div>
        <div className="courseTab">
          <p>SEP</p>
        </div>
        <div className="courseTab">
          <p>Diploma</p>
        </div>
      </div> */}
      <div className="w-100">
        <Carousel
          value={courses}
          itemTemplate={(course) => {
            const course_ = workExperience?.courses?.find(
              (c) => c.name === course
            );
            return (
              <div
                className={
                  "courseTab" + (course_?.selected ? " selectedCourseTab" : "")
                }
                onClick={() => {
                  setPage(1);
                  setSelectedCourse(course_?.id);
                  getWorkExperience({ courseId: course_?.id, page: 1 });
                }}
              >
                <p>
                  {course?.length > 15 ? course?.slice(0, 15) + "..." : course}
                </p>
              </div>
            );
          }}
          numVisible={4}
          numScroll={1}
          responsiveOptions={responsiveOptions}
          showIndicators={false}
        ></Carousel>
      </div>

      {workLogs?.length > 0 ? (
        <div className="workExperienceLogs">
          <div className="workExperienceProgress">
            <div className="card__progress">
              <progress
                max={workExperience?.workExperienceHours?.totalHours}
                value={workExperience?.workExperienceHours?.totalSpendHours}
              ></progress>
            </div>
            <div class="card__indicator">
              <span class="card__indicator-amount">
                {workExperience?.workExperienceHours?.totalSpendHours} /{" "}
                {workExperience?.workExperienceHours?.totalHours}
              </span>
              <span class="ml-1">hrs</span>
            </div>
          </div>

          <div className="workLogs mt-4">
            {workLogs?.length > 0 &&
              workLogs?.map((work) => {
                return (
                  <div className="d-flex gap-3 workLog my-3">
                    <div className="workHours">
                      <p className="work-hour">{work?.hours}</p>
                      <p>Hrs</p>
                    </div>
                    <div className="workCommentDiv w-100">
                      <p className="workComment">
                        {readMore?.id === work?.id
                          ? work?.comment
                          : work?.comment?.length > 144
                          ? work?.comment?.slice(0, 141) + "..."
                          : work?.comment}
                        <p
                          className="text-primary cursor-pointer"
                          onClick={() => {
                            setReadMore({
                              id: readMore?.id === work?.id ? "" : work?.id,
                            });
                          }}
                        >
                          {readMore?.id === work?.id
                            ? "Read  Less"
                            : work?.comment?.length > 144
                            ? "Read More"
                            : ""}
                        </p>
                      </p>
                      <p className="mt-2 text-end">
                        {new Date(work?.createdAt).toDateString()}
                      </p>
                    </div>
                  </div>
                );
              })}

            {workExperience?.logs?.length >= 20 && (
              <div>
                <button
                  className="btn btn-outline-secondary btn-sm loadMoreBtn"
                  onClick={() => {
                    getWorkExperience({
                      courseId: selectedCourse,
                      page: page + 1,
                    });
                    setPage(page + 1);
                  }}
                >
                  Load More...
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="workExperienceLogs">
          <p className="text-center fw-bold poppins">No logs available</p>
        </div>
      )}
    </div>
  );
}

export default WorkExperience;
