import setToken from "utils/setToken";
import { actions } from "./action";

const initialState = {
  tutors: [],
  archivedTutors: [],
  error: false,
  tutor: {},
  clubAdmins: [],
};

const tutorReducer = (state = initialState, action) => {
  const token = localStorage.getItem("TOKEN");
  if (token) {
    setToken(token);
  }
  switch (action.type) {
    case actions.CREATE_TUTOR_SUCCESS:
      const resData = state.tutors.find((data) => {
        return data?.role === "clubAdmin";
      });

      const filterData = state.tutors.filter((data) => {
        return data?.role !== "clubAdmin";
      });

      const sortData = [...filterData, ...action.payload].sort((a, b) => {
        return a?.name.localeCompare(b?.name);
      });

      return {
        ...state,
        // tutors: [...state.tutors, ...action.payload].sort((a, b) =>
        //   a.name.localeCompare(b.name)
        // ),
        tutors: [resData, ...sortData],
      };

    case actions.GET_TUTORS_SUCCESS:
      return {
        ...state,
        tutors: action.payload.filter((tutor) => {
          return !tutor?.isDelete && tutor;
        }),
        archivedTutors: action.payload.filter((tutor) => {
          return tutor?.isDelete && tutor;
        }),
      };
    case actions.UPDATE_TUTOR_SUCCESS:
      let tutorsData = state.tutors.filter((tutor) => {
        return tutor?.id !== action.payload.id;
      });
      tutorsData = tutorsData?.map((tutor) => {
        tutor.role = "tutor";
        return tutor;
      });
      return {
        ...state,
        tutor: action.payload,
        tutors: [action.payload, ...tutorsData],
      };

    case actions.REMOVE_TUTOR_SUCCESS:
      // let tutors = state.tutors.filter((tutor) => {
      //   return tutor.id !== action.payload.id && tutor;
      // });
      // tutors.push(action.payload);
      return {
        ...state,
        tutor: action.payload,
        tutors: state.tutors.filter((tutor) => {
          return tutor?.id !== action?.payload?.id && tutor;
        }),
        archivedTutors: [...state.archivedTutors, action.payload].sort((a, b) =>
          a?.name.localeCompare(b?.name)
        ),
      };

    case actions.UNREMOVE_TUTOR_SUCCESS:
      // let tutorsData = state.tutors.filter((tutor) => {
      //   return tutor.id !== action.payload.id && tutor;
      // });
      return {
        ...state,
        tutor: action.payload,
        tutors: [...state.tutors, action.payload].sort((a, b) =>
          a?.name.localeCompare(b?.name)
        ),
        archivedTutors: state.archivedTutors.filter((tutor) => {
          return tutor?.id !== action?.payload?.id && tutor;
        }),
      };

    case actions.DELETE_TUTOR_SUCCESS:
      const DataTutors = state.tutors.filter((data) => {
        return action?.payload !== data?.id;
      });

      const resDataClubAdmin = DataTutors.find((data) => {
        return data?.role === "clubAdmin";
      });

      const filterDataTutor = DataTutors.filter((data) => {
        return data?.role !== "clubAdmin";
      });

      const sortDataAll = filterDataTutor.sort((a, b) => {
        return a?.name.localeCompare(b?.name);
      });

      return {
        ...state,
        // tutors: [...state.tutors, ...action.payload].sort((a, b) =>
        //   a.name.localeCompare(b.name)
        // ),
        tutors: [resDataClubAdmin, ...sortDataAll],
        archivedTutors: state.archivedTutors.filter((tutor) => {
          return tutor?.id !== action?.payload;
        }),
      };

    case actions.GET_CLUB_ADMINS_SUCCESS:
      return {
        ...state,
        clubAdmins: action.payload,
      };

    case actions.CLEAR_TUTORS_STATE_SUCCESS:
      return {
        ...state,
        tutors: [],
        archivedTutors: [],
      };

    case actions.CATCH_ERROR:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default tutorReducer;
