import "./Courses.css";
import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { CourseCard } from "components/CourseCard";
// import CourseCard from "components/CourseCards";
import { useDispatch, useSelector } from "react-redux";
import { actions as courseActions } from "redux/Course/action";
import { actions as loaderActions } from "redux/Loader/action";
import jwt_decode from "jwt-decode";
import Navbar from "components/Navbar";
import CreateCourse from "components/CreateCourse";
import { INSTITUTE_ID_ACADEMIC } from "utils/constants";
import { useLocation } from "react-router-dom";
import { isLabelWithInternallyDisabledControl } from "@testing-library/user-event/dist/utils";

const Courses = ({ playerCentre, player, setPlayerCentre }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  let token = localStorage.getItem("TOKEN");
  let decoded;
  if (token) {
    decoded = jwt_decode(token);
  }
  // eslint-disable-next-line
  const { courses, institutions, institutionTypes } = useSelector(
    (state) => state.course
  );
  const [dropdownValue, setDropdownValue] = useState(false);
  const [categoryName, setCategoryName] = useState({
    id: INSTITUTE_ID_ACADEMIC,
    name: "Academic",
  });
  const [instituteId, setInstituteId] = useState(INSTITUTE_ID_ACADEMIC);
  const [isCreateCourse, setIsCreateCourse] = useState(false);

  useEffect(() => {
    dispatch(courseActions.getInstitutions());
    // if () {
    dispatch(
      courseActions.getInstitutionTypes({
        instituteId: decoded?.club?.instituteId,
      })
    );
    // }
  }, [dispatch, decoded?.club?.instituteId]);

  useEffect(() => {
    if (location?.state?.instituteId) {
      dispatch(
        courseActions.getInstitutionTypes({
          instituteId: location?.state?.instituteId,
        })
      );
      const existingInstitution = institutions?.find((data) => {
        return data.id === location?.state?.instituteId;
      });
      setCategoryName({
        id: location?.state?.instituteId,
        name: existingInstitution?.name ? existingInstitution?.name : "",
      });
      // location.state = null;
    } else {
      dispatch(
        courseActions.getInstitutionTypes({
          instituteId: INSTITUTE_ID_ACADEMIC,
        })
      );
    }
    // eslint-disable-next-line
  }, [dispatch, institutions]);

  return (
    <>
      <Navbar />
      <div className="ExistingControlBtn courses">
        {/* <MainControlButton
          // className="SubControlBtn"
          playerCentre={playerCentre}
          player={player}
          setPlayerCentre={setPlayerCentre}
        /> */}
        <div
          className={`d-flex ${
            !isCreateCourse ? "justify-content-between" : "justify-content-end"
          }`}
        >
          {!isCreateCourse && (
            <div className="courses-dropdown">
              {/* <p className="ExistingText">{"Existing Courses"}</p> */}

              {decoded?.role === "admin" && (
                <div id="controlButtons" className="DropDownControlBtn">
                  <div className="dropdown ">
                    <button
                      className="dropbtn controlBtn click instituteTypeBtn "
                      onClick={() => {
                        setDropdownValue(!dropdownValue);
                      }}
                    >
                      {categoryName ? categoryName?.name : "ORGANIZATION"}
                      <FeatherIcon
                        icon={dropdownValue ? "chevron-up" : "chevron-down"}
                        // icon="chevron-down"
                        size={20}
                      />
                    </button>
                    <div
                      className={`dropdown-content ${
                        dropdownValue ? "active" : ""
                      }`}
                    >
                      {institutions?.length > 0 &&
                        institutions?.map((item, index) => (
                          <button
                            key={index}
                            // id="controlButtons"
                            id="SubDropdown"
                            className="dropdownbtn controlBtn click"
                            onClick={() => {
                              dispatch(loaderActions.startLoader());
                              dispatch(
                                courseActions.getInstitutionTypes({
                                  instituteId: item.id,
                                })
                              );

                              setInstituteId(item.id);
                              setDropdownValue(!dropdownValue);
                              setCategoryName(item);
                              location.state = null;
                              // ExistingCourseFilter({
                              //   id: index,
                              //   name: item,
                              // });
                            }}
                            // value={item}
                          >
                            {item?.name}
                          </button>
                        ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* comments of courses page in ControlButton */}
          {/* <div>
           
            <div className="dropdown MainBtnDropDown">
              <button
                className="dropbutton controlBtn click"
                // onClick={() => {
                //   setPlayerCentre("myCourseCenter");
                // }}
              >
                Courses
              </button>
              <div className="dropdown-content">
                <button
                  // id="controlButtons"
                  id="ViewExisting"
                  className="dropdownbtn controlBtn click subDropDown"
                  onClick={() => {
                    // setPlayerCentre("courseCenter");
                    setIsCreateCourse(false);
                  }}
                  style={{ fontSize: "15px" }}
                >
                  View Existing
                </button>
                <button
                  // id="controlButtons"
                  id="createNew"
                  className="dropdownbtn controlBtn click "
                  onClick={() => {
                    setIsCreateCourse(true);
                  }}
                >
                  Create New
                </button>
              </div>
            </div>
          </div> */}

          {/* {!isCreateCourse && (
          <div>
            <p className="ExistingText">{"Existing Courses"}</p>

            {decoded?.role === "admin" && (
              <div id="controlButtons" className="DropDownControlBtn">
                <div className="dropdown ">
                  <button
                    className="dropbtn controlBtn click instituteTypeBtn "
                    onClick={() => {
                      setDropdownValue(!dropdownValue);
                    }}
                  >
                    {categoryName ? categoryName?.name : "INSTITUTION TYPES"}
                    <FeatherIcon
                      icon={dropdownValue ? "chevron-up" : "chevron-down"}
                      // icon="chevron-down"
                      size={20}
                    />
                  </button>
                  <div
                    className={`dropdown-content ${
                      dropdownValue ? "active" : ""
                    }`}
                  >
                    {institutions?.length > 0 &&
                      institutions?.map((item, index) => (
                        <button
                          key={index}
                          // id="controlButtons"
                          id="SubDropdown"
                          className="dropdownbtn controlBtn click"
                          onClick={() => {
                            dispatch(loaderActions.startLoader());
                            dispatch(
                              courseActions.getInstitutionTypes({
                                instituteId: item.id,
                              })
                            );
                            setDropdownValue(!dropdownValue);
                            setCategoryName(item);
                            // ExistingCourseFilter({
                            //   id: index,
                            //   name: item,
                            // });
                          }}
                          // value={item}
                        >
                          {item?.name}
                        </button>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        )} */}
        </div>
        {categoryName && !isCreateCourse && (
          <CourseCard
            CardName={institutionTypes}
            institutionTypes={institutionTypes}
            instituteId={instituteId}
            categoryName={categoryName}
            // setPlayerCentre={setPlayerCentre}
            // playerCentre={playerCentre}
          />
        )}

        {/* {categoryName && !isCreateCourse && (
          <QualificationCard
            CardName={institutionTypes}
            institutionTypes={institutionTypes}
            instituteId={instituteId}
            // setPlayerCentre={setPlayerCentre}
            // playerCentre={playerCentre}
          />
        )} */}

        {isCreateCourse && <CreateCourse playerCentre={"superCourseCenter"} />}
      </div>
    </>
  );
};

export default Courses;
