import plus1 from "assets/images/plus1.png";
import Heading from "components/Heading";
import Image from "components/Image";
import Navbar from "components/Navbar";
import FeatherIcon from "feather-icons-react";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { actions } from "redux/Course/action";
import { actions as loaderActions } from "redux/Loader/action";
import Swal from "sweetalert2";
import { SweetAlert } from "utils/SweetAlert";
import { uuid } from "utils/uuid";
import "./CourseCard.css";

const QualificationCard = ({
  institutionTypes,
  setPlayerCentre,
  playerCentre,
  instituteId,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const { user } = useSelector((state) => state.user);
  let token = localStorage.getItem("TOKEN");
  let decoded;
  if (token) {
    decoded = jwt_decode(token);
  }
  const { networkProgressDialog } = useSelector((state) => state.loader);
  const { qualifications } = useSelector((state) => state.course);
  // eslint-disable-next-line
  const [originalQualification, setOriginalQualification] = useState([]);
  const [qualificationState, setQualificationState] = useState([]);

  const handleCard = (data) => {
    let playerCenter_ = playerCentre;
    setPlayerCentre && setPlayerCentre("courseUnitCenter");
    navigate(
      decoded?.role === "admin"
        ? `/courses/course/${data?.id}`
        : `/course/${data?.id}`,
      {
        state: {
          ...location?.state,
          // step: data?.name ? data?.name : "",
          step1: data?.name ? data?.name : "",
          playerCenter: playerCenter_,
          instituteId: qualifications[qualifications?.length - 1].instituteId,
          instituteTypeId: params?.instituteTypeId,
        },
      }
    );
  };

  const optionList = [
    {
      title: "Update",
      // navigate: "/",
      icon: "edit-3",
      onClick: (id, data) => {
        const indexOfQualificationType = qualificationState.findIndex(
          (data) => {
            return data.id === id;
          }
        );
        qualificationState[indexOfQualificationType].createNew = true;
        qualificationState[indexOfQualificationType].updateAt = true;
        setQualificationState([...qualificationState]);
      },
    },
    // {
    //   title: "Unarchive",
    //   icon: "archive",
    //   onClick: (id) => {
    //     dispatch(actions.updateClub({ isEnable: true, id: id }, false));
    //   },
    // },
    {
      title: "Delete",
      // navigate: "/",
      icon: "trash-2",
      onClick: async (id, data) => {
        const result = await SweetAlert({
          title: "Are you sure? ",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
          confirmButtonColor: "#006b00",
        });
        if (result.isConfirmed) {
          const filterQualificationType = qualificationState.filter((data) => {
            return data.id !== id;
          });
          setQualificationState([...filterQualificationType]);
          if (!data?.createNew) {
            dispatch(
              actions.updateQualification({
                active: false,
                id: id,
              })
            );
          }

          Swal.fire({
            title: "Deleted!",
            text: "Your course type has been deleted.",
            icon: "success",
            confirmButtonColor: "#006b00",
          });
        }
      },
    },
  ];

  useEffect(() => {
    dispatch(loaderActions.startLoader());
    dispatch(
      actions.getQualifications({
        instituteTypeId: params?.instituteTypeId,
      })
    );
  }, [dispatch, params?.instituteTypeId]);

  useEffect(() => {
    let qualifications_ = structuredClone(qualifications);
    setQualificationState([...qualifications_]);
    setOriginalQualification([...qualifications_]);
    // localStorage.setItem("institutionType", JSON.stringify(institutionTypes));
  }, [qualifications]);

  return (
    <>
      <Navbar />
      {networkProgressDialog ? (
        <div id="cover-spin" className="LoaderSpin">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex CardTitle mt-3 px-3">
            <p
              className="CardExistingText"
              onClick={() => {
                if (playerCentre === "courseUnitCenter") {
                  setPlayerCentre("courseCenter");
                }
                if (playerCentre === "superCourseCenter") {
                  navigate("/courses", {
                    state: {
                      instituteId:
                        qualifications?.length > 0
                          ? qualifications[qualifications?.length - 1]
                              .instituteId
                          : location?.state?.instituteId,
                    },
                  });
                }
              }}
            >
              {`${
                location.pathname.includes("/qualification-type")
                  ? "AVAILABLE COURSE TYPES"
                  : "AVAILABLE COURSES"
              }`}{" "}
              | {location?.state?.step_0.toUpperCase()}
            </p>
            {playerCentre !== "myCourseCenter" && (
              <>
                <FeatherIcon
                  className="RightIcon"
                  icon={`chevron-right`}
                  size={30}
                  strokeWidth={2}
                />

                <p
                  // className="CardDynamicTitle"
                  className={"CardStaticTitle"}
                  // onClick={() => {

                  // }}
                >
                  {location?.state?.step}
                </p>
              </>
            )}
            {/* {!isShow && (
              <>
                <FeatherIcon
                  className="EditRightIcon"
                  icon={`chevron-right`}
                  size={35}
                  strokeWidth={2}
                />

                {!isShow && !duplicateShow ? (
                  <p className="CardStaticTitle">DUPLICATE & EDIT </p>
                ) : (
                  <p className="CardStaticTitle">EDIT EXISTING</p>
                )}
              </>
            )} */}
          </div>

          <div className="cards-list container-fluid">
            <div
              className="CourseCard"
              onClick={() => {
                setQualificationState([
                  { id: uuid(), name: "", createNew: true },
                  ...qualificationState,
                ]);
              }}
            >
              <div className="title-white">
                <div className={`flex-shrink-0 d-flex align-items-center`}>
                  <Image
                    src={plus1}
                    alt=""
                    className="img-fluid w-100 club_image_card cardBodyAddCard"
                    style={{ borderRadius: "10px", filter: "invert(1)" }}
                  />
                </div>
                <div className="w-100">
                  <Heading
                    type="h5"
                    className={`mb-0 poppins text-truncate text-center text-white`}
                    label={"Add Course Type"}
                  />
                </div>
              </div>
            </div>

            {qualificationState?.length > 0 ? (
              qualificationState &&
              qualificationState.map((item, index) => {
                // setInstituteValue(item.name)
                return (
                  <div className="CourseCard" key={index}>
                    <div
                      className="text-end p-0 position-absolute"
                      style={{ right: "10px", top: "7px" }}
                    >
                      <div className={`dropdown dropdownClub`}>
                        <span
                          className={`float-end text-body iconColor`}
                          data-bs-toggle="dropdown"
                        >
                          <FeatherIcon
                            icon={"more-horizontal"}
                            size={24}
                            className="text-white"
                          />
                        </span>
                        <ul className="dropdown-menu dropdownClubMenu">
                          {optionList &&
                            optionList.map((data, i) => {
                              return (
                                <li
                                  key={i + 1}
                                  className="dropdown-item dropdownItem poppins fw-bold px-2 mx-2"
                                  onClick={() => {
                                    data?.onClick(item?.id, item);
                                  }}
                                >
                                  {/* <Link className="dropdown-item dropdownItem poppins fw-bold px-2 mx-2"> */}
                                  <FeatherIcon
                                    icon={data?.icon && data?.icon}
                                    size={15}
                                    className="me-2"
                                  />
                                  {data?.title}
                                  {/* </Link> */}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                      {/* <FeatherIcon
                    icon="trash-2"
                    size={20}
                    className="text-white"
                  /> */}
                    </div>

                    <div
                      className="card_title title-white poppins"
                      onClick={() => {
                        if (!item?.createNew && !item?.isCancel) {
                          handleCard(item);
                        } else {
                          const indexOfQualificationType =
                            qualifications.findIndex((data) => {
                              return data.id === item?.id;
                            });
                          if (indexOfQualificationType !== -1) {
                            qualificationState[
                              indexOfQualificationType
                            ].isCancel = false;
                            setQualificationState([...qualificationState]);
                          }
                        }
                      }}
                    >
                      {item?.createNew ? (
                        <>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              const indexOfQualificationType =
                                qualificationState.findIndex((data) => {
                                  return data.id === item.id;
                                });
                              qualificationState[
                                indexOfQualificationType
                              ].createNew = false;
                              setQualificationState([...qualificationState]);
                              !item?.updateAt &&
                                dispatch(
                                  actions.createQualification({
                                    name: qualificationState[
                                      indexOfQualificationType
                                    ].name,
                                    instituteId:
                                      qualificationState[
                                        qualificationState?.length - 1
                                      ].instituteId,
                                    instituteTypeId: params?.instituteTypeId,
                                    userId: user.id,
                                  })
                                );
                              item?.updateAt &&
                                dispatch(
                                  actions.updateQualification({
                                    name: qualificationState[
                                      indexOfQualificationType
                                    ].name,
                                    id: qualificationState[
                                      indexOfQualificationType
                                    ].id,
                                  })
                                );
                            }}
                            className="position-relative"
                          >
                            <div
                              className={
                                "createField flex-row align-items-center"
                              }
                              style={{ backgroundColor: "white" }}
                            >
                              <input
                                type="text"
                                className="inputField"
                                id="clubName"
                                // label="Club Name"
                                name="name"
                                value={item.name}
                                onChange={(e) => {
                                  const indexOfQualificationType =
                                    qualificationState.findIndex((data) => {
                                      return data.id === item.id;
                                    });
                                  qualificationState[
                                    indexOfQualificationType
                                  ].name = e.target.value;
                                  setQualificationState([
                                    ...qualificationState,
                                  ]);
                                }}
                                style={{ width: "85%", fontSize: "18px" }}
                              />
                              {item?.updateAt && (
                                <div
                                  onClick={() => {
                                    // const isExistQualificationType = JSON.parse(
                                    //   localStorage.getItem("institutionType")
                                    // );

                                    const indexOfQualificationType =
                                      qualifications.findIndex((data) => {
                                        return data.id === item?.id;
                                      });
                                    qualificationState[
                                      indexOfQualificationType
                                    ].createNew = false;
                                    qualificationState[
                                      indexOfQualificationType
                                    ].isCancel = true;
                                    qualificationState[
                                      indexOfQualificationType
                                    ].name =
                                      qualifications[
                                        indexOfQualificationType
                                      ].name;
                                    setQualificationState([
                                      ...qualificationState,
                                    ]);
                                  }}
                                >
                                  <FeatherIcon
                                    icon="x"
                                    size={20}
                                    className="removeInputIcon pl-1"
                                  />
                                </div>
                              )}
                            </div>
                            {/* <Input
                          type="text"
                          className="inputField mt-2 px-2"
                          id="clubName"
                          // label="Club Name"
                          name="name"
                          divStyle={{ fontSize: "18px" }}
                          value={item.name}
                          onChange={(e) => {
                            const indexOfInstituteType =
                              instituteType.findIndex((data) => {
                                return data.id === item.id;
                              });
                            instituteType[indexOfInstituteType].name =
                              e.target.value;
                            setInstituteType([...instituteType]);
                          }}
                          style={{ width: "91%" }}
                        /> */}
                            {/* <button type="submit" className="d-none"></button> */}
                          </form>
                        </>
                      ) : (
                        // <button></button>

                        <p style={{ whiteSpace: "break-spaces" }}>
                          {item?.name
                            ? item?.name.replaceAll(" ", "\n").toUpperCase()
                            : ""}
                        </p>
                      )}

                      {/* {<p>
                    {item?.name?.length > 15
                      ? item?.name?.charAt(0).toUpperCase() +
                        item?.name?.slice(1, 14)?.toLowerCase() +
                        "..."
                      : item?.name?.charAt(0).toUpperCase() +
                        item?.name?.slice(1, item.name.length)?.toLowerCase()}
                  </p>} */}
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
              // <div className="text-center mt-5">
              //   {" "}
              //   <p>There are no available courses.</p>{" "}
              // </div>
            )}
          </div>
        </>
      )}

      {/* <CourseCardInfo /> */}

      {/* <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2">
            <div className="CourseCard card ">
              <div className="card-body">
                <h5 className="card_title">FOOTBALL</h5>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default QualificationCard;
