import React from "react";
import "./ControlButton.css";

const ControlButton = (props) => {
  const {
    id = "",
    className = "",
    onClick,
    label = "",
    classNameLabel,
  } = props;
  return (
    <div id={id} className={className} onClick={onClick}>
      <p className={classNameLabel}>{label}</p>
    </div>
  );
};

export default ControlButton;
