import React, { useEffect, useState } from "react";
import { PROFILE_FIELD_TYPES } from "utils/constants";
import { PROFILE_FIELD_CATEGORIES } from "utils/constants";
import Input from "components/Input";
import { Dropdown } from "primereact/dropdown";
import Label from "components/Label";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Button from "components/Button";
import { uuid } from "utils/uuid";
import EditProfileFieldArray from "components/ProfileFieldArray/EditProfileFieldArray";

function EditProfileField({
  updateProfileField,
  setUpdateProfileField,
  options,
  arrayField,
  setArrayField,
}) {
  const optionsProfileFields =
    PROFILE_FIELD_TYPES &&
    PROFILE_FIELD_TYPES?.length > 0 &&
    PROFILE_FIELD_TYPES?.map((data) => {
      return { name: data?.typeName, value: data?.typeName?.toLowerCase() };
    });

    const fieldCategories =
    PROFILE_FIELD_CATEGORIES &&
    PROFILE_FIELD_CATEGORIES?.length > 0 &&
    PROFILE_FIELD_CATEGORIES?.map((data) => {
      return { name: data?.typeName, value: data?.typeName?.toLowerCase() };
    });

  const [isShowAddMoreInput, setIsShowAddMoreInput] = useState(false);
  const [newOptionField, setNewOptionField] = useState("");
  const [isShowInputField, setIsShowInputField] = useState({
    id: "",
    isShow: false,
  });
  const [error, setError] = useState({
    message: "",
    name: "",
  });
  // const [arrayField, setArrayField] = useState({
  //   arrayType: "",
  //   placeholder: null,
  //   objectFields: [],
  //   noOfField: 0,
  //   placeholderBool: 0,
  // });

  useEffect(() => {
    if (updateProfileField?.type?.toLowerCase() === "array") {
      setArrayField({
        arrayType: updateProfileField?.arrayType
          ? updateProfileField?.arrayType
          : "",
        placeholder: updateProfileField?.placeholder
          ? updateProfileField?.placeholder
          : null,
        objectFields: updateProfileField?.objectFields
          ? updateProfileField?.objectFields
          : [],
        placeholderBool: updateProfileField?.placeholder ? 1 : 0,
        noOfField: updateProfileField?.objectFields
          ? updateProfileField?.objectFields?.length
          : 0,
        userAddable: updateProfileField?.userAddable,
        value: updateProfileField?.value,
      });
    }
  }, [updateProfileField]);
  // useEffect(() => {
  //   if (updateProfileField?.type?.toLowerCase() === "array") {
  //     setUpdateProfileField({
  //       ...updateProfileField,
  //       arrayType: arrayField?.arrayType ? arrayField?.arrayType : "",
  //       placeholder: arrayField?.placeholder ? arrayField?.placeholder : null,
  //       objectFields: arrayField?.objectFields ? arrayField?.objectFields : [],
  //     });
  //   }
  // }, []);

  return (
    <div>
      <Input
        type="text"
        className={`profile-inputField mt-2 px-2`}
        name="fieldName"
        label="Field Name"
        value={updateProfileField?.title}
        divStyle={{ fontSize: "18px" }}
        onChange={(e) => {
          setUpdateProfileField({
            ...updateProfileField,
            title: e.target.value,
          });
        }}
        divClass="poppins createField"
      />

      <div className="profile-field-type">
        <Label title="Field Type" className="mb-2 poppins" />
        <Dropdown
          value={
            updateProfileField?.field?.toLowerCase() === "select" ||
            updateProfileField?.field?.toLowerCase() === "multi-select"
              ? updateProfileField?.field?.toLowerCase()
              : updateProfileField?.type?.toLowerCase()
          }
          onChange={(e) => {
            setUpdateProfileField({
              ...updateProfileField,
              type: e.value,
            });
          }}
          options={optionsProfileFields}
          optionLabel="name"
          placeholder="Select a type"
          className="w-full text-left"
          style={{ textTransform: "capitalize" }}
        />
      </div>

      {updateProfileField?.options &&
        updateProfileField?.options?.length > 0 &&
        (updateProfileField?.type.toLowerCase() === "radio" ||
          updateProfileField?.type.toLowerCase() === "select" ||
          updateProfileField?.type.toLowerCase() === "multi-select" ||
          updateProfileField?.field?.toLowerCase() === "select") && (
          <div className="mt-3">
            <Label title="Options" className="poppins mb-0" />
            <div className="d-flex flex-wrap gap-3 my-3 mt-2">
              {updateProfileField?.options &&
                updateProfileField?.options?.length > 0 &&
                updateProfileField?.options?.map((item) => {
                  return (
                    <div className="poppins" key={item?.id}>
                      <p class="badge profile-option-label">
                        {item?.id !== isShowInputField?.id && item?.value}
                        {isShowInputField.isShow === true &&
                          item?.id === isShowInputField?.id && (
                            <input
                              type="text"
                              name="value"
                              value={item?.value}
                              className="profile-field-input"
                              onChange={(e) => {
                                const optionIndex =
                                  updateProfileField?.options?.findIndex(
                                    (a) => a.id === item.id
                                  );
                                updateProfileField.options[optionIndex].value =
                                  e.target.value;
                                setUpdateProfileField({
                                  ...updateProfileField,
                                });
                                // setOldOptions([...options]);
                                setError({ message: "" });
                              }}
                            />
                          )}
                        {isShowInputField.isShow === true &&
                          item?.id === isShowInputField?.id && (
                            <>
                              <FeatherIcon
                                className={`profile-field-EditIcon`}
                                icon={`check`}
                                size={17}
                                // onClick={() => setIsShowInputField(true)}
                                onClick={() => {
                                  if (item?.value) {
                                    setIsShowInputField(true);
                                  } else {
                                    setError({
                                      id: item.id,
                                      message: "Please enter option value",
                                    });
                                  }
                                }}
                              />
                              <FeatherIcon
                                className={`profile-field-remove`}
                                icon={`x`}
                                size={17}
                                onClick={(e) => {
                                  const existOption = options?.find(
                                    (a) => a.id === item.id
                                  );
                                  const optionIndex =
                                    updateProfileField?.options?.findIndex(
                                      (a) => a.id === item.id
                                    );
                                  updateProfileField.options[
                                    optionIndex
                                  ].value = existOption.value;
                                  setUpdateProfileField({
                                    ...updateProfileField,
                                  });
                                  setError({ message: "" });
                                  setIsShowInputField({
                                    id: "",
                                    isShow: false,
                                  });
                                }}
                              />
                            </>
                          )}

                        {item?.id !== isShowInputField?.id && (
                          <>
                            <FeatherIcon
                              className={`profile-field-EditIcon`}
                              icon={`edit-3`}
                              size={17}
                              onClick={() =>
                                setIsShowInputField({
                                  id: item?.id,
                                  isShow: true,
                                })
                              }
                              //   onClick={() => handleEdit(item.id)}
                            />
                            <FeatherIcon
                              className={`profile-field-remove`}
                              icon={`trash-2`}
                              size={17}
                              onClick={() => {
                                updateProfileField.options =
                                  updateProfileField?.options?.filter(
                                    (a) => a.id !== item?.id
                                  );
                                setUpdateProfileField({
                                  ...updateProfileField,
                                });
                              }}
                            />
                          </>
                        )}
                      </p>
                      {/* {error.id === item.id && error.message && (
                    <p
                      id="login_validation"
                      style={{ fontSize: "small", fontWeight: "600" }}
                      className="text-danger"
                    >
                      {error.message}
                    </p>
                  )} */}
                    </div>
                  );
                })}
            </div>

            {isShowAddMoreInput && (
              <div>
                <Label title="Option Title" className="mb-2 poppins" />
                <div className="d-flex mb-3 gap-1 w-100">
                  <Input
                    type="text"
                    id="numberOfOptionInputField"
                    className={`profileField-inputField px-2`}
                    name="numberOfOption"
                    divStyle={{ fontSize: "18px" }}
                    divClass="poppins w-100"
                    value={newOptionField}
                    onChange={(e) => {
                      setNewOptionField(e.target.value);
                    }}
                  />
                  <div className="d-flex gap-1">
                    <button
                      type="button"
                      // className="btn user-profile-saveBtn"
                      id="numberOfOptionGoBtn"
                      className={`btn greenBg`}
                      onClick={() => {
                        if (newOptionField !== "") {
                          const newOPtion = {
                            id: uuid(),
                            value: newOptionField,
                          };
                          updateProfileField.options.push(newOPtion);
                          setUpdateProfileField({
                            ...updateProfileField,
                          });
                          setIsShowAddMoreInput(false);
                          setNewOptionField("");
                          options.push(structuredClone(newOPtion));
                        } else {
                        }
                      }}
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      // className="btn user-profile-saveBtn"
                      id="numberOfOptionGoBtn"
                      className={`btn btn-outline-secondary`}
                      onClick={() => {
                        setIsShowAddMoreInput(false);
                        setNewOptionField("");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}

            <Button
              className="btn btn-outline-secondary btn-sm"
              label="+ Add More"
              handleClick={() => {
                setIsShowAddMoreInput(true);
              }}
            />
          </div>
        )}

      {updateProfileField?.type?.toLowerCase() === "array" && (
        <EditProfileFieldArray
          arrayField={arrayField}
          setArrayField={setArrayField}
          editProfileFieldArray={true}
          setUpdateProfileField={setUpdateProfileField}
          updateProfileField={updateProfileField}
        />
      )}

<div className="profile-field-type">
        <Label title="Catgory" className="mb-2 poppins" />
        <Dropdown
          value={
            updateProfileField?.category
          }
          onChange={(e) => {
            setUpdateProfileField({
              ...updateProfileField,
              category: e.value,
            });
          }}
          options={fieldCategories}
          optionLabel="name"
          placeholder="Select a category"
          className="w-full text-left"
          style={{ textTransform: "capitalize" }}
        />
      </div>


<div className="profile-field-type" style={{marginTop:'20px', display: "flex", alignItems: "center" }}>
  <Label title="Show In CV " className="mb-2 poppins" /> &nbsp;
  <input
                    type="checkbox"
                    checked={Boolean(updateProfileField?.showInCV) === true}
                    onChange={(e) => {
                      setUpdateProfileField({
                        ...updateProfileField,
                        showInCV: e.target.checked,
                      });
                    }}
                  />
 
</div>


    </div>
  );
}

export default EditProfileField;
