import Card from "components/Card";
import Navbar from "components/Navbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/Club/action";
import { actions as tutorActions } from "redux/Tutor/action";
// import AddClub from "components/AddClub";
// import AddTutor from "components/AddTutor";
import Search from "components/Search";
import FeatherIcon from "feather-icons-react";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
import { actions as courseActions } from "redux/Course/action";
import { INSTITUTE_ID_ACADEMIC } from "utils/constants";
import "./AdminDashboard.css";

function AdminDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { clubs: clubsData } = useSelector((state) => state.club);
  const [clubDataUpdate, setClubDataUpdate] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [clubs, setClubs] = useState([]);
  const { institutions, institutionTypes } = useSelector(
    (state) => state.course
  );
  const [institutionsOptions, setInstitutionsOptions] = useState([]);
  const [institutionTypeOptions, setInstitutionTypeOptions] = useState([]);

  const [instituteOption, setInstituteOption] = useState({
    name: "All",
    value: "all",
  });
  const [instituteTypeOption, setInstituteTypeOption] = useState({
    name: "All",
    value: "all",
  });
  const optionList = [
    {
      title: "Club info",
      // navigate: "/club/info",
      navigate: "/club/dashboard",
      icon: "eye",
    },
    {
      title: "Update",
      // navigate: "/",
      icon: "edit-3",
      attribute: { "data-bs-toggle": "modal", "data-bs-target": "#myModal" },
    },
    // {
    //   title: "Unarchive",
    //   icon: "archive",
    //   onClick: (id) => {
    //     dispatch(actions.updateClub({ isEnable: true, id: id }, false));
    //   },
    // },
    {
      title: "Archive",
      // navigate: "/",
      icon: "archive",
      onClick: (id) => {
        dispatch(actions.updateClub({ isEnable: false, id: id }, false));
      },
    },
  ];

  const handleSearch = (keyword) => {
    // eslint-disable-next-line
    const data = clubsData?.filter((club) => {
      if (
        (club?.name?.toLowerCase().includes(keyword?.toLowerCase()) &&
          instituteOption?.value === club?.instituteId) ||
        instituteOption?.value === "all"
      ) {
        if (
          instituteTypeOption?.value === club?.instituteTypeId ||
          instituteTypeOption?.value === "all"
        )
          return club?.name?.toLowerCase().includes(keyword?.toLowerCase());
      }
    });
    setClubs([...data]);
  };

  const handleFilterOrganisation = (instituteId) => {
    if (instituteId === "all") {
      setClubs([...clubsData]);
      return;
    }
    const data = clubsData?.filter((club) => {
      return club?.instituteId === instituteId;
    });
    setClubs([...data]);
  };

  const handleFilterOrganisationType = (instituteTypeId) => {
    if (instituteTypeId === "all") {
      const data = clubsData?.filter((club) => {
        return club?.instituteId === instituteOption?.value;
      });
      setClubs([...data]);
      return;
    }
    const data = clubsData?.filter((club) => {
      return club?.instituteTypeId === instituteTypeId;
    });
    setClubs([...data]);
  };

  const setInstitutionsOptionsFunction = (instituteId) => {
    if (instituteId === "all") {
      const data = clubsData?.filter((club) => {
        return club?.instituteId === instituteOption?.value;
      });
      setClubs([...data]);
      return;
    }

    const institute =
      institutions && institutions.find((item) => item.id === instituteId);

    const options =
      institute.instituteTypes &&
      institute.instituteTypes.map((data) => {
        return { name: data?.name?.toLowerCase(), value: data.id };
      });
    setInstitutionTypeOptions([{ name: "All", value: "all" }, ...options]);
    setInstituteTypeOption({
      name: "All",
      value: "all",
    });
  };

  useEffect(() => {
    // if (user) {
    // user?.club?.id && dispatch(playersAction.getPlayers(user));
    dispatch(tutorActions.getClubAdmins());
    dispatch(actions.getClubs());
    // }
  }, [dispatch]);

  useEffect(() => {
    // const data = clubsData?.filter((club) => {
    //   return club?.instituteId === INSTITUTE_ID_ACADEMIC;
    // });
    // setClubs([...data]);
    setClubs(clubsData);
  }, [clubsData]);

  useEffect(() => {
    dispatch(courseActions.getInstitutions());
    dispatch(
      courseActions.getInstitutionTypes({
        instituteId: INSTITUTE_ID_ACADEMIC,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const options = institutions.map((data) => {
      return { name: data?.name?.toLowerCase(), value: data.id };
    });
    setInstitutionsOptions([{ name: "All", value: "all" }, ...options]);
    institutions.length > 0 &&
      setInstitutionsOptionsFunction(INSTITUTE_ID_ACADEMIC);
  }, [institutions]);

  // useEffect(() => {
  //   const options = institutionTypes.map((data) => {
  //     return { name: data?.name?.toLowerCase(), value: data.id };
  //   });
  //   setInstitutionTypeOptions([{ name: "All", value: "all" }, ...options]);
  // }, [institutionTypes]);

  useEffect(() => {
    // handleFilterOrganisation(clubDataUpdate?.instituteId);
    if (clubDataUpdate && instituteTypeOption.value !== "all") {
      if (
        clubs?.length > 0 &&
        (clubDataUpdate?.instituteTypeId !== clubs[0]?.instituteTypeId ||
          clubDataUpdate?.instituteId !== clubs[0]?.instituteId)
      ) {
        const data = clubs?.filter((club) => {
          return club?.id !== clubDataUpdate?.id;
        });
        setClubs([...data]);
      }
      // else if (
      //   clubs?.length > 0 &&
      //   clubDataUpdate?.instituteId === clubs[0]?.instituteId
      // ) {
      //   return;
      // }

      // const data = clubs?.filter((club) => {
      //   return club?.id !== clubDataUpdate?.id;
      // });
      // setClubs([...data]);
    }

    // handleFilterOrganisationType(clubDataUpdate?.instituteTypeId);
    // eslint-disable-next-line
  }, [clubDataUpdate]);

  return (
    <>
      <Navbar />
      {
        <div className="container-fluid text-end">
          <div className="d-flex align-items-center justify-content-between mt-3 adminDashboardHeader">
            <div className="adminDashboardDropdown">
              <Dropdown
                value={instituteOption?.value}
                onChange={(e) => {
                  setInstituteOption(e);
                  // dispatch(
                  //   courseActions.getInstitutionTypes({
                  //     instituteId: e.value,
                  //   })
                  // );
                  setInstitutionsOptionsFunction(e.value);
                  handleFilterOrganisation(e.value);
                  setKeyword("");
                }}
                options={institutionsOptions}
                optionLabel="name"
                placeholder="Select a organisation"
                className="w-full md:w-14rem text-left me-2"
                style={{ textTransform: "capitalize" }}
              />
              <Dropdown
                value={instituteTypeOption?.value}
                onChange={(e) => {
                  setInstituteTypeOption(e);
                  handleFilterOrganisationType(e.value);
                  setKeyword("");
                }}
                options={institutionTypeOptions}
                optionLabel="name"
                placeholder="Select a organisation type"
                className="w-full md:w-14rem text-left me-2"
              />
            </div>
            <div className="d-flex align-items-center justify-content-end adminDashboardHeader">
              {/* {instituteOption?.value && (
              <Dropdown
                value={instituteOption?.value}
                onChange={(e) => {
                  setInstituteOption(e);
                  handleFilterOrganisation(e.value);
                }}
                options={institutionsOptions}
                optionLabel="name"
                placeholder="Select a organisation"
                className="w-full md:w-14rem text-left me-2"
              />
            )} */}
              <div className="adminDashboardSearch">
                <Search
                  keyword={keyword}
                  setKeyword={setKeyword}
                  handleSearch={handleSearch}
                />
              </div>

              <div className="text-end d-inline-block ms-2">
                <div
                  className="d-flex justify-content-end btn btn-outline-success rounded-0 border-green archivedButton text-green position-relative"
                  onClick={() => navigate("/clubs/archived")}
                >
                  <span>
                    <FeatherIcon icon="archive" size={16} />
                  </span>
                  <p className="ps-2">Archived</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      <div className="container-fluid py-4">
        <div
          className="d-flex h-100 flex-wrap justify-content-center adminDashboardCard"
          id="card"
        >
          <Card cardType="addItemCard" label="Add New Organisation" />

          {clubs &&
            clubs?.length > 0 &&
            clubs.map((club) => {
              return (
                <div>
                  <Card
                    image={club?.image}
                    name={club?.name}
                    menuIcon={true}
                    optionList={optionList}
                    cardType="clubCard"
                    key={club?.id}
                    club={club}
                    setClubDataUpdate={setClubDataUpdate}
                    clubDataUpdate={clubDataUpdate}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default AdminDashboard;
