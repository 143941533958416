import React, { useEffect, useState } from "react";

const BoxItem = (props) => {
  const {
    title = "",
    id = "",
    value = "",
    handleCallBack,
    contentEditable,
    setOpenCollapseId,
    addOpenClass = false,
  } = props;

  const [selectedVal, setSelectedVal] = useState(value);
  const [open, setOpen] = useState(addOpenClass);
  const handleSelectedVal = (val) => {
    setSelectedVal(val);
    handleCallBack(id, val);
  };

  useEffect(() => {
    if (value !== selectedVal) {
      setSelectedVal(value);
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <>
      <div className="box">
        <div className="boxTitle text-nowrap">{title}</div>
        <div
          className="select-wrapper"
          onClick={() => {
            contentEditable && setOpenCollapseId(id);
            setOpen(!open);
          }}
        >
          <div
            id={`${id}Select`}
            className={`select ${
              contentEditable && open && addOpenClass ? "open" : ""
            }`}
          >
            <div
              className={`select__trigger ${
                selectedVal === 1
                  ? "green"
                  : selectedVal === 2
                  ? "orange"
                  : selectedVal === 3
                  ? "red"
                  : ""
              }`}
              id={`${id}SelectVal`}
            >
              <span>
                {selectedVal === 1
                  ? "Skills"
                  : selectedVal === 2
                  ? "Develop"
                  : selectedVal === 3
                  ? "Focus"
                  : "Select.."}
              </span>
              <div className="arrow"></div>
            </div>
            <div className="custom-options" id={id}>
              <span
                className="custom-option green selected"
                data-value="green"
                onClick={() => handleSelectedVal(1)}
              >
                Skills
              </span>
              <span
                className="custom-option orange"
                data-value="orange"
                onClick={() => handleSelectedVal(2)}
              >
                Develop
              </span>
              <span
                className="custom-option red"
                data-value="red"
                onClick={() => handleSelectedVal(3)}
              >
                Focus
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoxItem;
