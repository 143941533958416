import CourseInfo from "components/CourseInfo";
import InstitutionType from "components/InstitutionType";
import MainControlButton from "components/MainControlButton";
// import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React, { useEffect, useRef, useState } from "react";
import { uuid } from "utils/uuid";
import "../../components/CreateCourse/CreateCourse.css";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/Course/action";
import Navbar from "components/Navbar";
// import { Icons } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import CreateCourseSteps from "components/CreateCourseSteps";

const Course = ({ playerCentre, player, setPlayerCentre }) => {
  // const [dropdownValue, setDropdownValue] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [courseInfo, setCourseInfo] = useState(null);
  const [isShowInputBox, setIsShowInputBox] = useState(true);
  const [newTypes, setNewTypes] = useState([
    { id: uuid(), Types: "Cricket" },
    // { id: uuid(), Types: "FootBoll" },
  ]);
  const { networkProgressDialog } = useSelector((state) => state.loader);

  const { course } = useSelector((state) => state.course);

  const CreateIntuitionType = (data) => {
    newTypes.find((item) => item.id);
    setNewTypes([...newTypes, data]);
  };
  const [selectedOption, setSelectedOption] = useState({
    name: location?.state?.step_0,
    id: location?.state?.instituteId,
  });
  const [selectedOptionInstitutionType, setSelectedOptionInstitutionType] =
    useState({
      name: location?.state?.step,
      id: location?.state?.instituteTypeId,
    });
  const [selectedOptionQualificationType, setSelectedOptionQualificationType] =
    useState({
      name: location?.state?.step1,
      id: location?.state?.qualificationId,
    });
  const [selectedOptionGrading, setSelectedOptionGrading] = useState(null);
  const [dropdownValue, setDropdownValue] = useState(false);
  const [dropdownValueInstitutionType, setDropdownValueInstitutionType] =
    useState(false);

  const [dropdownValueQualificationType, setDropdownValueQualificationType] =
    useState(false);
  const [dropdownValueGrading, setDropdownValueGrading] = useState(false);

  const dropdownRef = useRef();
  // const [editDisabled, setEditDisabled] = useState(false);

  useEffect(() => {
    dispatch(actions.getInstitutions());
    dispatch(
      actions.getGrades({
        instituteId: location?.state?.instituteId,
        qualificationId: location?.state?.qualificationId,
      })
    );
    dispatch(actions.clearCourse());
  }, [
    dispatch,
    location?.state?.instituteId,
    location?.state?.qualificationId,
  ]);

  //   useEffect(() => {
  //     let hander = (e) => {
  //       if (selectedOption && dropdownRef.current?.contains(e.target)) {
  //         setSelectedOption({ ...selectedOption, disabled: true });
  //         setDropdownValue(false);
  //       }
  //     };
  //     return document.addEventListener("mousedown", hander);
  //   }, [selectedOption, dropdownValue]);

  //   useEffect(() => {
  //     let hander = (e) => {
  //       if (
  //         selectedOptionInstitutionType &&
  //         dropdownRef.current?.contains(e.target)
  //       ) {
  //         setSelectedOptionInstitutionType({
  //           ...selectedOptionInstitutionType,
  //           disabled: true,
  //         });
  //         setDropdownValueInstitutionType(false);
  //       }
  //     };
  //     return document.addEventListener("mousedown", hander);
  //   }, [selectedOptionInstitutionType, dropdownValue]);

  //   useEffect(() => {
  //     let hander = (e) => {
  //       if (
  //         selectedOptionQualificationType &&
  //         dropdownRef.current?.contains(e.target)
  //       ) {
  //         setSelectedOptionQualificationType({
  //           ...selectedOptionQualificationType,
  //           disabled: true,
  //         });
  //         setDropdownValueQualificationType(false);
  //       }
  //     };
  //     return document.addEventListener("mousedown", hander);
  //   }, [selectedOptionQualificationType]);

  //   useEffect(() => {
  //     let hander = (e) => {
  //       if (selectedOptionGrading && dropdownRef.current?.contains(e.target)) {
  //         setSelectedOptionGrading({
  //           ...selectedOptionGrading,
  //           disabled: true,
  //         });
  //         setDropdownValueGrading(false);
  //       }
  //     };
  //     return document.addEventListener("mousedown", hander);
  //   }, [selectedOptionGrading]);

  return (
    <>
      <Navbar />

      <div>
        <div className="container-fluid mt-3">
          <div
            className="d-flex align-items-center text-secondary fw-bold"
            onClick={() => {
              navigate(-1);
            }}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="feather feather-arrow-left cursor-pointer">
              <g>
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </g>
            </svg>
            <span className="ps-1 cursor-pointer">Back</span>
          </div>
        </div>
        {/* <Icons name="leftArrow" />
        <span className="ms-2">Back</span> */}

        {/* <button>Back</button> */}

        {playerCentre !== "superCourseCenter" && (
          <MainControlButton
            className="MainControlButton"
            playerCentre={playerCentre}
            player={player}
            setPlayerCentre={setPlayerCentre}
          />
        )}
        <div className="ml-3 mr-3">
          {selectedOption && (
            <CreateCourseSteps
              selectedOption={selectedOption}
              selectedOptionInstitutionType={selectedOptionInstitutionType}
              selectedOptionQualificationType={selectedOptionQualificationType}
              courseInfo={courseInfo}
            />
          )}
        </div>
        <div className="container-fluid">
          <div className="row CreateCourseRow mt-5 px-3">
            <div className="col-md-auto createCourse">
              <p className="CourseText">CREATE NEW COURSE</p>
              {newTypes.map((data, i) => (
                <InstitutionType
                  key={i}
                  CreateIntuitionType={CreateIntuitionType}
                  setCourseInfo={setCourseInfo}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  selectedOptionInstitutionType={selectedOptionInstitutionType}
                  setSelectedOptionInstitutionType={
                    setSelectedOptionInstitutionType
                  }
                  selectedOptionQualificationType={
                    selectedOptionQualificationType
                  }
                  setSelectedOptionQualificationType={
                    setSelectedOptionQualificationType
                  }
                  selectedOptionGrading={selectedOptionGrading}
                  setSelectedOptionGrading={setSelectedOptionGrading}
                  dropdownValue={dropdownValue}
                  setDropdownValue={setDropdownValue}
                  dropdownValueInstitutionType={dropdownValueInstitutionType}
                  setDropdownValueInstitutionType={
                    setDropdownValueInstitutionType
                  }
                  dropdownValueQualificationType={
                    dropdownValueQualificationType
                  }
                  setDropdownValueQualificationType={
                    setDropdownValueQualificationType
                  }
                  dropdownValueGrading={dropdownValueGrading}
                  setDropdownValueGrading={setDropdownValueGrading}
                  superAddCourse={"superAddCourse"}
                />
              ))}
            </div>
            <div
              className="CourseInfo col col-md-2 col-lg-4 mt-8"
              ref={dropdownRef}>
              {course && courseInfo && (
                <CourseInfo
                  // courseInfo={courseInfo}
                  courseInfo={course}
                  // courseInfoCopy={courseInfoCopy}
                  editExistingCourse={true}
                  createCourse={true}
                  isShowInputBox={isShowInputBox}
                  // setDuplicateShow={setDuplicateShow}
                  duplicateAndEdit={false}
                  // duplicateShow={duplicateShow}
                  // setLoaderSave={setLoaderSave}
                  // setIsCancel={setIsCancel}
                  setIsShowInputBox={setIsShowInputBox}
                  selectedOptionGradingEditable={{ id: course?.gradeId }}
                  selectedOptionQualificationTypeEditable={{
                    id: course?.qualificationId,
                  }}
                  selectedOptionInstitutionTypeEditable={{
                    id: course?.instituteTypeId,
                  }}
                  selectedOptionEditable={{ id: course?.instituteId }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {networkProgressDialog && courseInfo && (
        <div id="cover-spin" className="LoaderSpin">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </>
  );
};

export default Course;
