import CardDropdown from "components/CardDropdown";
import "./UserProfileCard.css";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const UserProfileCard = ({ profileField, optionList, displayIconOfAdded }) => {
  return (
    <div>
      <div className="px-lg-4 px-md-3 px-xs-2 pb-5 user-profile-card-div">
        <div className={`card border-0`}>
          <div className="card-header bg-white border-0 pb-0 profile-card-header">
            <div className="d-flex align-items-center">
              <CardDropdown
                optionList={optionList}
                data={profileField}
                displayIconOfAdded={displayIconOfAdded}
              />
            </div>
          </div>
          <div className="card-body poppins pt-5">
            <div>
              <h6 className="fw-bold">{profileField?.title?.toUpperCase()}</h6>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span className="text-secondary">
                {profileField?.type?.toUpperCase()}
              </span>
              {displayIconOfAdded && (
                <div className="d-flex align-items-center badge bg-success">
                  <FeatherIcon
                    className={`${"mr-1"}`}
                    icon={`${"check-circle"}`}
                    size={14}
                    strokeWidth={2}
                  />
                  <p>Added</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileCard;
