import { all, fork, put, takeLatest } from "redux-saga/effects";
import { actions as loaderAction } from "redux/Loader/action";
import { deleteQuery, getQuery, postQuery, putQuery } from "utils/api";
import { Toast } from "utils/toast";
import { actions } from "./action";

export function* getProfilesFields() {
  yield takeLatest(actions.GET_PROFILE_FIELDS_REQUEST, function* () {
    try {
      const response = yield getQuery(`common-profile`);

      if (!response.data.error) {
        yield put({
          type: actions.GET_PROFILE_FIELDS_SUCCESS,
          payload: response.data.result,
        });
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    } catch (e) {
      console.log(e);
      yield put({
        type: actions.CATCH_ERROR,
        payload: true,
      });
      Toast("error", e.response.data.message);
      yield put({
        type: loaderAction.CLOSE_LOADER,
      });
    }
  });
}

export function* createProfileField() {
  yield takeLatest(
    actions.CREATE_PROFILE_FIELD_REQUEST,
    function* ({ data, navigate }) {
      try {
        const response = yield putQuery(`common-profile`, data);
        if (!response.data.error) {
          yield put({
            type: actions.CREATE_PROFILE_FIELD_SUCCESS,
            payload: response.data.result,
          });
          Toast("success", "Profile Field Added Successfully!");
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
          navigate && navigate(false);
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    }
  );
}

export function* updateProfileField() {
  yield takeLatest(
    actions.UPDATE_PROFILE_FIELD_REQUEST,
    function* ({ data, navigate }) {
      try {
        const response = yield putQuery(`common-profile`, data);
        if (!response.data.error) {
          yield put({
            type: actions.UPDATE_PROFILE_FIELD_SUCCESS,
            payload: response.data.result,
          });
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
          Toast("success", "Profile Field Updated Successfully!");
          // navigate && navigate(true);
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    }
  );
}

export function* deleteProfileField() {
  yield takeLatest(
    actions.DELETE_PROFILE_FIELD_REQUEST,
    function* ({ data, navigate }) {
      try {
        const response = yield deleteQuery(
          `common-profile?id=${data.id}&profileFieldId=${data.profileFieldId}`
        );

        if (!response.data.error) {
          yield put({
            type: actions.DELETE_PROFILE_FIELD_SUCCESS,
            payload: data,
          });
          // Toast("success", "Profile Field Removed Successfully!");
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
      }
    }
  );
}

export function* getDynamicProfilesFields() {
  yield takeLatest(
    actions.GET_DYNAMIC_PROFILE_FIELDS_REQUEST,
    function* ({ data }) {
      try {
        let endpoint = "dynamic-profile";
        if (data?.instituteId && data?.instituteTypeId) {
          endpoint = `dynamic-profile?instituteId=${data?.instituteId}&instituteTypeId=${data?.instituteTypeId}`;
        }
        const response = yield getQuery(endpoint);

        if (!response.data.error) {
          yield put({
            type: actions.GET_DYNAMIC_PROFILE_FIELDS_SUCCESS,
            payload: response.data.result,
          });
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    }
  );
}

export function* createNewDynamicProfile() {
  yield takeLatest(
    actions.CREATE_NEW_DYNAMIC_PROFILE_REQUEST,
    function* ({ data, navigate }) {
      try {
        const response = yield postQuery(`dynamic-profile`, data);
        if (!response.data.error) {
          yield put({
            type: actions.CREATE_NEW_DYNAMIC_PROFILE_SUCCESS,
            payload: response.data.result,
          });
          Toast("success", "Profile Field Added Successfully!");
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
          navigate && navigate(false);
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    }
  );
}

export function* createDynamicProfileField() {
  yield takeLatest(
    actions.CREATE_DYNAMIC_PROFILE_FIELD_REQUEST,
    function* ({ data, navigate }) {
      try {
        const response = yield putQuery(`dynamic-profile`, data);
        if (!response.data.error) {
          yield put({
            type: actions.CREATE_DYNAMIC_PROFILE_FIELD_SUCCESS,
            payload: response.data.result,
          });
          Toast("success", "Profile Field Added Successfully!");
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
          navigate && navigate(false);
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    }
  );
}

export function* updateDynamicProfileField() {
  yield takeLatest(
    actions.UPDATE_DYNAMIC_PROFILE_FIELD_REQUEST,
    function* ({ data, navigate }) {
      try {
        const response = yield putQuery(`dynamic-profile`, data);
        if (!response.data.error) {
          yield put({
            type: actions.UPDATE_DYNAMIC_PROFILE_FIELD_SUCCESS,
            payload: response.data.result,
          });
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
          Toast("success", "Profile Field Updated Successfully!");
          // navigate && navigate(true);
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    }
  );
}

export function* deleteDynamicProfileField() {
  yield takeLatest(
    actions.DELETE_DYNAMIC_PROFILE_FIELD_REQUEST,
    function* ({ data, navigate }) {
      try {
        const response = yield deleteQuery(
          `dynamic-profile?id=${data.id}&profileFieldId=${data.profileFieldId}`
        );

        if (!response.data.error) {
          yield put({
            type: actions.DELETE_DYNAMIC_PROFILE_FIELD_SUCCESS,
            payload: data,
          });
          // Toast("success", "Profile Field Removed Successfully!");
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
      }
    }
  );
}

export function* getOrganizationProfilesFields() {
  yield takeLatest(
    actions.GET_ORGANIZATION_PROFILE_FIELDS_REQUEST,
    function* ({ data }) {
      try {
        let endpoint = "dynamic-profile";
        if (
          data?.instituteId &&
          data?.instituteTypeId &&
          data?.organizationId
        ) {
          endpoint = `dynamic-profile?instituteId=${data?.instituteId}&instituteTypeId=${data?.instituteTypeId}&organizationId=${data?.organizationId}`;
        }
        const response = yield getQuery(endpoint);

        if (!response.data.error) {
          yield put({
            type: actions.GET_ORGANIZATION_PROFILE_FIELDS_SUCCESS,
            payload: response.data.result,
          });
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    }
  );
}

export function* createNewOrganizationProfile() {
  yield takeLatest(
    actions.CREATE_NEW_ORGANIZATION_PROFILE_REQUEST,
    function* ({ data, navigate }) {
      try {
        const response = yield postQuery(`dynamic-profile`, data);
        if (!response.data.error) {
          yield put({
            type: actions.CREATE_NEW_ORGANIZATION_PROFILE_SUCCESS,
            payload: response.data.result,
          });

          let payload;
          if (data?.multiple === true) {
            // If profileFields is an array, set the payload as the entire profileFields array
            payload = response.data.result.profileFields;
          } else {
            // If profileFields is not an array, set the payload as the last item in the profileFields array
            const lastItemIndex = response.data.result.profileFields.length - 1;
            payload = response.data.result.profileFields[lastItemIndex];
          }

          yield put({
            type: actions.ADD_PRIORITY_PROFILE_FIELDS_SUCCESS,
            payload,
          });

          // yield put({
          //   type: actions.ADD_PRIORITY_PROFILE_FIELDS_SUCCESS,
          //   payload:
          //     response.data.result.profileFields[
          //       response.data.result.profileFields.length - 1
          //     ],
          // });
          Toast("success", "Profile Field Added Successfully!");
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
          navigate && navigate(false);
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    }
  );
}

export function* createOrganizationProfileField() {
  yield takeLatest(
    actions.CREATE_ORGANIZATION_PROFILE_FIELD_REQUEST,
    function* ({ data, navigate }) {
      try {
        const response = yield putQuery(`dynamic-profile`, data);

        if (!response.data.error) {
          yield put({
            type: actions.CREATE_ORGANIZATION_PROFILE_FIELD_SUCCESS,
            payload: response.data.result,
          });

          let payload;
          if (data?.multiple === true) {
            // If profileFields is an array, set the payload as the entire profileFields array
            payload = response.data.result.profileFields;
          } else {
            // If profileFields is not an array, set the payload as the last item in the profileFields array
            const lastItemIndex = response.data.result.profileFields.length - 1;
            payload = response.data.result.profileFields[lastItemIndex];
          }

          yield put({
            type: actions.ADD_PRIORITY_PROFILE_FIELDS_SUCCESS,
            payload,
          });

          // yield put({
          //   type: actions.ADD_PRIORITY_PROFILE_FIELDS_SUCCESS,
          //   payload:
          //     response.data.result.profileFields[
          //       response.data.result.profileFields.length - 1
          //     ],
          // });
          Toast("success", "Profile Field Added Successfully!");
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
          navigate && navigate(false);
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    }
  );
}

export function* updateOrganizationProfileField() {
  yield takeLatest(
    actions.UPDATE_ORGANIZATION_PROFILE_FIELD_REQUEST,
    function* ({ data, navigate }) {
      try {
        const response = yield putQuery(`dynamic-profile`, data);
        if (!response.data.error) {
          yield put({
            type: actions.UPDATE_ORGANIZATION_PROFILE_FIELD_SUCCESS,
            payload: response.data.result,
          });
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
          Toast("success", "Profile Field Updated Successfully!");
          // navigate && navigate(true);
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    }
  );
}

export function* deleteOrganizationProfileField() {
  yield takeLatest(
    actions.DELETE_ORGANIZATION_PROFILE_FIELD_REQUEST,
    function* ({ data, navigate }) {
      try {
        const response = yield deleteQuery(
          `dynamic-profile?id=${data.id}&profileFieldId=${data.profileFieldId}`
        );

        if (!response.data.error) {
          yield put({
            type: actions.DELETE_ORGANIZATION_PROFILE_FIELD_SUCCESS,
            payload: data,
          });
          yield put({
            type: actions.REMOVE_PRIORITY_PROFILE_FIELDS_SUCCESS,
            payload: data,
          });
          // Toast("success", "Profile Field Removed Successfully!");
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
      }
    }
  );
}

export function* getPriorityProfilesFields() {
  yield takeLatest(
    actions.GET_PRIORITY_PROFILE_FIELDS_REQUEST,
    function* ({ data }) {
      try {
        let endpoint = "priority-profile";
        if (data?.organizationId) {
          endpoint = `priority-profile?organisationId=${data?.organizationId}`;
        }
        const response = yield getQuery(endpoint);

        if (!response.data.error) {
          yield put({
            type: actions.GET_PRIORITY_PROFILE_FIELDS_SUCCESS,
            payload: response.data.result,
          });
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    }
  );
}

export function* updatePriorityProfilesFields() {
  yield takeLatest(
    actions.UPDATE_PRIORITY_PROFILE_FIELDS_REQUEST,
    function* ({ data, navigate }) {
      try {
        const response = yield putQuery(`priority-profile`, data);
        if (!response.data.error) {
          yield put({
            type: actions.UPDATE_PRIORITY_PROFILE_FIELDS_SUCCESS,
            payload: response.data.result,
          });
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
          Toast("success", "Profile Field Updated Successfully!");
          // navigate && navigate(true);
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    }
  );
}

export default function* profileSaga() {
  yield all([
    fork(getProfilesFields),
    fork(createProfileField),
    fork(updateProfileField),
    fork(deleteProfileField),
    fork(getDynamicProfilesFields),
    fork(createNewDynamicProfile),
    fork(createDynamicProfileField),
    fork(updateDynamicProfileField),
    fork(deleteDynamicProfileField),
    fork(getOrganizationProfilesFields),
    fork(createNewOrganizationProfile),
    fork(createOrganizationProfileField),
    fork(updateOrganizationProfileField),
    fork(deleteOrganizationProfileField),
    fork(getPriorityProfilesFields),
    fork(updatePriorityProfilesFields),
  ]);
}
