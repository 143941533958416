import React from "react";
import "./Button.css";

const Button = ({
  label = "",
  handleClick,
  className = "",
  id = "",
  attribute,
  type,
  disabled,
  style,
}) => (
  <button
    type={type}
    className={className}
    id={id}
    onClick={handleClick}
    disabled={disabled}
    style={style}
    {...attribute}
  >
    {label}
  </button>
);

export default Button;
