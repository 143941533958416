export const actions = {
  VIEW_CV_REQUEST: "VIEW_CV_REQUEST",
  VIEW_CV_SUCCESS: "VIEW_CV_SUCCESS",

  VIEW_ALL_CV_REQUEST: "VIEW_ALL_CV_REQUEST",
  VIEW_ALL_CV_SUCCESS: "VIEW_ALL_CV_SUCCESS",

  viewCV: (data) => {
    return {
      type: actions.VIEW_CV_REQUEST,
      data,
    };
  },
  viewAllCV: (data) => {
    return {
      type: actions.VIEW_ALL_CV_REQUEST,
      data,
    };
  },
};
