import React from "react";
import "./CreateCourseSteps.css";

const CreateCourseSteps = ({
  selectedOption,
  selectedOptionInstitutionType,
  selectedOptionQualificationType,
  courseInfo,
}) => {
  return (
    <>
      <div className="">
        <div className="mt-5">
          <div
            className="alert alert-success poppins text-uppercase fs-5 p-2"
            role="alert"
          >
            {`${selectedOption?.name?.replace(/\b[a-z]/g, (x) =>
              x.toUpperCase()
            )} ${
              selectedOptionInstitutionType?.name
                ? " > " +
                  selectedOptionInstitutionType?.name
                    ?.toLocaleLowerCase()
                    .replace(/\b[a-z]/g, (x) => x.toUpperCase())
                : ""
            } ${
              selectedOptionQualificationType?.name
                ? " > " +
                  selectedOptionQualificationType?.name
                    ?.toLocaleLowerCase()
                    .replace(/\b[a-z]/g, (x) => x.toUpperCase())
                : ""
            } ${
              courseInfo?.title
                ? " > " +
                  courseInfo?.title
                    ?.toLocaleLowerCase()
                    .replace(/\b[a-z]/g, (x) => x.toUpperCase())
                : ""
            }
            `}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCourseSteps;
