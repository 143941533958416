import React, { useState, useEffect, useContext } from "react";
import Image from "components/Image";
import "./Sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import { utils, writeFile } from "xlsx";
import default_club from "assets/images/default_club.png";
import { actions as playersAction } from "redux/Player/action";
import FeatherIcon from "feather-icons-react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { UserContext } from "components/contexts/User.context";
import { actions as cvActions } from "redux/CV/action";

const Sidebar = ({ setPlayerCentre, setPlayer, setFileName }) => {
  const dispatch = useDispatch();
  // const fileRef = useRef();
  const { players } = useSelector((state) => state.players);
  const { user } = useSelector((state) => state.user);
  const [dropdownValue, setDropdownValue] = useState(false);
  const [categoryDropdownValue, setCategoryDropdownValue] = useState(false);
  const [playerCategory, setPlayerCategory] = useState("");
  const params = useParams();
  let { clubs } = useSelector((state) => state.club);
  let clubData =
    params?.id &&
    clubs?.find((club) => {
      return params?.id === club?.id;
    });
  // const [uploadFileData, setUploadFileData] = useState(null);

  const { playerCategory: playerCategories } = useSelector(
    (state) => state.players
  );

  const filteredPlayers =
    playerCategory != ""
      ? players &&
        players?.length > 0 &&
        players?.filter((player) => {
          return player.playerCategory === playerCategory.id;
        })
      : players;

  const { user: userType } = useContext(UserContext);

  const handleTab = (data) => {
    setPlayerCentre(data);
  };

  function preferredOrder(obj, order) {
    var newObject = {};
    for (var i = 0; i < order.length; i++) {
      if (obj.hasOwnProperty(order[i])) {
        newObject[order[i]] = obj[order[i]];
      }
    }
    return newObject;
  }

  const downloadFile = () => {
    const playersCopy = structuredClone(players);
    let playersData = playersCopy.map((data) => {
      delete data.transferableValues;
      delete data.boxToBox;
      delete data.id;
      delete data.profileImage;
      delete data.status;
      data.forename = data?.name.split(" ")[0];
      data.surname = data?.name.split(" ")[1];
      // data.date_of_birth = data.date_of_birth
      //   ? new Date(data.date_of_birth).getDate() +
      //     "." +
      //     (new Date(data.date_of_birth).getMonth() + 1) +
      //     "." +
      //     new Date(data.date_of_birth).getFullYear()
      //   : "-";
      data.date_of_birth = data.date_of_birth
        ? moment(data.date_of_birth, "DD-MM-YYYY").format("DD.MM.YYYY")
        : "-";

      let restructureData = preferredOrder(data, [
        "forename",
        "surname",
        "email",
        "date_of_birth",
        "FAno",
        "PFAno",
        "NIno",
      ]);
      return restructureData;
    });

    const Heading = [
      [
        "Forename",
        "Surname",
        "Email",
        "Date of Birth",
        "FA No.",
        "PFA No.",
        "N.I. No.",
      ],
    ];
    let filename = `${userType}.xlsx`;
    var ws = utils.json_to_sheet(playersData, {
      origin: "A2",
      skipHeader: true,
    });
    utils.sheet_add_aoa(ws, Heading, { origin: "A1" });
    var wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "People");

    writeFile(wb, filename, {
      type: "buffer",
      cellStyles: true,
    });
  };

  // const uploadFile = (e) => {
  //   setUploadFileData(e.target.value);
  //   var reader = new FileReader();
  //   reader.onload = function (e) {
  //     var workbook = read(reader.result, {
  //       type: "binary",
  //       cellDates: true,
  //     });
  //     var firstSheet = workbook.SheetNames[0];
  //     let arr = sheet2arr(workbook.Sheets[firstSheet]);
  //     let dataArr = [];
  //     for (let i = 1; i < arr.length; i++) {
  //       let obj = {
  //         name: arr[i][0],
  //         email: arr[i][1],
  //         date_of_birth: arr[i][2],
  //         FAno: arr[i][3],
  //         PFAno: arr[i][4],
  //         NIno: arr[i][5],
  //       };

  //       dataArr.push(obj);
  //     }

  //     let playersJSONData = utils.sheet_to_row_object_array(
  //       workbook.Sheets[firstSheet]
  //     );
  //     // eslint-disable-next-line
  //     playersJSONData = playersJSONData.map((data) => {
  //       data.club = user.club;
  //       return data;
  //     });

  //     dataArr = dataArr?.map((data) => {
  //       data.club = user.club;
  //       return data;
  //     });
  //     setPlayer(dataArr);
  //   };

  //   reader.readAsBinaryString(e.target.files[0]);
  //   setPlayerCentre("multiplePlayer");
  //   setFileName(e.target.files[0].name);

  //   var sheet2arr = function (sheet) {
  //     var result = [];
  //     var row;
  //     var rowNum;
  //     var colNum;
  //     var range = utils.decode_range(sheet["!ref"]);
  //     for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
  //       row = [];
  //       for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
  //         var nextCell = sheet[utils.encode_cell({ r: rowNum, c: colNum })];

  //         if (typeof nextCell == "undefined") {
  //           row.push(void 0);
  //         } else row.push(nextCell.w);
  //       }
  //       result.push(row);
  //     }
  //     return result;
  //   };
  // };

  useEffect(() => {
    if (user && !params?.id) {
      user?.club?.id && dispatch(playersAction.getPlayers(user));
      user?.club?.id && dispatch(playersAction.getClub());
    } else if (user && params?.id) {
      dispatch(playersAction.getPlayers({ club: { id: params?.id } }));
      dispatch(playersAction.getClub());
    }
    // eslint-disable-next-line
  }, [user, dispatch]);

  const viewAllCV = () => {
    dispatch(
      cvActions.viewAllCV(
        players?.map((player) => {
          return { id: player?.id };
        })
      )
    );
  };

  return (
    <>
      <div id="sideBar">
        <div className="flex">
          <div>
            <div className="imgSection">
              <Image
                id="clubImage"
                src={
                  user?.club?.image
                    ? user?.club?.image
                    : clubData && params?.id
                    ? clubData?.image
                    : default_club
                }
                alt={
                  user?.club?.name
                    ? user?.club?.name
                    : clubData && params?.id
                    ? clubData?.name
                    : ""
                }
                className="clubImage"
                title={
                  user?.club?.name
                    ? user?.club?.name
                    : clubData && params?.id
                    ? clubData?.name
                    : ""
                }
              />
            </div>

            <div
              id="newPlayer"
              className="boxHeader click"
              onClick={() => handleTab("newPlayer")}
            >
              <p>New {userType}</p>
              <p className="plusIcon">
                <FeatherIcon icon="user-plus" size={20} />
              </p>
            </div>

            <div
              id="multiplePlayer"
              className="boxHeader click"
              onClick={() => setPlayerCentre("multiplePlayer")}
            >
              {/* <Label
                htmlFor="multiplePlayerFile"
                style={{ cursor: "pointer" }}
                className="fullwidth uploadFileLabel"
                title="Multiple Players"
              /> */}
              <p>Multiple {userType}s</p>
              {/* <input
                type="file"
                name="multiplePlayer"
                id="multiplePlayerFile"
                accept=".xlsx, .xls, .csv"
                style={{ display: "none" }}
                value={uploadFileData}
                // onChange={uploadFile}
                onClick={setPlayerCentre("multiplePlayer")}
                ref={fileRef}
              /> */}
              <p className="plusIcon">
                <FeatherIcon icon="file-plus" size={20} />
              </p>
            </div>

            <div id="playerCategoryList" style={{ marginBottom: 20 }}>
              <div
                id="playerCategoryHeader"
                className="listHeader click"
                onClick={() => setCategoryDropdownValue(!categoryDropdownValue)}
              >
                <p>
                  {playerCategory ? playerCategory.name : `Filter ${userType}s`}
                </p>
                <p className="plusIcon">
                  <FeatherIcon
                    icon={categoryDropdownValue ? "chevron-up" : "chevron-down"}
                    size={20}
                    className="text-dark"
                  />
                </p>
              </div>
              <div
                id="linkList"
                className={`noHeight ${categoryDropdownValue ? "active" : ""}`}
              >
                <div
                  key="allplayerscategories"
                  className="listItem click"
                  onClick={() => {
                    handleTab("viewPlayer");
                    setCategoryDropdownValue(false);
                    setPlayerCategory("");
                  }}
                >
                  All
                </div>
                {playerCategories &&
                  playerCategories.length > 0 &&
                  playerCategories.map((playerCat) => {
                    return (
                      playerCat?.name !== "" && (
                        <div
                          key={playerCat.id}
                          className="listItem click"
                          onClick={() => {
                            handleTab("viewPlayer");
                            setCategoryDropdownValue(false);
                            setPlayerCategory(playerCat);
                          }}
                        >
                          {playerCat?.name}
                        </div>
                      )
                    );
                  })}
              </div>
            </div>

            <div id="playerList">
              <div
                id="playerHeader"
                className="listHeader click"
                onClick={() => setDropdownValue(!dropdownValue)}
              >
                <p>All {userType}s</p>
                <p className="plusIcon">
                  <FeatherIcon
                    icon={dropdownValue ? "chevron-up" : "chevron-down"}
                    size={20}
                    className="text-dark"
                  />
                </p>
                {/* <Image
                  id="playerArrow"
                  className={`arrowIcon ${dropdownValue ? "rotate" : ""}`}
                  src="https://magicalogical.co.uk/routes/routes_design_1/public/images/dropdownarrow.svg"
                  alt="arrow icon"
                /> */}
              </div>
              <div
                id="linkList"
                className={`noHeight ${dropdownValue ? "active" : ""}`}
              >
                {filteredPlayers &&
                  filteredPlayers.length > 0 &&
                  filteredPlayers.map((player) => {
                    return (
                      player?.name !== "" && (
                        <div
                          key={player.id}
                          className="listItem click"
                          onClick={() => {
                            handleTab("viewPlayer");
                            setPlayer(player);
                          }}
                        >
                          {player?.name}
                        </div>
                      )
                    );
                  })}
              </div>
            </div>
          </div>
          <div>
            <div
              id="download_section"
              className="click downloadBtn"
              onClick={viewAllCV}
            >
              <span> View All CV</span>

              <div className="downloadIcon">
                <FeatherIcon icon={"eye"} size={20} />
              </div>
            </div>
            <div
              id="download_section"
              className="click downloadBtn"
              onClick={downloadFile}
            >
              <span> Download {userType}s</span>

              <div className="downloadIcon">
                <FeatherIcon icon={"download"} size={20} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
