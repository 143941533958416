import CourseInfo from "components/CourseInfo";
import InstitutionType from "components/InstitutionType";
import MainControlButton from "components/MainControlButton";
import React, { useEffect, useRef, useState } from "react";
import { uuid } from "utils/uuid";
import "./CreateCourse.css";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/Course/action";
import CreateCourseSteps from "components/CreateCourseSteps";

const CreateCourse = ({ playerCentre, player, setPlayerCentre }) => {
  const [isShowInputBox, setIsShowInputBox] = useState(true);
  const dispatch = useDispatch();
  const [courseInfo, setCourseInfo] = useState(null);
  const [newTypes, setNewTypes] = useState([{ id: uuid(), Types: "Cricket" }]);
  const { networkProgressDialog } = useSelector((state) => state.loader);

  const { course } = useSelector((state) => state.course);

  const CreateIntuitionType = (data) => {
    newTypes.find((item) => item.id);
    setNewTypes([...newTypes, data]);
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionInstitutionType, setSelectedOptionInstitutionType] =
    useState(null);
  const [selectedOptionQualificationType, setSelectedOptionQualificationType] =
    useState(null);
  const [selectedOptionGrading, setSelectedOptionGrading] = useState(null);
  const [dropdownValue, setDropdownValue] = useState(false);
  const [dropdownValueInstitutionType, setDropdownValueInstitutionType] =
    useState(false);

  const [dropdownValueQualificationType, setDropdownValueQualificationType] =
    useState(false);
  const [dropdownValueGrading, setDropdownValueGrading] = useState(false);

  const dropdownRef = useRef();

  useEffect(() => {
    dispatch(actions.getInstitutions());
    dispatch(actions.clearCourse());
  }, [dispatch]);

  // useEffect(() => {
  //   let hander = (e) => {
  //     if (selectedOption && dropdownRef.current?.contains(e.target)) {
  //       setSelectedOption({ ...selectedOption, disabled: true });
  //       setDropdownValue(false);
  //     }
  //   };
  //   return document.addEventListener("mousedown", hander);
  // }, [selectedOption, dropdownValue]);

  // useEffect(() => {
  //   let hander = (e) => {
  //     if (
  //       selectedOptionInstitutionType &&
  //       dropdownRef.current?.contains(e.target)
  //     ) {
  //       setSelectedOptionInstitutionType({
  //         ...selectedOptionInstitutionType,
  //         disabled: true,
  //       });
  //       setDropdownValueInstitutionType(false);
  //     }
  //   };
  //   return document.addEventListener("mousedown", hander);
  // }, [selectedOptionInstitutionType, dropdownValue]);

  // useEffect(() => {
  //   let hander = (e) => {
  //     if (
  //       selectedOptionQualificationType &&
  //       dropdownRef.current?.contains(e.target)
  //     ) {
  //       setSelectedOptionQualificationType({
  //         ...selectedOptionQualificationType,
  //         disabled: true,
  //       });
  //       setDropdownValueQualificationType(false);
  //     }
  //   };
  //   return document.addEventListener("mousedown", hander);
  // }, [selectedOptionQualificationType]);

  // useEffect(() => {
  //   let hander = (e) => {
  //     if (selectedOptionGrading && dropdownRef.current?.contains(e.target)) {
  //       setSelectedOptionGrading({
  //         ...selectedOptionGrading,
  //         disabled: true,
  //       });
  //       setDropdownValueGrading(false);
  //     }
  //   };
  //   return document.addEventListener("mousedown", hander);
  // }, [selectedOptionGrading]);

  return (
    <>
      <div
        className={`${
          playerCentre === "superCourseCenter"
            ? "CreateSuperCourseBtn"
            : "CreateCourseBtn"
        }`}>
        {playerCentre !== "superCourseCenter" && (
          <MainControlButton
            className="MainControlButton"
            playerCentre={playerCentre}
            player={player}
            setPlayerCentre={setPlayerCentre}
          />
        )}
        {/* <p className="CourseText">CREATE NEW COURSE</p> */}
        {selectedOption && (
          <CreateCourseSteps
            selectedOption={selectedOption}
            selectedOptionInstitutionType={selectedOptionInstitutionType}
            selectedOptionQualificationType={selectedOptionQualificationType}
            courseInfo={courseInfo}
          />
        )}
        <div className="container-fluid">
          <div className="row CreateCourseRow">
            <div className="col-md-auto createCourse">
              <p className="CourseText">CREATE NEW COURSE</p>
              {newTypes.map((data, i) => (
                <InstitutionType
                  key={i}
                  CreateIntuitionType={CreateIntuitionType}
                  setCourseInfo={setCourseInfo}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  selectedOptionInstitutionType={selectedOptionInstitutionType}
                  setSelectedOptionInstitutionType={
                    setSelectedOptionInstitutionType
                  }
                  selectedOptionQualificationType={
                    selectedOptionQualificationType
                  }
                  setSelectedOptionQualificationType={
                    setSelectedOptionQualificationType
                  }
                  selectedOptionGrading={selectedOptionGrading}
                  setSelectedOptionGrading={setSelectedOptionGrading}
                  dropdownValue={dropdownValue}
                  setDropdownValue={setDropdownValue}
                  dropdownValueInstitutionType={dropdownValueInstitutionType}
                  setDropdownValueInstitutionType={
                    setDropdownValueInstitutionType
                  }
                  dropdownValueQualificationType={
                    dropdownValueQualificationType
                  }
                  setDropdownValueQualificationType={
                    setDropdownValueQualificationType
                  }
                  dropdownValueGrading={dropdownValueGrading}
                  setDropdownValueGrading={setDropdownValueGrading}
                />
              ))}
            </div>
            <div
              className="CourseInfo col col-md-2 col-lg-4 mt-8"
              ref={dropdownRef}>
              {course && courseInfo && (
                <CourseInfo
                  courseInfo={course}
                  editExistingCourse={true}
                  createCourse={true}
                  isShowInputBox={isShowInputBox}
                  duplicateAndEdit={false}
                  setIsShowInputBox={setIsShowInputBox}
                  selectedOptionGradingEditable={{ id: course?.gradeId }}
                  selectedOptionQualificationTypeEditable={{
                    id: course?.qualificationId,
                  }}
                  selectedOptionInstitutionTypeEditable={{
                    id: course?.instituteTypeId,
                  }}
                  selectedOptionEditable={{ id: course?.instituteId }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {networkProgressDialog && courseInfo && (
        <div id="cover-spin" className="LoaderSpin">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateCourse;
