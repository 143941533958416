import React from "react";
import "./Input.css";

const Input = (props) => {
  const {
    id = "",
    name = "",
    value = "",
    type,
    className = "",
    onChange,
    placeholder = "",
    label = "",
    required = false,
    style,
    accept,
    disabled,
    divClass,
    divStyle,
    min,
    max,
    onInput,
    readOnly,
  } = props;

  return (
    <div className={divClass ? divClass : "createField"} style={divStyle}>
      <p>{label}</p>
      <input
        id={id}
        type={type}
        name={name}
        value={value}
        className={className}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        accept={accept}
        style={style}
        disabled={disabled}
        min={min}
        max={max}
        onInput={onInput}
        readOnly={readOnly}
      />
    </div>
  );
};

export default Input;
