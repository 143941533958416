import React, { useEffect, useState } from "react";
import Header from "components/Header";
import Navbar from "components/Navbar";
import PlayersCard from "components/PlayersCard";
import { actions as playersAction } from "redux/Player/action";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";

const Master = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [selectedClubs, setSelectedClubs] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [operation, setOperation] = useState("");
  const [selectedFilter, setSelectedFilter] = useState([
    {
      id: "aa8bae6a-b686-7be4-7558-65fe9174d006",
      status: [],
      startDate: null,
      endDate: null,
      positions: [],
      clubs: [],
    },
  ]);
  const [players, setPlayers] = useState([]);
  const token = localStorage.getItem("TOKEN");
  let decoded;
  if (token) {
    decoded = jwt_decode(token);
  }
  useEffect(() => {
    if (user) {
      (user?.club?.id || decoded?.role === "admin") &&
        dispatch(playersAction.getClub());
    }
  }, [user, token, decoded, dispatch]);

  return (
    <>
      <Navbar />
      <Header
        page={"master"}
        selectedPositions={selectedPositions}
        setSelectedPositions={setSelectedPositions}
        selectedClubs={selectedClubs}
        setSelectedClubs={setSelectedClubs}
        keyword={keyword}
        setKeyword={setKeyword}
        setPlayers={setPlayers}
        operation={operation}
        setOperation={setOperation}
        setSelectedFilter={setSelectedFilter}
        selectedFilter={selectedFilter}
      />
      <PlayersCard
        page={"master"}
        selectedPositions={selectedPositions}
        selectedClubs={selectedClubs}
        keyword={keyword}
        players={players}
        setPlayers={setPlayers}
        operation={operation}
        selectedFilter={selectedFilter}
      />
    </>
  );
};

export default Master;
