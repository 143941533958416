import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from "components/Navbar";
import { useSelector } from "react-redux";
import "./PlayerCategories.css";

const PlayerCategories = () => {
  const [data, setData] = useState([]);
  const [editId, setEditId] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState('');
  const { user } = useSelector((state) => state.user);
  const [editedCategoryName, setEditedCategoryName] = useState('');
  
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`player/category`);
      setData(response.data.result);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleEdit = (id, categoryName) => {
    setEditId(id);
    setEditedCategoryName(categoryName);
  };

  const handleSave = async (id, updatedCategoryName) => {
    try {
        var dataModel = {
            id: id,
            name: updatedCategoryName
        }

      await axios.put('player/updateCategory', dataModel);
      setEditId(null);
      setEditedCategoryName('');
      fetchData();
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  const handleCancel = () => {
    setEditId(null);
  };

  const handleNewRecord = async () => {
    try {
        var dataModel = {
            clubId: user?.club?.id,
            name: newCategoryName
        }

      await axios.post('player/addCategory', dataModel);
      fetchData();
      setNewCategoryName('');
    } catch (error) {
      console.error('Error adding new record:', error);
    }
  };

  return (
    <>
       <Navbar />

    

      <div className='playerCategoriesContainer'>
  {data.map(({ id, name }) => (
    <div className='playerCategoryRow' key={id}>
      {editId === id ? (
        <>
          <input
            type="text"
            value={editedCategoryName}
            onChange={(e) => setEditedCategoryName(e.target.value)}
          />
          <button className='categoryEditButton' onClick={() => handleSave(id, editedCategoryName)}>Update</button>
          <button className='categoryEditButton' onClick={handleCancel}>Cancel</button>
        </>
      ) : (
        <>
          {name}
          <button className='categoryEditButton' onClick={() => handleEdit(id, name)}>Edit</button>
        </>
      )}
    </div>
  ))}


      <div className='newCategoryArea'>
        <input
          type="text"
          placeholder="New Category Name"
          value={newCategoryName}
          onChange={(e) => setNewCategoryName(e.target.value)}
        />
        <button className='categoryEditButton' onClick={handleNewRecord}>Add New Record</button>
      </div>
      </div>
    </>
  );
};

export default PlayerCategories;
