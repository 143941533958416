import React, { useEffect, useState } from "react";
import MessageCentre from "components/MessageCentre";
import Navbar from "components/Navbar";
import PlayerCentre from "components/PlayerCentre";
import Sidebar from "components/Sidebar";
import { actions } from "redux/Club/action";
import { useDispatch, useSelector } from "react-redux";
// import ControlButton from "components/ControlButton";
// import { actions as cvActions } from "redux/CV/action";
// import { actions as courseActions } from "redux/Course/action";
// import MainControlButton from "components/MainControlButton";
import CreateCourse from "components/CreateCourse";
import { useLocation, useParams } from "react-router-dom";
import { CourseCardInfo } from "components/CourseCard";
import MyCourses from "components/MyCourses";
// import { actions as playersAction } from "redux/Player/action"
import { UserContext } from "components/contexts/User.context";
import { actions as courseActions } from "redux/Course/action";
import {
  INSTITUTE_ID_ACADEMIC,
  INSTITUTE_ID_ACADEMIC_SPORT,
} from "utils/constants";
import jwt_decode from "jwt-decode";
import CreateWorkExperience from "components/CreateWorkExperience";
import { actions as workExpActions } from "redux/WorkExperience/action";

const Dashboard = () => {
  const { user } = useSelector((state) => state.user);
  const { institutions } = useSelector((state) => state.course);
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const [playerCentre, setPlayerCentre] = useState("myCourseCenter");
  const [player, setPlayer] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [userType, setUserType] = useState("User");
  let { clubs } = useSelector((state) => state.club);

  let token = localStorage.getItem("TOKEN");

  let decoded;
  if (token) {
    decoded = jwt_decode(token);
  }

  //find clubData
  let clubData =
    params?.id &&
    clubs?.find((club) => {
      return params?.id === club?.id;
    });
  // const [messageSection, setMessageSection] = useState(false);

  // const handleClick = () => {
  //   setMessageSection(!messageSection);
  // };

  // const viewCV = () => {
  //   dispatch(cvActions.viewCV({ email: player?.email }));
  // };

  useEffect(() => {
    // dispatch(playersAction.getPlayerStatus());
    dispatch(actions.getClubs());
    // dispatch(courseActions.getInstitutions());
  }, [dispatch]);

  // added organization  wise labeling
  useEffect(() => {
    if (clubData?.instituteId === INSTITUTE_ID_ACADEMIC) {
      setUserType("Learner");
    }
    if (user?.club?.instituteId === INSTITUTE_ID_ACADEMIC) {
      setUserType("Learner");
    }
    if (clubData?.instituteId === INSTITUTE_ID_ACADEMIC_SPORT) {
      setUserType("Player");
      dispatch(
        courseActions.getInstitutionTypes({
          instituteId: clubData?.instituteId,
        })
      );
    }
    if (user?.club?.instituteId === INSTITUTE_ID_ACADEMIC_SPORT) {
      setUserType("Player");
      dispatch(
        courseActions.getInstitutionTypes({
          instituteId: user?.club?.instituteId,
        })
      );
    }
    // eslint-disable-next-line
  }, [institutions]);
  useEffect(() => {
    if (location?.state?.player?.id) {
      if (params?.id) {
        clubData &&
          user &&
          dispatch(
            courseActions.getCourses({
              instituteId: clubData?.instituteId,
              instituteTypeId: clubData?.instituteTypeId,
              userId: user?.id,
              clubId: params?.id,
              myCourses: true,
            })
          );
      } else {
        user &&
          dispatch(
            courseActions.getCourses({
              instituteId: user?.club?.instituteId,
              instituteTypeId: user?.club?.instituteTypeId,
              userId: user?.id,
              myCourses: true,
              clubId: user?.club?.id,
            })
          );
      }
      setPlayerCentre("viewPlayer");
      setPlayer(location?.state?.player);
    }
  }, [location?.state?.player?.id, location?.state?.player, clubData, user]);

  useEffect(() => {
    user &&
      dispatch(
        workExpActions?.getWorkExperienceTiles({
          clubId: params?.id ? params?.id : user?.club?.id,
        })
      );
  }, [user]);

  return (
    <>
      <UserContext.Provider value={{ user: userType }}>
        <Navbar />
        <div
          id="controlCentreContainer"
          className={`fullWidth ${
            playerCentre === "multiplePlayer" ? "" : "spaceBetween"
          }`}>
          <Sidebar
            setPlayerCentre={setPlayerCentre}
            setPlayer={setPlayer}
            setFileName={setFileName}
          />

          {playerCentre === "newPlayer" ||
          playerCentre === "viewPlayer" ||
          playerCentre === "multiplePlayer" ? (
            <PlayerCentre
              playerCentre={playerCentre}
              setPlayerCentre={setPlayerCentre}
              player={player}
              setPlayer={setPlayer}
              fileName={fileName}
            />
          ) : (
            ""
          )}

          {playerCentre !== "multiplePlayer" &&
          playerCentre !== "courseCenter" &&
          playerCentre !== "myCourseCenter" &&
          playerCentre !== "courseUnitCenter" &&
          playerCentre !== "createCourseCenter" &&
          playerCentre !== "workExperienceCenter" ? (
            <MessageCentre
              playerCentre={playerCentre}
              player={player}
              setPlayerCentre={setPlayerCentre}
            />
          ) : (
            ""
          )}

          {/* {playerCentre === "courseCenter" && (
          <MainControlButton
            playerCentre={playerCentre}
            player={player}
            setPlayerCentre={setPlayerCentre}
          />
        )} */}

          {playerCentre === "courseCenter" && (
            <CourseCardInfo
              playerCentre={playerCentre}
              player={player}
              setPlayerCentre={setPlayerCentre}
            />
          )}

          {playerCentre === "myCourseCenter" &&
            decoded?.role !== "tutor" &&
            user?.role !== "tutor" && (
              <MyCourses
                playerCentre={playerCentre}
                player={player}
                setPlayerCentre={setPlayerCentre}
              />
            )}

          {playerCentre === "courseUnitCenter" &&
            location.pathname.includes("/course") && (
              <CourseCardInfo
                playerCentre={playerCentre}
                player={player}
                setPlayerCentre={setPlayerCentre}
              />
            )}

          {playerCentre === "createCourseCenter" && (
            <CreateCourse
              playerCentre={playerCentre}
              player={player}
              setPlayerCentre={setPlayerCentre}
            />
          )}

          {playerCentre === "workExperienceCenter" && (
            <CreateWorkExperience
              playerCentre={playerCentre}
              player={player}
              setPlayerCentre={setPlayerCentre}
            />
          )}
        </div>
      </UserContext.Provider>
    </>
  );
};

export default Dashboard;
