export const INSTITUTE_ID_ACADEMIC = "ed810ec3-ab45-c914-f12e-d9a857d560a6";
export const INSTITUTE_ID_ACADEMIC_SPORT =
  "4edf8179-e313-c992-ab28-0475d99f9110";

export const PROFILE_FIELD_TYPES = [
  {
    id: 1,
    typeName: "String",
  },
  {
    id: 2,
    typeName: "Number",
  },
  {
    id: 3,
    typeName: "Boolean",
  },
  {
    id: 4,
    typeName: "Radio",
  },
  {
    id: 5,
    typeName: "Select",
  },
  {
    id: 6,
    typeName: "Multi-Select",
  },
  {
    id: 7,
    typeName: "Array",
  },
  {
    id: 8,
    typeName: "Date",
  },
  {
    id: 9,
    typeName: "Image",
  },
];

export const PROFILE_FIELD_VALUE_TYPES = [
  {
    id: 1,
    typeName: "Single Line",
    value: "textfield",
  },
  {
    id: 2,
    typeName: "Multi Line",
    value: "textview",
  },
];

export const PROFILE_FIELD_NAME_ID = "3b9107bc-27e2-022d-36fd-76ef115670d3";

export const PROFILE_FIELD_IMAGE_ID = "7787eac5-eccc-0cf4-cea4-d9344ddaa07f";

export const INSTITUTE_TYPE_ID_FOOTBALL =
  "cbfe115b-a362-a964-e44e-156e6d9c989e";

  export const PROFILE_FIELD_CATEGORIES = [
    {
      id: 1,
      typeName: "About Me",
    },
    {
      id: 2,
      typeName: "Education",
    },
    {
      id: 3,
      typeName: "Personal Development",
    },
    {
      id: 4,
      typeName: "Previous Clubs",
    },
    {
      id: 5,
      typeName: "Football Stats",
    },
    {
      id: 6,
      typeName: "Physical Markers",
    },
    {
      id: 7,
      typeName: "Transferable Skills",
    },
  ];