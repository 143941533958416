import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import "./NavigationStep.css";
import React from "react";

const NavigationStep = ({ steps }) => {
  return (
    <div className="d-flex px-lg-4 px-md-3 px-xs-2 navigation-step">
      {steps?.length > 0 &&
        steps?.map((item, i) => (
          <>
            <p
              className={`navigation-step-first-title ${
                item?.active === true
                  ? "navigation-title-active"
                  : "navigation-title-deactivate"
              }`}
              onClick={item?.onClick}
            >
              {item?.title ? item?.title : "AVAILABLE COURSE TYPES | ACADEMIC"}
            </p>

            {i < steps?.length - 1 && (
              <FeatherIcon
                className="navigation-step-rightIcon"
                icon={`chevron-right`}
                size={25}
                strokeWidth={2}
              />
            )}
            {/* <p className="navigation-step-second-title">{item}</p> */}
          </>
        ))}
    </div>
  );
};

export default NavigationStep;
