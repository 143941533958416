import setToken from "utils/setToken";
import { actions } from "./action";

const initialState = {
  workExperience: {},
  workExperiences: [],
  error: false,
};

const workExperienceReducer = (state = initialState, action) => {
  const token = localStorage.getItem("TOKEN");
  if (token) {
    setToken(token);
  }
  switch (action.type) {
    case actions.GET_WORK_EXPERIENCE_SUCCESS:
      return {
        ...state,
        workExperience: action.payload,
      };
    case actions.CREATE_WORK_EXPERIENCE_SUCCESS:
      return {
        ...state,
        workExperiences: [action.payload, ...state.workExperiences],
      };
    case actions.UPDATE_WORK_EXPERIENCE_SUCCESS:
      const index = state.workExperiences.findIndex(
        (workExp) => workExp.id === action.payload.id
      );
      if (index !== -1) {
        state.workExperiences[index] = action.payload;
      }
      return {
        ...state,
        workExperiences: state.workExperiences,
      };
    case actions.GET_WORK_EXPERIENCE_TILES_SUCCESS:
      return {
        ...state,
        workExperiences: action.payload,
      };

    case actions.CATCH_ERROR:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default workExperienceReducer;
