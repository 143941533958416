import Swal from "sweetalert2";

export const SweetAlert = async ({
  title,
  text,
  icon,
  showCancelButton,
  cancelButtonText,
  confirmButtonText,
  confirmButtonColor,
  cancelButtonColor,
  reverseButtons,
}) => {
  const result = await Swal.fire({
    title: title ? title : "Are you sure?",
    text: text ? text : "",
    icon: icon ? icon : "",
    showCancelButton: showCancelButton ? showCancelButton : false,
    cancelButtonText: cancelButtonText ? cancelButtonText : "",
    confirmButtonColor: confirmButtonColor ? confirmButtonColor : "",
    cancelButtonColor: cancelButtonColor ? cancelButtonColor : "",
    confirmButtonText: confirmButtonText ? confirmButtonText : "",
    reverseButtons: reverseButtons ? reverseButtons : false,
  });
  return result;
};
