import plus1 from "assets/images/plus1.png";
import Heading from "components/Heading";
import Image from "components/Image";
import "./AddUserProfileCard.css";

const AddUserProfileCard = ({ setShowProfileField, setCreateNewProfile }) => {
  return (
    <>
      {/* <div className="col-md-5 profile-card-div">
        <div className="card border-0 user-profile-card">
          <div className="card-header bg-white border-0 profile-card-header">
            <div className="d-flex justify-content-between">
              <p className="user-profile-EditIcon">Profile Filed 1</p>
              <div className="d-flex gap-2">
                <div className="">
                  <FeatherIcon
                    className={`user-profile-EditIcon`}
                    icon={`edit-3`}
                    size={20}
                    onClick={() => setShowSaveBtn(true)}
                  />
                </div>
                <div className="">
                  <FeatherIcon
                    className={`user-profile-RemoveIcon`}
                    icon={`trash-2`}
                    size={20}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-body user-profile-card-body">
            <div className="row align-items-baseline">
              <div className="col-md-1 card-title-div">
                <h6 className="poppins fw-bold text-capitalize profile-card-title m-0">
                  Title
                </h6>
              </div>

              <div className="col-md-11 profile-card-title card-title-div form__group">
                <input
                  type="input"
                  class="form__field"
                  placeholder="Enter the name"
                  required=""
                />
              </div>
            </div>

            <div className="row align-items-baseline">
              <div className="col-md-1 card-title-div">
                <h6 className="poppins fw-bold text-capitalize profile-card-title m-0">
                  Value
                </h6>
              </div>

              <div className="col-md-11 profile-card-title card-title-div form__group">
                <input
                  type="input"
                  class="form__field"
                  placeholder="Enter the value"
                  required=""
                />
              </div>
            </div>

            <div className="row align-items-baseline">
              <div className="col-md-1 card-title-div">
                <h6 className="poppins fw-bold text-capitalize profile-card-title m-0">
                  Type
                </h6>
              </div>

              <div className="col-md-11 profile-card-title card-title-div form__group">
                <input
                  type="input"
                  class="form__field"
                  placeholder="String"
                  required=""
                />
              </div>
            </div>
          </div>
          {showSaveBtn && (
            <div className="profile-card-footer">
              <div className="d-flex justify-content-end p-2 gap-2">
                <button type="button" class="btn btn-outline-secondary btn-sm">
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn user-profile-saveBtn btn-sm"
                  onClick={() => setShowSaveBtn(false)}
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div> */}

      <div className="user-profile-card ">
        <div>
          <div className="px-lg-4 px-md-3 px-xs-2 pb-5 pt-4">
            <div className="card border-0">
              <div
                className="card-body p-4 cursor-pointer"
                onClick={() => {
                  setShowProfileField(true);
                  setCreateNewProfile(false);
                }}
              >
                <div className="text-black">
                  <div
                    className={`flex-shrink-0 d-flex img-wrapper-user-profile align-items-center`}
                  >
                    <Image
                      src={plus1}
                      alt=""
                      className="img-fluid w-100 club_image_card cardBodyAddCard"
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                  <div className="row w-100">
                    <Heading
                      type="h5"
                      className={`mb-0 text-green pt-1 poppins fw-bold text-truncate text-center text-dark`}
                      label={"Add New Field"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUserProfileCard;
