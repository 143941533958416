export const actions = {
  GET_COURSE_REQUEST: "GET_COURSE_REQUEST",
  GET_COURSE_SUCCESS: "GET_COURSE_SUCCESS",

  // GET_MY_COURSE_REQUEST: "GET_MY_COURSE_REQUEST",
  GET_MY_COURSE_SUCCESS: "GET_MY_COURSE_SUCCESS",

  GET_INSTITUTION_REQUEST: "GET_INSTITUTION_REQUEST",
  GET_INSTITUTION_SUCCESS: "GET_INSTITUTION_SUCCESS",

  GET_INSTITUTION_TYPES_REQUEST: "GET_INSTITUTION_TYPES_REQUEST",
  GET_INSTITUTION_TYPES_SUCCESS: "GET_INSTITUTION_TYPES_SUCCESS",

  GET_QUALIFICATION_REQUEST: "GET_QUALIFICATION_REQUEST",
  GET_QUALIFICATION_SUCCESS: "GET_QUALIFICATION_SUCCESS",

  GET_GRADE_REQUEST: "GET_GRADE_REQUEST",
  GET_GRADE_SUCCESS: "GET_GRADE_SUCCESS",

  GET_UNIT_REQUEST: "GET_UNIT_REQUEST",
  GET_UNIT_SUCCESS: "GET_UNIT_SUCCESS",

  CREATE_COURSE_REQUEST: "CREATE_COURSE_REQUEST",
  CREATE_COURSE_SUCCESS: "CREATE_COURSE_SUCCESS",

  UPDATE_COURSE_REQUEST: "UPDATE_COURSE_REQUEST",
  UPDATE_COURSE_SUCCESS: "UPDATE_COURSE_SUCCESS",

  GET_SINGLE_COURSE_REQUEST: "GET_SINGLE_COURSE_REQUEST",
  GET_SINGLE_COURSE_SUCCESS: "GET_SINGLE_COURSE_SUCCESS",

  CREATE_INSTITUTION_TYPE_REQUEST: "CREATE_INSTITUTION_TYPE_REQUEST",
  CREATE_INSTITUTION_TYPE_SUCCESS: "CREATE_INSTITUTION_TYPE_SUCCESS",

  UPDATE_INSTITUTION_TYPE_REQUEST: "UPDATE_INSTITUTION_TYPE_REQUEST",
  UPDATE_INSTITUTION_TYPE_SUCCESS: "UPDATE_INSTITUTION_TYPE_SUCCESS",

  CREATE_QUALIFICATION_REQUEST: "CREATE_QUALIFICATION_REQUEST",
  CREATE_QUALIFICATION_SUCCESS: "CREATE_QUALIFICATION_SUCCESS",

  UPDATE_QUALIFICATION_REQUEST: "UPDATE_QUALIFICATION_REQUEST",
  UPDATE_QUALIFICATION_SUCCESS: "UPDATE_QUALIFICATION_SUCCESS",

  CREATE_GRADE_STRUCTURE_REQUEST: "CREATE_GRADE_STRUCTURE_REQUEST",
  CREATE_GRADE_STRUCTURE_SUCCESS: "CREATE_GRADE_STRUCTURE_SUCCESS",

  ADD_TO_MY_COURSE_REQUEST: "ADD_TO_MY_COURSE_REQUEST",
  ADD_TO_MY_COURSE_SUCCESS: "ADD_TO_MY_COURSE_SUCCESS",

  // REMOVE_CLUB_REQUEST: "REMOVE_CLUB_REQUEST",
  // REMOVE_CLUB_SUCCESS: "REMOVE_CLUB_SUCCESS",

  // UNREMOVE_CLUB_REQUEST: "UNREMOVE_CLUB_REQUEST",
  // UNREMOVE_CLUB_SUCCESS: "UNREMOVE_CLUB_SUCCESS",

  ADD_TO_PREVIOUS_FILTER_COURSE_REQUEST:
    "ADD_TO_PREVIOUS_FILTER_COURSE_REQUEST",
  ADD_TO_PREVIOUS_FILTER_COURSE_SUCCESS:
    "ADD_TO_PREVIOUS_FILTER_COURSE_SUCCESS",

  CATCH_ERROR: "CATCH_ERROR",

  CLEAR_COURSE_REQUEST: "CLEAR_COURSE_REQUEST",
  CLEAR_COURSE_SUCCESS: "CLEAR_COURSE_SUCCESS",

  CLEAR_COURSES_REQUEST: "CLEAR_COURSES_REQUEST",
  CLEAR_COURSES_SUCCESS: "CLEAR_COURSES_SUCCESS",

  getInstitutions: () => {
    return {
      type: actions.GET_INSTITUTION_REQUEST,
    };
  },

  getInstitutionTypes: (data) => {
    return {
      type: actions.GET_INSTITUTION_TYPES_REQUEST,
      data,
    };
  },

  getQualifications: (data) => {
    return {
      type: actions.GET_QUALIFICATION_REQUEST,
      data,
    };
  },

  getGrades: (data) => {
    return {
      type: actions.GET_GRADE_REQUEST,
      data,
    };
  },

  getCourses: (data) => {
    return {
      type: actions.GET_COURSE_REQUEST,
      data,
    };
  },

  getSingleCourse: (data) => {
    return {
      type: actions.GET_SINGLE_COURSE_REQUEST,
      data,
    };
  },

  clearCourse: (data) => {
    return {
      type: actions.CLEAR_COURSE_REQUEST,
      data,
    };
  },

  clearCourses: (data) => {
    return {
      type: actions.CLEAR_COURSES_REQUEST,
      data,
    };
  },

  getUnits: (data) => {
    return {
      type: actions.GET_UNIT_REQUEST,
      data,
    };
  },

  createCourse: (data, navigate) => {
    return {
      type: actions.CREATE_COURSE_REQUEST,
      data,
      navigate,
    };
  },

  addToMyCourse: (data) => {
    return {
      type: actions.ADD_TO_MY_COURSE_REQUEST,
      data,
    };
  },

  updateCourse: (data, navigate) => {
    return {
      type: actions.UPDATE_COURSE_REQUEST,
      data,
      navigate,
    };
  },

  createInstitutionType: (data, navigate) => {
    return {
      type: actions.CREATE_INSTITUTION_TYPE_REQUEST,
      data,
      navigate,
    };
  },

  updateInstitutionType: (data) => {
    return {
      type: actions.UPDATE_INSTITUTION_TYPE_REQUEST,
      data,
    };
  },

  createQualification: (data, navigate) => {
    return {
      type: actions.CREATE_QUALIFICATION_REQUEST,
      data,
      navigate,
    };
  },

  updateQualification: (data) => {
    return {
      type: actions.UPDATE_QUALIFICATION_REQUEST,
      data,
    };
  },

  createGrade: (data) => {
    return {
      type: actions.CREATE_GRADE_STRUCTURE_REQUEST,
      data,
    };
  },

  addToPreviousFilterCorse: (data) => {
    return {
      type: actions.ADD_TO_PREVIOUS_FILTER_COURSE_REQUEST,
      data,
    };
  },

  // updateClub: (data, alert = true) => {
  //   return {
  //     type: actions.UPDATE_CLUB_REQUEST,
  //     data,
  //     alert,
  //   };
  // },

  // getAllPlayers: (data) => {
  //   return {
  //     type: actions.GET_ALL_PLAYERS_REQUEST,
  //     data,
  //   };
  // },
};
