import React, { useContext, useEffect, useState } from "react";
import Button from "components/Button";
import { Dropdown } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { actions as playersAction } from "redux/Player/action";
import { read, utils, writeFile } from "xlsx";
import "./Multiplayers.css";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { Toast } from "utils/toast";
import { UserContext } from "components/contexts/User.context";
import SelectionCourse from "./SelectionCourse";

// const status = Status.map((status) => {
//   return { name: status?.name, value: status.id };
// });

const MultiplePlayers = ({ player, setPlayer, setPlayerCentre }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { user: userType } = useContext(UserContext);

  let { clubs } = useSelector((state) => state.club);
  let clubData =
    params?.id &&
    clubs?.find((club) => {
      return params?.id === club?.id;
    });

  const { user } = useSelector((state) => state.user);
  // const fileRef = useRef();
  // eslint-disable-next-line
  const [playerStatus, setPlayerStatus] = useState("");
  const [nextStep, setNextStep] = useState(false);
  const [disabledNextButton, setDisabledNextButton] = useState(false);
  const [uploadFileData, setUploadFileData] = useState(null);
  const [validation, setValidation] = useState([]);

  const {
    playerStatus: playerStatuses,
    errors,
    existPlayers,
    newPlayers,
  } = useSelector((state) => state.players);

  const status =
    playerStatuses &&
    playerStatuses?.length > 0 &&
    playerStatuses?.map((status) => {
      return { name: status.name, value: status.id };
    });

  const uploadFile = () => {
    const isFindPlayer = player?.find((data) => {
      return (
        !data.validationStatus.isValidDate ||
        !data.validationStatus.isValidEmail
      );
    });

    if (isFindPlayer) {
      Toast("error", "Please enter valid details!");
    } else {
      delete player.validationStatus;
      dispatch(playersAction.createPlayers(player));
      setNextStep(false);
      setUploadFileData(null);
    }
  };

  const handleChange = (play, e) => {
    let playersData = player.map((player) => {
      if (player?.email === play?.email) {
        player.status = e.value;
      }
      return player;
    });
    setPlayerStatus(e.value);
    setPlayer([...playersData]);
  };

  const downloadFile = () => {
    let playersData = [
      {
        forename: "John",
        surname: "Doe",
        email: "john@gmail.com",
        date_of_birth: "20.08.2004",
        FAno: "74433140",
        PFAno: "60610",
        NIno: "PK 15 56 20 A",
      },
    ];
    const Heading = [
      [
        "Forename",
        "Surname",
        "Email",
        "Date of Birth",
        "FA No.",
        "PFA No.",
        "N.I. No.",
      ],
    ];
    let filename = "Players.xlsx";
    var ws = utils.json_to_sheet(playersData, {
      origin: "A2",
      skipHeader: true,
    });
    utils.sheet_add_aoa(ws, Heading, { origin: "A1" });
    var wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "People");

    writeFile(wb, filename, {
      type: "buffer",
      cellStyles: true,
    });
  };

  function ValidateEmail(email) {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (email && email.match(validRegex)) {
      return true;
    } else {
      setValidation([...validation, { email: email, error: "Invalid Email!" }]);
      return false;
    }
  }

  function ValidateDate(date) {
    if (Object.prototype.toString.call(date) === "[object Date]") {
      return true;
    } else {
      // setValidation([...validation, { email: email, error: "Invalid Email!" }]);
      return false;
    }
  }

  const chooseFile = (e) => {
    setUploadFileData(e.target.value);
    setDisabledNextButton(false);
    var reader = new FileReader();
    reader.onload = function (e) {
      var workbook = read(reader.result, {
        type: "binary",
        cellDates: true,
      });
      var firstSheet = workbook.SheetNames[0];
      let arr = sheet2arr(workbook.Sheets[firstSheet]);
      let dataArr = [];
      for (let i = 1; i < arr.length; i++) {
        const surname = arr[i][1] ? arr[i][1] : "";
        let isValidEmail = ValidateEmail(arr[i][2]);
        let isValidDate = ValidateDate(
          arr[i][3] !== undefined
            ? new Date(moment(arr[i][3], "DD-MM-YYYY").format("YYYY-MM-DD"))
            : ""
        );
        let obj = {
          name: arr[i][0] + " " + surname,
          email: arr[i][2],
          // date_of_birth:
          //   new Date(arr[i][2]).getFullYear() +
          //   "-" +
          //   (new Date(arr[i][2]).getMonth() + 1 < 10
          //     ? "0" + (new Date(arr[i][2]).getMonth() + 1)
          //     : new Date(arr[i][2]).getMonth() + 1) +
          //   "-" +
          //   (new Date(arr[i][2]).getDate() < 10
          //     ? "0" + new Date(arr[i][2]).getDate()
          //     : new Date(arr[i][2]).getDate()),
          date_of_birth:
            arr[i][3] !== undefined
              ? moment(arr[i][3], "DD-MM-YYYY").format("YYYY-MM-DD")
              : "",
          FAno: arr[i][4] === undefined ? "" : arr[i][4],
          PFAno: arr[i][5] === undefined ? "" : arr[i][5],
          NIno: arr[i][6] === undefined ? "" : arr[i][6],
          validationStatus: { isValidEmail, isValidDate },
          coursesId: [],
          workExperiencesId: [],
        };

        dataArr.push(obj);
      }

      let playersJSONData = utils.sheet_to_row_object_array(
        workbook.Sheets[firstSheet]
      );
      // eslint-disable-next-line
      playersJSONData = playersJSONData.map((data) => {
        // data.club = user?.club;
        data.club =
          clubData && params?.id
            ? {
                id: clubData?.id,
                name: clubData?.name,
                image: clubData?.image,
              }
            : user?.club;
        return data;
      });

      dataArr = dataArr?.map((data) => {
        // data.club = user?.club;
        data.club =
          clubData && params?.id
            ? {
                id: clubData?.id,
                name: clubData?.name,
                image: clubData?.image,
                instituteId: clubData?.instituteId,
                instituteTypeId: clubData?.instituteTypeId,
              }
            : user?.club;
        return data;
      });
      setPlayer(dataArr);
    };

    reader.readAsBinaryString(e.target.files[0]);
    // setPlayerCentre("multiplePlayer");

    var sheet2arr = function (sheet) {
      var result = [];
      var row;
      var rowNum;
      var colNum;
      var range = utils.decode_range(sheet["!ref"]);
      for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        row = [];
        for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
          var nextCell = sheet[utils.encode_cell({ r: rowNum, c: colNum })];

          if (typeof nextCell == "undefined") {
            row.push(void 0);
          } else row.push(nextCell.w);
        }
        result.push(row);
      }
      return result;
    };
  };

  useEffect(() => {
    const playersCopy = structuredClone(player);
    const players =
      playersCopy?.length > 0 &&
      playersCopy?.map((player) => {
        player.resStatus = "SUCCEED";
        player.resMessage = "";
        if (existPlayers) {
          let data = existPlayers.find((playerExist) => {
            return playerExist.user === player.email;
          });
          if (data) {
            player.resStatus = "FAILED";
            player.resMessage = data.error;
          }
          setDisabledNextButton(true);
        }
        if (errors) {
          let dataArr = errors.find((playerExist) => {
            return playerExist.user === player.email && playerExist;
          });
          if (dataArr) {
            player.resStatus = "PENDING";
            player.resMessage = dataArr.error;
          }
          setDisabledNextButton(true);
        }
        return player;
      });
    setPlayer(players);
    // eslint-disable-next-line
  }, [existPlayers, errors, newPlayers]);

  return (
    <>
      <div id="uploadFileSection" className="">
        <div className="uploadFileName">
          {!nextStep && (
            <div className="downloadSampleFile">
              {/* <p>
              Download the sample file from{" "}
              <a className="" href="" disabled onClick={downloadFile}>
                here.
              </a>
            </p> */}
              <div className="d-flex downloadSampleFileDiv">
                <div className="h-100 border-end p-2">
                  <FeatherIcon icon="file-text" />
                </div>
                <div className="p-2">
                  <p>
                    Download the sample file from{" "}
                    <Link to="" onClick={downloadFile}>
                      here.
                    </Link>
                  </p>
                </div>
              </div>
              <div className="my-4">
                {/* <Button
                id="selectFile"
                className="uploadButton"
                handleClick={() => fileRef.current.click()}
                label="Select File"
              /> */}
                <p className="mb-2" style={{ color: "#5d5d5d " }}>
                  Select a {userType} file.
                </p>
                <div className="p-2 inputWrapper rounded-1">
                  <input
                    type="file"
                    name="multiplePlayer"
                    id="multiplePlayerFile"
                    accept=".xlsx, .xls, .csv"
                    // style={{ display: "none" }}
                    value={uploadFileData}
                    onChange={chooseFile}
                    // ref={fileRef}
                    style={{ color: "#5d5d5d " }}
                  />
                </div>
                <p className="text-muted mt-1" style={{ fontSize: "small" }}>
                  only the <b>.xls, .xlsx</b> file types are allowed.
                </p>
                {player?.length <= 0 && (
                  <p className="text-muted mt-2" style={{ fontSize: "small" }}>
                    Date of Birth formate must be <b>DD.MM.YYYY</b>.
                  </p>
                )}
              </div>
            </div>
          )}

          {!nextStep && player && player?.length > 0 && (
            <div className="playerList my-5 w-75">
              <table className="table table-hover table-striped text-center table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Forename</th>
                    <th scope="col">Surname</th>
                    <th scope="col">Email</th>
                    <th scope="col">{userType} Status</th>
                    {player?.find((data) => {
                      return (
                        !data.validationStatus.isValidDate ||
                        !data.validationStatus.isValidEmail
                      );
                    }) && <th scope="col">Error Report</th>}
                    {(errors.length > 0 ||
                      existPlayers.length > 0 ||
                      newPlayers.length > 0) &&
                      player &&
                      player?.length > 0 &&
                      player[0]?.resStatus && <th scope="col">Status</th>}
                    {(errors.length > 0 ||
                      existPlayers.length > 0 ||
                      newPlayers.length > 0) &&
                      player &&
                      player?.length > 0 &&
                      player[0]?.resStatus && <th scope="col">Message</th>}
                  </tr>
                </thead>
                <tbody>
                  {player &&
                    player?.length > 0 &&
                    player?.map((data) => {
                      return (
                        <tr key={data?.email}>
                          <td>{data?.name ? data?.name.split(" ")[0] : ""}</td>
                          <td>
                            {data?.name
                              ? data?.name.split(" ")[1] === undefined
                                ? ""
                                : data?.name.split(" ")[1]
                              : ""}
                          </td>
                          <td>
                            {/* {validation.find(
                              (data_) => data_?.email === data.email
                            ) ? (
                              <FeatherIcon icon="alert-triangle" color="red" />
                            ) : (
                              ""
                            )} */}
                            {data?.email ? data?.email : ""}
                          </td>
                          <td>
                            <Dropdown
                              value={data?.status}
                              options={status}
                              onChange={(e) => handleChange(data, e)}
                              optionLabel="name"
                              placeholder="Select a Status"
                              className="w-100 text-start"
                              disabled={data?.resStatus ? true : false}
                            />
                          </td>
                          {data?.resStatus && (
                            <td>
                              <span
                                className={`badge rounded-pill
                                ${
                                  data?.resStatus === "PENDING"
                                    ? "bg-danger"
                                    : data?.resStatus === "SUCCEED"
                                    ? "bg-success"
                                    : "bg-danger"
                                }
                              `}>
                                {data?.resStatus ? data?.resStatus : ""}
                              </span>
                            </td>
                          )}
                          {data?.resStatus && (
                            <td>
                              <span className="">
                                {data?.resMessage ? data?.resMessage : ""}
                              </span>
                            </td>
                          )}
                          {player?.find((data) => {
                            return (
                              !data.validationStatus.isValidDate ||
                              !data.validationStatus.isValidEmail
                            );
                          }) && (
                            <td className="text-danger">
                              {!data?.validationStatus?.isValidEmail &&
                              !data?.validationStatus?.isValidDate
                                ? "Invalid email and date!"
                                : !data?.validationStatus?.isValidEmail
                                ? "Invalid email!"
                                : !data?.validationStatus?.isValidDate
                                ? "Invalid date!"
                                : ""}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}

          {nextStep && (
            <SelectionCourse player={player} setPlayer={setPlayer} />
          )}

          {!disabledNextButton && (
            <div className="multipleDataButtonDiv">
              {player && player?.length > 0 && (
                <>
                  <Button
                    id="upload"
                    className="uploadButton"
                    handleClick={() => {
                      const isFindPlayer = player?.find((data) => {
                        return (
                          !data.validationStatus.isValidDate ||
                          !data.validationStatus.isValidEmail
                        );
                      });

                      if (isFindPlayer) {
                        Toast("error", "Please enter valid details!");
                      } else {
                        if (!nextStep) {
                          setNextStep(true);
                        } else {
                          uploadFile();
                        }
                      }
                    }}
                    label={!nextStep ? "Next" : "Upload"}
                    disabled={disabledNextButton ? true : false}
                    style={{
                      cursor: disabledNextButton ? "not-allowed" : "pointer",
                    }}
                  />
                  <Button
                    id="cancel"
                    className="uploadButton"
                    handleClick={() => {
                      setPlayer(null);
                      setUploadFileData("");
                      setNextStep(false);
                      setDisabledNextButton(false);
                      // fileRef.current.form.reset();
                    }}
                    label="Cancel"
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MultiplePlayers;
