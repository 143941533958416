import setToken from "utils/setToken";
import { actions } from "./action";

const initialState = {
  message: null,
  messages: [],
};

const messageReducer = (state = initialState, action) => {
  const token = localStorage.getItem("TOKEN");
  if (token) {
    setToken(token);
  }
  switch (action.type) {
    case actions.GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.payload,
      };
    case actions.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        message: action.payload,
      };
    default:
      return state;
  }
};

export default messageReducer;
