import { all } from "redux-saga/effects";
import cvSaga from "./CV/saga";
import loaderSaga from "./Loader/saga";
import messageSaga from "./Message/saga";
import playerSaga from "./Player/saga";
import userSaga from "./User/saga";
import clubSaga from "./Club/saga";
import tutorSaga from "./Tutor/saga";
import courseSaga from "./Course/saga";
import profileSaga from "./Profile/saga";
import workExperienceSaga from "./WorkExperience/saga";

export default function* rootSaga() {
  yield all([
    userSaga(),
    playerSaga(),
    messageSaga(),
    cvSaga(),
    loaderSaga(),
    clubSaga(),
    tutorSaga(),
    courseSaga(),
    profileSaga(),
    workExperienceSaga(),
  ]);
}
