export const actions = {
  GET_TUTORS_REQUEST: "GET_TUTORS_REQUEST",
  GET_TUTORS_SUCCESS: "GET_TUTORS_SUCCESS",

  GET_CLUB_ADMINS_REQUEST: "GET_CLUB_ADMINS_REQUEST",
  GET_CLUB_ADMINS_SUCCESS: "GET_CLUB_ADMINS_SUCCESS",

  CREATE_TUTOR_REQUEST: "CREATE_TUTOR_REQUEST",
  CREATE_TUTOR_SUCCESS: "CREATE_TUTOR_SUCCESS",

  UPDATE_TUTOR_REQUEST: "UPDATE_TUTOR_REQUEST",
  UPDATE_TUTOR_SUCCESS: "UPDATE_TUTOR_SUCCESS",

  REMOVE_TUTOR_REQUEST: "REMOVE_TUTOR_REQUEST",
  REMOVE_TUTOR_SUCCESS: "REMOVE_TUTOR_SUCCESS",

  UNREMOVE_TUTOR_REQUEST: "UNREMOVE_TUTOR_REQUEST",
  UNREMOVE_TUTOR_SUCCESS: "UNREMOVE_TUTOR_SUCCESS",

  DELETE_TUTOR_REQUEST: "DELETE_TUTOR_REQUEST",
  DELETE_TUTOR_SUCCESS: "DELETE_TUTOR_SUCCESS",

  CLEAR_TUTORS_STATE_REQUEST: "CLEAR_TUTORS_STATE_REQUEST",
  CLEAR_TUTORS_STATE_SUCCESS: "CLEAR_TUTORS_STATE_SUCCESS",

  CATCH_ERROR: "CATCH_ERROR",

  createTutor: (data, navigate) => {
    return {
      type: actions.CREATE_TUTOR_REQUEST,
      data,
      navigate,
    };
  },

  getTutors: (data) => {
    return {
      type: actions.GET_TUTORS_REQUEST,
      data,
    };
  },

  updateTutor: (data, alert = true) => {
    return {
      type: actions.UPDATE_TUTOR_REQUEST,
      data,
      alert,
    };
  },

  getClubAdmins: (data) => {
    return {
      type: actions.GET_CLUB_ADMINS_REQUEST,
      data,
    };
  },

  deleteTutor: (data) => {
    return {
      type: actions.DELETE_TUTOR_REQUEST,
      data,
    };
  },

  clearTutorsState: () => {
    return {
      type: actions.CLEAR_TUTORS_STATE_REQUEST,
    };
  },

  // getAllPlayers: (data) => {
  //   return {
  //     type: actions.GET_ALL_PLAYERS_REQUEST,
  //     data,
  //   };
  // },
};
