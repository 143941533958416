import React, { useEffect } from "react";
import { useState } from "react";
import CourseInfo from "components/CourseInfo";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/Course/action";
import InstitutionType from "components/InstitutionType";
import { useRef } from "react";
import { actions as loaderActions } from "redux/Loader/action";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Navbar from "components/Navbar";
import AllCourses from "./AllCourses";

function EditableCourse() {
  const location = useLocation();
  const params = useParams();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const dropdownRef = useRef();
  const token = localStorage.getItem("TOKEN");
  let decoded;
  if (token) {
    decoded = jwt_decode(token);
  }
  useEffect(() => {
    let hander = (e) => {
      if (menuRef && !menuRef.current?.contains(e.target)) {
        setDropdownValueUnitCard(false);
      }
    };
    document.addEventListener("mousedown", hander);
  }, []);

  useEffect(() => {
    dispatch(loaderActions.startLoader());
    dispatch(
      actions.getSingleCourse({
        id: params?.courseId,
      })
    );
  }, [dispatch, params.courseId]);

  // eslint-disable-next-line
  const { course } = useSelector((state) => state.course);
  const { networkProgressDialog } = useSelector((state) => state.loader);
  const [loaderSave, setLoaderSave] = useState(false);
  const [isShow, setIsShow] = useState(false);
  // eslint-disable-next-line
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedOptionEditable, setSelectedOptionEditable] = useState(null);
  const [
    selectedOptionInstitutionTypeEditable,
    setSelectedOptionInstitutionTypeEditable,
  ] = useState(null);
  const [
    selectedOptionQualificationTypeEditable,
    setSelectedOptionQualificationTypeEditable,
  ] = useState(null);
  const [selectedOptionGradingEditable, setSelectedOptionGradingEditable] =
    useState(null);
  const [isShowInputBox, setIsShowInputBox] = useState(true);
  const [duplicateShow, setDuplicateShow] = useState(true);
  const [duplicateAndEdit, setDuplicateAndEdit] = useState(
    location?.state?.duplicateAndEdit ? true : false
  );
  const [isCancel, setIsCancel] = useState(false);
  // eslint-disable-next-line
  const [dropdownValueUnitCard, setDropdownValueUnitCard] = useState({
    id: "",
    open: false,
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionInstitutionType, setSelectedOptionInstitutionType] =
    useState(null);
  const [selectedOptionQualificationType, setSelectedOptionQualificationType] =
    useState(null);
  const [selectedOptionGrading, setSelectedOptionGrading] = useState(null);
  const [dropdownValue, setDropdownValue] = useState(false);
  const [dropdownValueInstitutionType, setDropdownValueInstitutionType] =
    useState(false);

  const [dropdownValueQualificationType, setDropdownValueQualificationType] =
    useState(false);
  const [dropdownValueGrading, setDropdownValueGrading] = useState(false);

  const navigate = useNavigate();
  const menuRef = useRef();

  useEffect(() => {
    setDuplicateAndEdit(location?.state?.duplicateAndEdit ? true : false);
  }, [location?.state?.duplicateAndEdit]);

  return (
    <div>
      <Navbar />
      <div className="container-fluid mt-3">
        <div
          className="d-flex align-items-center text-secondary fw-bold"
          onClick={() => {
            navigate(-1);
            dispatch(actions.addToPreviousFilterCorse(location.state));
            dispatch(actions.clearCourse());
          }}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="feather feather-arrow-left cursor-pointer"
          >
            <g>
              <line x1="19" y1="12" x2="5" y2="12"></line>
              <polyline points="12 19 5 12 12 5"></polyline>
            </g>
          </svg>
          <span className="ps-1 cursor-pointer">Back</span>
        </div>
      </div>
      <>
        <div className="container-fluid">
          <div className="row courseCardInfoRow my-5">
            <div className="col-md-auto courseCardInfoCol">
              <InstitutionType
                // CreateIntuitionType={CreateIntuitionType}
                // setCourseInfo={setCourseInfo}
                course={course}
                editExistingCourse={true}
                setSelectedOptionGradingEditable={
                  setSelectedOptionGradingEditable
                }
                setSelectedOptionQualificationTypeEditable={
                  setSelectedOptionQualificationTypeEditable
                }
                setSelectedOptionInstitutionTypeEditable={
                  setSelectedOptionInstitutionTypeEditable
                }
                setSelectedOptionEditable={setSelectedOptionEditable}
                isCancel={isCancel}
                setIsCancel={setIsCancel}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                selectedOptionInstitutionType={selectedOptionInstitutionType}
                setSelectedOptionInstitutionType={
                  setSelectedOptionInstitutionType
                }
                selectedOptionQualificationType={
                  selectedOptionQualificationType
                }
                setSelectedOptionQualificationType={
                  setSelectedOptionQualificationType
                }
                selectedOptionGrading={selectedOptionGrading}
                setSelectedOptionGrading={setSelectedOptionGrading}
                dropdownValue={dropdownValue}
                setDropdownValue={setDropdownValue}
                dropdownValueInstitutionType={dropdownValueInstitutionType}
                setDropdownValueInstitutionType={
                  setDropdownValueInstitutionType
                }
                dropdownValueQualificationType={dropdownValueQualificationType}
                setDropdownValueQualificationType={
                  setDropdownValueQualificationType
                }
                dropdownValueGrading={dropdownValueGrading}
                setDropdownValueGrading={setDropdownValueGrading}
              />
            </div>
            <div
              className="CourseInfo col col-md-2 col-lg-4 "
              ref={dropdownRef}
            >
              {
                <CourseInfo
                  courseInfo={course}
                  // courseInfoCopy={courseInfoCopy}
                  editExistingCourse={true}
                  setIsShowInputBox={setIsShowInputBox}
                  isShowInputBox={isShowInputBox}
                  setIsShow={setIsShow}
                  setDuplicateShow={setDuplicateShow}
                  duplicateAndEdit={duplicateAndEdit}
                  duplicateShow={duplicateShow}
                  selectedOptionGradingEditable={selectedOptionGradingEditable}
                  selectedOptionQualificationTypeEditable={
                    selectedOptionQualificationTypeEditable
                  }
                  selectedOptionInstitutionTypeEditable={
                    selectedOptionInstitutionTypeEditable
                  }
                  selectedOptionEditable={selectedOptionEditable}
                  setLoaderSave={setLoaderSave}
                  setIsCancel={setIsCancel}
                  editExistingCourseFromAllCourses={true}
                />
             
              }
            </div>

            {/* <div className="mt-5">
            <CourseInfo
              courseInfo={courseInfos}
              courseInfoCopy={courseInfoCopy}
              setIsShow={setIsShow}
              setDuplicateShow={setDuplicateShow}
              duplicateAndEdit={duplicateAndEdit}
            />
          </div> */}
          </div>
        </div>

        {networkProgressDialog && (
          <>
            <div id="cover-spin" className="LoaderSpin">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </>
        )}
      </>
    </div>
  );
}

export default EditableCourse;
