/* eslint-disable array-callback-return */
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { actions } from "redux/Course/action";
import { actions as loaderActions } from "redux/Loader/action";
import { Toast } from "utils/toast";
import { getBlobName, uploadFile } from "utils/uploadFile";
import { uuid } from "utils/uuid";
import "./UnitInfo.css";

const UnitInfo = ({
  isShowInputBox,
  setIsShowInputBox,
  courseUpdate,
  setCourseUpdate,
  courseInfo,
  module,
  courseInfoCopy,
  setIsShow,
  isShow,
  setDuplicateShow,
  duplicateAndEdit,
  duplicateUpdate,
  setDuplicateUpdate,
  selectedOptionGradingEditable,
  selectedOptionQualificationTypeEditable,
  selectedOptionInstitutionTypeEditable,
  selectedOptionEditable,
  setLoaderSave,
  setImageValue,
  setIsCancel,
  handleUnitDelete,
  handleAddUnit,
  add_Module,
  setAdd_Module,
  textColor,
  createCourse,
  // handleChangeTextColor,
  // textColorId,
  setDisableSaveBtn,
  disableSaveBtn,
  editExistingCourseFromAllCourses,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [editData, setEditData] = useState(
    duplicateAndEdit ? duplicateUpdate : courseUpdate
  );

  const [deleteModule, setDeleteModule] = useState({ unit: [], module: [] });
  const [imgValues, setImgValues] = useState([]);
  const [imgFiles, setImgFiles] = useState([]);

  const [addUnit, setAddUnit] = useState([]);
  const [textColorId, setTextColorId] = useState([]);

  const navigate = useNavigate();
  let token = localStorage.getItem("TOKEN");
  let decoded;
  if (token) {
    // eslint-disable-next-line
    decoded = jwt_decode(token);
  }
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setCourseUpdate({ ...courseUpdate, [name]: value });
  // };

  // const navigate = useNavigate();

  function dataURLtoBuffer(dataUrl) {
    const base64String = dataUrl.split(",")[1];
    const uint8Array = new Uint8Array(
      atob(base64String)
        .split("")
        .map((char) => char.charCodeAt(0))
    );
    return uint8Array;
  }

  const uploadFileToBlob = async (fileStream) => {
    const delimiter = "base64,";
    const parts = fileStream.split(delimiter);

    const contentType = parts[0].substring(parts[0].indexOf(":") + 1);

    const mimetype = contentType;
    // decoded = dataUriToBuffer(uri);
    const buf = dataURLtoBuffer(fileStream);
    // const lUuid = `invitation-${longUuid()}`;
    const blobName = getBlobName("unit-" + uuid(), mimetype);
    const additionalUploadProps = {
      blobHTTPHeaders: {
        blobContentType: mimetype,
      },
    };
    const link = await uploadFile(
      buf,
      blobName,
      process.env.REACT_APP_AZURE_STORAGE_CONTAINER_NAME,
      additionalUploadProps
    );
    return link;
  };

  const handleSubmit = async () => {
    //add module in particular unit
    let new_Module = [];
    if (add_Module.length > 0) {
      add_Module.map((data) => {
        const unit = addUnit.filter((x) => x.moduleId === data.id);
        new_Module.push({
          ...data,
          unit,
        });
      });
    }
    if (!duplicateAndEdit) {
      if (
        selectedOptionGradingEditable &&
        selectedOptionQualificationTypeEditable &&
        selectedOptionInstitutionTypeEditable &&
        selectedOptionEditable
      ) {
        setCourseUpdate({ ...courseUpdate });
        const uploadImages = [];
        for (let i = 0; i < imgValues.length; i++) {
          const img = imgValues[i];
          const linkUrl = await uploadFileToBlob(img.src);
          uploadImages.push({
            id: img.id,
            src: linkUrl,
          });
        }

        uploadImages.map((data) => {
          const existCourseIndex = courseUpdate?.module?.findIndex((item) => {
            return data.id === item.id;
          });
          if (existCourseIndex === -1) {
            // let existCourseModule;
            courseUpdate?.module?.map((item, i) => {
              const existCourseUnit = item?.unit?.findIndex((item_) => {
                return data?.id === item_?.id;
              });
              if (existCourseUnit !== undefined && existCourseUnit !== -1) {
                courseUpdate.module[i].unit[existCourseUnit].icon = data.src;
              }
            });
          } else {
            courseUpdate.module[existCourseIndex].icon = data.src;
          }
          setCourseUpdate({ ...courseUpdate });
          return data;
        });

        if (add_Module.length > 0) {
          uploadImages.map((data) => {
            const existCourseIndex = add_Module?.findIndex((item) => {
              return data?.id === item?.id;
            });
            const existModule = new_Module?.findIndex((item) => {
              return data?.id === item?.id;
            });
            if (existCourseIndex === -1) {
              new_Module.map((item, i) => {
                const existCourseUnit = item?.unit?.findIndex((item_) => {
                  return data?.id === item_?.id;
                });
                if (existCourseUnit !== undefined && existCourseUnit !== -1) {
                  // add_Module[i].module[existCourseUnit].icon = data.src;
                  new_Module[i].unit[existCourseUnit].icon = data.src;
                  setAdd_Module({ ...add_Module });
                }
              });
            } else {
              add_Module[existCourseIndex].icon = data.src;
              setAdd_Module({ ...add_Module });
              if (existModule === -1) return;
              new_Module[existModule].icon = data.src;
            }
            return data;
          });
        }

        !createCourse && setIsShowInputBox(false);
        const formData = new FormData();
        formData.append("id", courseInfo.id);
        formData.append("gradeId", selectedOptionGradingEditable.id);
        formData.append(
          "qualificationId",
          selectedOptionQualificationTypeEditable.id
        );
        formData.append(
          "instituteTypeId",
          selectedOptionInstitutionTypeEditable.id
        );
        formData.append("instituteId", selectedOptionEditable.id);
        formData.append(
          "noOfModules",
          Number(courseUpdate?.module?.length) + Number(new_Module?.length)
        );
        formData.append("title", courseUpdate.title);
        formData.append("image", courseUpdate.icon);
        formData.append("color", courseUpdate.color);
        formData.append("isPublic", courseUpdate.isPublic);
        formData.append(
          "workExperienceHours",
          Number(courseUpdate.workExperienceHours)
        );
        formData.append("module", JSON.stringify(courseUpdate.module));
        formData.append("newModule", JSON.stringify(new_Module));
        formData.append("deleteModule", JSON.stringify(deleteModule));

        // dispatch(
        //   actions.updateCourse(
        //     { id: courseInfo.id, ...courseUpdate },
        //     setIsShow
        //   )
        // );
        setLoaderSave && setLoaderSave(true);
        dispatch(loaderActions.startLoader());
        dispatch(actions.updateCourse(formData, setIsShow));
        // setIsShow(true);
        // navigate(-1);
      } else {
        Toast("error", "Please select proper information!");
      }
    } else {
      if (
        selectedOptionGradingEditable &&
        selectedOptionQualificationTypeEditable &&
        selectedOptionInstitutionTypeEditable &&
        selectedOptionEditable
      ) {
        if (duplicateUpdate?.icon.includes("base64")) {
          const linkUrl = await uploadFileToBlob(duplicateUpdate.icon);
          duplicateUpdate.icon = linkUrl;
        }
        const uploadImages = [];
        for (let i = 0; i < imgValues.length; i++) {
          const img = imgValues[i];
          const linkUrl = await uploadFileToBlob(img.src);
          uploadImages.push({
            id: img.id,
            src: linkUrl,
          });
        }

        uploadImages.map((data) => {
          const existCourseIndex = duplicateUpdate?.module?.findIndex(
            (item) => {
              return data?.id === item?.id;
            }
          );
          if (existCourseIndex === -1) {
            duplicateUpdate?.module?.map((item, i) => {
              const existCourseModule = item?.unit?.findIndex((item_) => {
                return data?.id === item_?.id;
              });
              if (existCourseModule !== undefined && existCourseModule !== -1) {
                duplicateUpdate.module[i].unit[existCourseModule].icon =
                  data.src;
              }
            });
          } else {
            duplicateUpdate.module[existCourseIndex].icon = data.src;
          }

          setDuplicateUpdate({ ...duplicateUpdate });
          return data;
        });

        if (add_Module.length > 0) {
          uploadImages?.map((data) => {
            const existCourseIndex = add_Module?.findIndex((item) => {
              return data?.id === item?.id;
            });
            const existModule = new_Module?.findIndex((item) => {
              return data?.id === item?.id;
            });
            if (existCourseIndex === -1) {
              new_Module?.map((item, i) => {
                const existCourseUnit = item?.unit?.findIndex((item_) => {
                  return data?.id === item_?.id;
                });
                if (existCourseUnit !== undefined && existCourseUnit !== -1) {
                  // add_Module[i].module[existCourseUnit].icon = data.src;
                  new_Module[i].unit[existCourseUnit].icon = data.src;
                  setAdd_Module({ ...add_Module });
                }
              });
            } else {
              add_Module[existCourseIndex].icon = data.src;
              setAdd_Module({ ...add_Module });
              if (existModule === -1) return;
              new_Module[existModule].icon = data.src;
            }
            return data;
          });
        }

        setLoaderSave(true);

        dispatch(loaderActions.startLoader());
        dispatch(
          actions.createCourse(
            { ...duplicateUpdate, module: [...module, ...new_Module] },
            setIsShow
          )
        );
      } else {
        Toast("error", "Please select proper information!");
      }
    }

    setAdd_Module();
  };

  const handleCancel = () => {
    !duplicateAndEdit &&
      setCourseUpdate({
        ...courseUpdate,
        title: courseInfo?.title ? courseInfo?.title : "",
        icon: courseInfo?.icon ? courseInfo?.icon : "",
        // unit: courseInfo?.unit ? courseInfo?.unit : "",
        // unit: courseInfoCopy ? courseInfoCopy : "",
        module: localStorage.getItem("module")
          ? JSON.parse(localStorage.getItem("module"))
          : [],
      });
    duplicateAndEdit &&
      setDuplicateUpdate({
        ...duplicateUpdate,
        title: courseInfo?.title ? courseInfo?.title : "",
        icon: courseInfo?.icon ? courseInfo?.icon : "",
        // unit: courseInfo?.unit ? courseInfo?.unit : "",
        // unit: courseInfoCopy ? courseInfoCopy : "",
        module: localStorage.getItem("module")
          ? JSON.parse(localStorage.getItem("module"))
          : [],
      });
    setIsCancel && setIsCancel(true);
    setImgValues([]);
    setImageValue("");
    setIsShow(true);

    // if (decoded?.role === "admin" && !params.id) {
    // }
    // setIsShowInputBox(false);
    if (editExistingCourseFromAllCourses) {
      dispatch(actions.addToPreviousFilterCorse({ ...location.state }));
      navigate(-1);
    }
  };

  // console.log("duplicateAndEdit", duplicateAndEdit);
  // console.log("duplicateUpdate", duplicateUpdate);
  // console.log("courseUpdate", courseUpdate);
  // console.log("editData", editData);

  useEffect(() => {
    setEditData(duplicateAndEdit ? duplicateUpdate : courseUpdate);
  }, [courseUpdate, duplicateUpdate, duplicateAndEdit]);

  function importData(id) {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (_) => {
      let files = Array.from(input.files);
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target.result;
        let existImgIndex = imgValues.findIndex((data) => {
          return data.id === id;
        });
        let existImgFileIndex = imgFiles.findIndex((data) => {
          return data.id === id;
        });
        if (existImgIndex !== -1) {
          imgValues[existImgIndex].src = result;
          setImgValues([...imgValues]);
          imgFiles[existImgFileIndex].file = file;
          setImgFiles([...imgFiles]);
        } else {
          setImgFiles([...imgFiles, { id: id, file: file }]);
          setImgValues([...imgValues, { id: id, src: result }]);
        }
      };
      setDisableSaveBtn(true);

      reader.readAsDataURL(file);
    };
    input.click();
  }

  const handleAddModule = (data) => {
    const existAddModule = add_Module?.find((e) => e.id === data.moduleId);

    addUnit[existAddModule] = add_Module?.find((e) => e.id === data.moduleId);

    setAddUnit([...addUnit, data]);
  };

  const handleModuleDelete = (id) => {
    const GradingIndex = addUnit?.filter((a) => a.id !== id);
    setAddUnit(GradingIndex);

    if (duplicateAndEdit === true) {
      const existModule = editData.module?.findIndex(
        (e) => e.id === id.moduleId
      );

      duplicateUpdate.module[existModule].unit = duplicateUpdate.module[
        existModule
      ]?.unit?.filter((item) => item.id !== id.id);

      setDuplicateUpdate({
        ...duplicateUpdate,
      });
    } else {
      const existModule = editData.module?.findIndex(
        (e) => e.id === id.moduleId
      );
      courseUpdate.module[existModule].unit = courseUpdate.module[
        existModule
      ]?.unit?.filter((item) => item.id !== id.id);

      setCourseUpdate({
        ...courseUpdate,
      });
    }
  };

  const handleAddDuplicateModule = (data) => {
    const existModule = editData.module?.find((e) => e.id === data.moduleId);
    if (!existModule?.unit) {
      existModule.unit = [];
    }
    existModule?.unit?.push(data);
    setEditData({ ...editData });
  };

  const handleChangeTextColor = (id) => {
    if (!textColorId.includes(id)) {
      setTextColorId([...textColorId, id]);
    }
  };
  return (
    <>
      <div className="d-flex flex-wrap gap-4 UnitInfo">
        {editData &&
          editData?.module?.length > 0 &&
          editData?.module?.map((item, index) => {
            const existUnitIcon = imgValues.find((data) => data.id === item.id);
            return (
              <>
                <div key={item.id}>
                  <div className="d-flex justify-content-between UnitInfoIcon">
                    <div className="d-flex CourseIn">
                      <p className="Course_Title">{"Module " + (index + 1)}</p>
                      {!isShowInputBox ? (
                        <>
                          <p className="Title_Name">
                            {/* {item?.name ? item?.name : ""} */}

                            {courseUpdate?.unitName
                              ? courseUpdate?.unitName
                              : item?.name}
                          </p>
                        </>
                      ) : (
                        <>
                          <input
                            name="unitName"
                            type="text"
                            // className="UnitInfoInput"
                            className={`UnitInfoInput ${
                              index + 1 >= 1 && index + 1 <= 9
                                ? "add-width"
                                : ""
                            }`}
                            value={item?.name}
                            style={{
                              color: textColorId.find((data) => {
                                return data === item?.id;
                              })
                                ? "#000"
                                : "gray",
                            }}
                            onKeyDown={() => {
                              handleChangeTextColor(item.id);
                            }}
                            // value={courseUpdate?.unitName}
                            onChange={(e) => {
                              const existIndex =
                                courseUpdate.module &&
                                courseUpdate.module.findIndex(
                                  (a) => a.id === item.id
                                );

                              if (duplicateAndEdit === true) {
                                const dupExistIndex =
                                  duplicateUpdate?.module.findIndex(
                                    (a) => a.id === item.id
                                  );
                                duplicateUpdate.module[dupExistIndex].name =
                                  e.target.value;

                                setDuplicateUpdate({ ...duplicateUpdate });
                                setDisableSaveBtn(true);
                              } else {
                                courseUpdate.module[existIndex].name =
                                  e.target.value;

                                setCourseUpdate({ ...courseUpdate });
                                setDisableSaveBtn(true);
                              }
                            }}
                          />
                        </>
                      )}
                    </div>
                    {isShowInputBox && (
                      <div
                        className="d-flex unitUserIcon"
                        // onClick={UnitUploadImg}
                        onClick={() => importData(item.id)}>
                        <p className="icon mr-2">+ icon</p>
                        {existUnitIcon || item.icon ? (
                          <img
                            src={
                              existUnitIcon?.src
                                ? existUnitIcon?.src
                                : item.icon
                                ? item.icon
                                : ""
                            }
                            alt="rest"
                            className="IconImg"
                          />
                        ) : (
                          <FeatherIcon
                            className="userIcon"
                            icon="user"
                            size={18}
                            strokeWidth={2}
                          />
                        )}

                        {/* <input
                        className="FileUpload"
                        ref={imgRef}
                        type="file"
                        name="uploadIcon"
                        accept="image/*"
                        onChange={(e) => {
                          handleUnitImportImg(e, item.id);
                        }}
                        
                      /> */}
                      </div>
                    )}
                    {/* particular unit delete  */}
                    {isShowInputBox && (
                      <p
                        className="Course_Title text-danger cursor-pointer deleteBtn"
                        onClick={() => {
                          setDeleteModule({
                            ...deleteModule,
                            module: [...deleteModule?.module, item],
                          });
                          handleUnitDelete(item.id);
                          setDisableSaveBtn(true);
                        }}>
                        Delete
                      </p>
                    )}
                  </div>
                  <hr className="Unit_Line" />

                  {isShowInputBox && (
                    <p
                      className="Course_Title cursor-pointer"
                      // href="# "
                      onClick={() =>
                        handleAddDuplicateModule({
                          id: uuid(),
                          name: "",
                          moduleId: item.id,
                        })
                      }>
                      + Add Unit
                    </p>
                  )}

                  {item?.unit?.map((data, i) => {
                    const existModuleIcon = imgValues.find(
                      (data_) => data_.id === data.id
                    );

                    return (
                      <div key={data.id}>
                        <div className="d-flex mt-3 mb-3 CourseIn">
                          <p className="Course_Title">{"Unit " + (i + 1)}</p>

                          {!isShowInputBox ? (
                            <>
                              <p className="Title_Name">
                                {courseUpdate?.moduleName
                                  ? courseUpdate?.moduleName
                                  : data?.name}
                              </p>
                            </>
                          ) : (
                            <>
                              <input
                                name="moduleName"
                                type="text"
                                className="ModuleInfoInput"
                                value={data?.name}
                                // value={courseUpdate?.moduleName}
                                style={{
                                  color: textColorId.find((i) => {
                                    return i === data?.id;
                                  })
                                    ? "#000"
                                    : "gray",
                                }}
                                onKeyDown={() => {
                                  handleChangeTextColor(data.id);
                                }}
                                onChange={(e) => {
                                  if (duplicateAndEdit === true) {
                                    const dupExistIndex = item?.unit?.findIndex(
                                      (a) => a.id === data.id
                                    );

                                    item.unit[dupExistIndex].name =
                                      e.target.value;
                                    setDuplicateUpdate({ ...duplicateUpdate });

                                    setDisableSaveBtn(true);
                                  } else {
                                    const existIndex = item?.unit?.findIndex(
                                      (a) => a.id === data.id
                                    );

                                    item.unit[existIndex].name = e.target.value;

                                    setCourseUpdate({ ...courseUpdate });
                                    setDisableSaveBtn(true);
                                  }
                                }}
                              />

                              {isShowInputBox && (
                                <div
                                  className="d-flex unitUserIcon"
                                  // onClick={UnitUploadImg}
                                  onClick={() => importData(data.id)}>
                                  <p className="icon mr-2">+ icon </p>
                                  {existModuleIcon || data.icon ? (
                                    <img
                                      src={
                                        existModuleIcon?.src
                                          ? existModuleIcon?.src
                                          : data.icon
                                          ? data.icon
                                          : ""
                                      }
                                      alt="rest"
                                      className="IconImg"
                                    />
                                  ) : (
                                    <FeatherIcon
                                      className="userIcon"
                                      icon="user"
                                      size={18}
                                      strokeWidth={2}
                                    />
                                  )}

                                  {/* <input
                        className="FileUpload"
                        ref={imgRef}
                        type="file"
                        name="uploadIcon"
                        accept="image/*"
                        onChange={(e) => {
                          handleUnitImportImg(e, item.id);
                        }}
                        
                      /> */}
                                </div>
                              )}
                              <p
                                className="Course_Title text-danger cursor-pointer deleteBtn"
                                onClick={() => {
                                  setDeleteModule({
                                    ...deleteModule,
                                    unit: [...deleteModule?.unit, data],
                                  });
                                  handleModuleDelete(data);
                                  setDisableSaveBtn(true);
                                }}>
                                Delete
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            );
          })}
        {/* add multiple module from here  */}
        {add_Module?.length > 0 &&
          add_Module?.map((item, i) => {
            const unit = addUnit.filter((x) => x.moduleId === item.id);
            const existUnitIcon = imgValues.find((data) => data.id === item.id);
            return (
              <div key={item?.id}>
                <div className="d-flex  UnitInfoIcon">
                  <div className="d-flex CourseIn">
                    <p
                      className="Course_Title"
                      style={{ height: "fit-content" }}>
                      {`Module ${editData?.module?.length + i + 1}`}
                    </p>

                    <input
                      name="unitName"
                      type="text"
                      className="UnitInfoInput"
                      // style={{ height: "fit-content" }}
                      value={item?.name}
                      style={{
                        color: textColorId.find((data) => {
                          return data === item?.id;
                        })
                          ? "#000"
                          : "gray",
                        height: "fit-content",
                      }}
                      onKeyDown={() => {
                        handleChangeTextColor(item.id);
                      }}
                      onChange={(e) => {
                        const existIndex =
                          add_Module &&
                          add_Module.findIndex((a) => a.id === item.id);
                        add_Module[existIndex].name = e.target.value;
                        setAdd_Module([...add_Module]);
                        setDisableSaveBtn(true);
                      }}
                    />
                  </div>

                  <div className="d-flex unitUserIcon">
                    <div
                      className="d-flex uploadImg"
                      onClick={() => importData(item.id)}>
                      <p className="icon mr-2">+ icon</p>
                      {existUnitIcon || item.icon ? (
                        <img
                          src={
                            existUnitIcon?.src
                              ? existUnitIcon?.src
                              : item.icon
                              ? item.icon
                              : ""
                          }
                          alt="rest"
                          className="IconImg"
                        />
                      ) : (
                        <FeatherIcon
                          className="userIcon"
                          icon="user"
                          size={18}
                          strokeWidth={2}
                        />
                      )}
                    </div>
                    <p
                      className="Course_Title text-danger cursor-pointer deleteBtn"
                      onClick={() => handleUnitDelete(item.id)}>
                      Delete
                    </p>
                  </div>
                </div>
                <hr className="Unit_Line" />
                <div>
                  <div className="d-flex mb-3 CourseIn">
                    {/* <p className="Course_Title">+ Add Module</p> */}
                    <p
                      className="Course_Title cursor-pointer"
                      onClick={() =>
                        handleAddModule({
                          id: uuid(),
                          name: "",
                          moduleId: item.id,
                        })
                      }>
                      + Add Unit
                    </p>
                  </div>
                  {unit?.map((item, i) => {
                    const existModuleIcon = imgValues.find(
                      (data) => data.id === item.id
                    );

                    return (
                      <>
                        <div className="d-flex mb-3 CourseIn">
                          <p className="Course_Title">{`Unit ${i + 1}`}</p>
                          <input
                            name="moduleName"
                            type="text"
                            className="ModuleInfoInput"
                            value={item.name}
                            style={{
                              color: textColorId.find((data) => {
                                return data === item?.id;
                              })
                                ? "#000"
                                : "gray",
                            }}
                            onKeyDown={() => {
                              handleChangeTextColor(item.id);
                            }}
                            onChange={(e) => {
                              const existIndex =
                                addUnit &&
                                addUnit.findIndex((a) => a.id === item.id);
                              addUnit[existIndex].name = e.target.value;
                              setAddUnit([...addUnit]);
                              setDisableSaveBtn(true);
                            }}
                          />
                          <div className="d-flex unitUserIcon">
                            <div
                              className="d-flex uploadImg"
                              onClick={() => importData(item.id)}>
                              <p className="icon mr-2">+ icon</p>
                              {existModuleIcon || item.icon ? (
                                <img
                                  src={
                                    existModuleIcon?.src
                                      ? existModuleIcon?.src
                                      : item.icon
                                      ? item.icon
                                      : ""
                                  }
                                  alt="rest"
                                  className="IconImg"
                                />
                              ) : (
                                <FeatherIcon
                                  className="userIcon"
                                  icon="user"
                                  size={18}
                                  strokeWidth={2}
                                />
                              )}
                            </div>
                            <p
                              className="Course_Title text-danger cursor-pointer"
                              onClick={() => handleModuleDelete(item.id)}>
                              Delete
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            );
          })}
        {(duplicateAndEdit || isShowInputBox) && (
          <button
            className="unitAddBtn"
            onClick={() => {
              handleAddUnit({
                id: uuid(),
                name: "",
                icon: "",
              });
            }}>
            + Add Module
          </button>
        )}
      </div>
      <hr className="Last_border" />
      {isShowInputBox && (
        <div className="d-flex justify-content-end unitBtn pb-4">
          <button className="UnitCancelBtn" onClick={handleCancel}>
            Cancel
          </button>

          <button
            className={`UnitSaveBtn ${
              disableSaveBtn === false ? "disabled" : ""
            }`}
            onClick={handleSubmit}
            disabled={disableSaveBtn === false ? !disableSaveBtn : ""}>
            Save
          </button>
        </div>
      )}
    </>
  );
};

export default UnitInfo;
