export const actions = {
  GET_MESSAGES_REQUEST: "GET_MESSAGES_REQUEST",
  GET_MESSAGES_SUCCESS: "GET_MESSAGES_SUCCESS",

  SEND_MESSAGE_REQUEST: "SEND_MESSAGE_REQUEST",
  SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",

  getMessages: (data) => {
    return {
      type: actions.GET_MESSAGES_REQUEST,
      data,
    };
  },

  sendMessage: (data) => {
    return {
      type: actions.SEND_MESSAGE_REQUEST,
      data,
    };
  },
};
