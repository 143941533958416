import React from "react";
import CustomLogin from "components/CustomLogin";

function Login() {
  return (
    <>
      <CustomLogin />
    </>
  );
}

export default Login;
