import Input from "components/Input";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useState } from "react";
import { uuid } from "utils/uuid";
import "./ProfileFieldType.css";

const ProfileFieldType = ({
  oldNumberOfOption,
  setOldNumberOfOption,
  options,
  setOptions,
}) => {
  const [isShowNumberOfType, setIsShowNumberOfType] = useState(false);
  const [isShowInputField, setIsShowInputField] = useState({
    id: "",
    isShow: false,
  });
  const [numberOfOption, setNumberOfOption] = useState({
    numberOfOption: "",
  });
  // const [oldNumberOfOption, setOldNumberOfOption] = useState(0);

  // const [options, setOptions] = useState([]);
  const [oldOptions, setOldOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState({ value: "" });
  const [disableGoButton, setDisableGoButton] = useState(false);
  const [error, setError] = useState({
    message: "",
    name: "",
  });
  // const { options, setOptions, oldNumberOfOption, setOldNumberOfOption } =
  //   useContext(ProfileContext);

  const handleChangeInput = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setNumberOfOption((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
      setError({ message: "" });
    }
  };

  const handleChangeOptions = (event) => {
    setTypeOptions((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleClickGoButton = (data) => {
    if (data.numberOfOption) {
      setIsShowNumberOfType(true);
      setOldNumberOfOption(Number(data.numberOfOption) + oldNumberOfOption);
      setDisableGoButton(true);
      // setOldNumberOfOption(
      //   Number(numberOfOption.numberOfOption) + Number(data.numberOfOption)
      // );
    } else {
      setError({
        message: "Please enter number of options",
        name: "numberOfOption",
      });
    }
  };

  let options_ = [];
  const handleClickAddButton = (data) => {
    if (data.value) {
      options_.push(data);
      options.push(data);
      setOptions(options);
      setOldOptions([...oldOptions, structuredClone(data)]);
      setTypeOptions({});
      if (options.length >= Number(oldNumberOfOption)) {
        setIsShowNumberOfType(false);
      }
    } else {
      setError({
        message: "Please enter option value",
        name: "value",
      });
    }
  };

  const handleEdit = (id) => {
    setIsShowInputField({ id: id, isShow: true });
  };

  const handleRemove = (id) => {
    const optionIndex = options?.filter((a) => a.id !== id);
    setOptions(optionIndex);
  };

  return (
    <>
      <div>
        <div className="mt-4 poppins">
          <span className="fs-5">How many options do you want to add?</span>
        </div>
        <div className="d-flex mt-2 gap-2 profileFieldType">
          <>
            <Input
              type="text"
              id="numberOfOptionInputField"
              className={`profileField-inputField px-2 ${
                disableGoButton === true ? "disable" : ""
              } `}
              name="numberOfOption"
              divStyle={{ fontSize: "18px", width: "19%" }}
              value={numberOfOption?.numberOfOption}
              onChange={handleChangeInput}
              divClass="poppins numberOfOptionInputField"
            />
            <div className="numberOfOptionButtons">
              <button
                type="button"
                // className="btn user-profile-saveBtn"
                id="numberOfOptionGoBtn"
                className={`btn profile-field-goBtn mx-2 ${
                  disableGoButton === true ? "go-btn-disable" : ""
                }`}
                onClick={() =>
                  handleClickGoButton({
                    id: uuid(),
                    numberOfOption: numberOfOption?.numberOfOption,
                  })
                }
                disabled={disableGoButton ? true : false}
              >
                Go
              </button>

              {disableGoButton === true && (
                <button
                  type="button"
                  className={`btn user-profile-editBtn`}
                  onClick={() => setDisableGoButton(false)}
                >
                  Edit
                </button>
              )}
            </div>
          </>
        </div>
        {error?.name === "numberOfOption" && error.message && (
          <p
            id="login_validation"
            style={{ fontSize: "small" }}
            className="text-danger mt-1"
          >
            {error.message}
          </p>
        )}
        <div className="d-flex gap-3 flex-wrap profileField-options my-3 ">
          {options?.map((item) => {
            return (
              <div className="poppins profileField-badge">
                <p className="badge profile-option-label d-flex align-item-center ">
                  {item?.id !== isShowInputField?.id && item?.value}
                  {isShowInputField.isShow === true &&
                    item?.id === isShowInputField?.id && (
                      <input
                        type="text"
                        name="value"
                        value={item?.value}
                        className="profile-field-input"
                        onChange={(e) => {
                          const optionIndex = options?.findIndex(
                            (a) => a.id === item.id
                          );
                          options[optionIndex].value = e.target.value;
                          setOptions([...options]);
                          // setOldOptions([...options]);
                          setError({ message: "" });
                        }}
                      />
                    )}

                  {isShowInputField.isShow === true &&
                    item?.id === isShowInputField?.id && (
                      <>
                        <div className="checkAndCloseIcon">
                          <FeatherIcon
                            className={`profile-field-EditIcon`}
                            icon={`check`}
                            size={17}
                            stroke-width={3}
                            // onClick={() => setIsShowInputField(true)}
                            onClick={() => {
                              if (item?.value) {
                                setIsShowInputField(true);
                              } else {
                                setError({
                                  id: item.id,
                                  message: "Please enter option value",
                                });
                              }
                            }}
                          />
                          <FeatherIcon
                            className={`profile-field-remove`}
                            icon={`x`}
                            size={17}
                            onClick={(e) => {
                              const existOption = oldOptions?.find(
                                (a) => a.id === item.id
                              );
                              const optionIndex = options?.findIndex(
                                (a) => a.id === item.id
                              );
                              options[optionIndex].value = existOption.value;
                              setOptions([...options]);
                              setError({ message: "" });
                              setIsShowInputField({ id: "", isShow: false });
                            }}
                            // onClick={() => {
                            //   if (item?.value) {
                            //     setIsShowInputField(false);
                            //   }
                            // }}
                          />
                        </div>
                      </>
                    )}

                  {item?.id !== isShowInputField?.id && (
                    <>
                      <div>
                        <FeatherIcon
                          className={`profile-EditIcon`}
                          icon={`edit-3`}
                          size={17}
                          // onClick={() => setIsShowInputField(true)}
                          onClick={() => handleEdit(item.id)}
                        />
                        <FeatherIcon
                          className={`profile-remove`}
                          icon={`trash-2`}
                          size={17}
                          onClick={() => handleRemove(item.id)}
                        />
                      </div>
                    </>
                  )}
                </p>
                {error.id === item.id && error.message && (
                  <p
                    id="login_validation"
                    style={{ fontSize: "small", fontWeight: "600" }}
                    className="text-danger"
                  >
                    {error.message}
                  </p>
                )}
              </div>
            );
          })}
        </div>

        {isShowNumberOfType && (
          <>
            <div className="mt-4 poppins">
              <span className="fs-5">{"option"}</span>
            </div>
            <div className="d-flex mt-2 gap-3">
              <Input
                type="text"
                className={`profileField-inputField px-2 `}
                name="value"
                divStyle={{ fontSize: "18px", width: "19%" }}
                value={typeOptions?.value}
                onChange={handleChangeOptions}
                divClass="poppins numberOfOptionInputField"
              />

              <div>
                <button
                  type="button"
                  className="btn user-profile-AddBtn "
                  onClick={() =>
                    handleClickAddButton({
                      id: uuid(),
                      value: typeOptions?.value,
                    })
                  }
                  // onClick={() =>
                  //   handleClickGoButton({
                  //     id: uuid(),
                  //     numberOfOption: "",
                  //   })
                  // }
                >
                  Add
                </button>
              </div>
            </div>
            {error?.name === "value" && error.message && (
              <p
                id="login_validation"
                style={{ fontSize: "small" }}
                className="text-danger mt-1"
              >
                {error.message}
              </p>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ProfileFieldType;
