import setToken from "utils/setToken";
import { actions } from "./action";

const initialState = {
  player: null,
  players: [],
  club: [],
  allPlayers: [],
  playerStatus: [],
  playerCategory: [],
  existPlayers: [],
  errors: [],
  newPlayers: [],
  archivedPlayers: [],
  coursesOfUser: {
    userId: "",
    courses: [],
  },
  error: false,
};

const playerReducer = (state = initialState, action) => {
  const token = localStorage.getItem("TOKEN");
  if (token) {
    setToken(token);
  }
  switch (action.type) {
    case actions.GET_PLAYERS_SUCCESS:
      return {
        ...state,
        players: action.payload,
      };
    case actions.CREATE_PLAYERS_SUCCESS:
      return {
        ...state,
        players: [...state.players, ...action.payload.user],
        existPlayers: action.payload.existUsers,
        errors: action.payload.errors,
        newPlayers: action.payload.user,
      };
    case actions.UPDATE_PLAYER_SUCCESS:
      return {
        ...state,
        player: action.payload,
      };

    case actions.GET_CLUB_SUCCESS:
      return {
        ...state,
        club: action.payload,
      };

    case actions.GET_ALL_PLAYERS_SUCCESS:
      return {
        ...state,
        // allPlayers: [...action.payload],
        allPlayers: action.payload.filter((player) => {
          return !player?.isArchived;
        }),
        // archivedPlayers: action.payload.filter((player) => {
        //   return player?.isArchived;
        // }),
      };

    case actions.GET_ARCHIVED_PLAYERS_SUCCESS:
      return {
        ...state,
        // allPlayers: [...action.payload],
        archivedPlayers: [...action.payload],
      };

    case actions.GET_PLAYER_STATUS_SUCCESS:
      return {
        ...state,
        playerStatus: action.payload,
      };

    case actions.GET_PLAYER_CATEGORY_SUCCESS:
      return {
        ...state,
        playerCategory: action.payload,
      };

    case actions.REMOVE_PLAYER_SUCCESS:
      // let tutors = state.tutors.filter((tutor) => {
      //   return tutor.id !== action.payload.id && tutor;
      // });
      // tutors.push(action.payload);
      return {
        ...state,
        player: action.payload,
        // allPlayers: state.allPlayers.filter((player) => {
        //   return player?.id !== action?.payload?.id;
        // }),
        archivedPlayers: [action.payload, ...state.archivedPlayers],
        // .sort(
        //   (a, b) => a?.name.localeCompare(b?.name)
        // ),
      };

    case actions.UNREMOVE_PLAYER_SUCCESS:
      return {
        ...state,
        player: action.payload,
        allPlayers: [action.payload, ...state.allPlayers],
        // .sort((a, b) =>
        //   a?.name.localeCompare(b?.name)
        // ),
        archivedPlayers: state.archivedPlayers.filter((player) => {
          return player?.id !== action?.payload?.id;
        }),
      };

    case actions.DELETE_PLAYER_SUCCESS:
      return {
        ...state,
        // tutors: [...state.tutors, ...action.payload].sort((a, b) =>
        //   a.name.localeCompare(b.name)
        // ),
        // tutors: [resDataClubAdmin, ...sortDataAll],
        archivedPlayers: state.archivedPlayers.filter((player) => {
          return player?.id !== action?.payload;
        }),
      };
    case actions.ADD_TRANSFERABLE_SUCCESS:
      const isIndex = state.players.findIndex(
        (player) => player.id === action.payload.userId
      );
      state.players[isIndex].transferableSkills.transferableSkills =
        action.payload.transferableSkills;
      return {
        ...state,
        players: state.players,
      };

    case actions.GET_PLAYER_COURSES_SUCCESS:
      return {
        ...state,
        coursesOfUser: action.payload,
      };

    case actions.CATCH_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default playerReducer;
