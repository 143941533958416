import book from "assets/images/book.png";
import home from "assets/images/home.png";
import university from "assets/images/university.png";
import userIcon from "assets/images/user.png";
import jwt_decode from "jwt-decode";
import { Sidebar } from "primereact/sidebar";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import "./NavSidebar.css";

const NavSidebar = ({ visible, setVisible }) => {
  const token = localStorage.getItem("TOKEN");
  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const params = useParams();
  let decoded;
  if (token) {
    decoded = jwt_decode(token);
  }
  return (
    <>
      <div>
        <Sidebar
          visible={visible}
          onHide={() => setVisible(false)}
          className="navSidebar"
        >
          <div
            className={`p-2 ${
              location.pathname === "/" ? "navSidebarTabActive" : ""
            }`}
          >
            {(user?.role === "admin" || decoded?.role === "admin") && (
              <Link
                to={`/`}
                className={`text-black poppins fs-5 navSidebarTabText`}
              >
                <img src={home} alt="" className="navbarIconImg mr-3" />
                Home
              </Link>
            )}
          </div>
          <hr className="navbar-line" />
          <div
            className={`${
              (user?.role === "admin" || decoded?.role === "admin") &&
              !params?.id
                ? "p-2"
                : "p-0"
            } ${
              location?.pathname === "/players" ? "navSidebarTabActive" : ""
            }`}
          >
            {(user?.role === "admin" || decoded?.role === "admin") &&
              !params?.id && (
                <Link
                  to="/players"
                  // className="text-white bebas allPlayersNav ms-4 active"
                  className={`text-black poppins fs-5 navSidebarTabText`}
                >
                  <img src={userIcon} alt="" className="navbarIconImg mr-3" />
                  Users
                </Link>
              )}
          </div>

          {(user?.role === "admin" || decoded?.role === "admin") &&
            !params?.id && <hr className="navbar-line" />}

          <div
            className={`p-2 ${
              location?.pathname === "/courses" ? "navSidebarTabActive" : ""
            } `}
          >
            {(user?.role === "admin" || decoded?.role === "admin") && (
              <Link
                to="/courses"
                // className="text-white bebas allPlayersNav "
                className={`text-black poppins fs-5 navSidebarTabText`}
              >
                <img src={university} alt="" className="navbarIconImg mr-3" />
                Organisations
              </Link>
            )}
          </div>
          <hr className="navbar-line" />
          <div
            className={`p-2 ${
              location?.pathname === "/all-courses" ? "navSidebarTabActive" : ""
            }`}
          >
            {(user?.role === "admin" || decoded?.role === "admin") && (
              <Link
                to="/all-courses"
                // className="text-white bebas allPlayersNav "
                className={`text-black poppins fs-5 navSidebarTabText`}
              >
                <img src={book} alt="" className="navbarIconImg mr-3" />
                Courses
              </Link>
            )}
          </div>

          <hr className="navbar-line" />

          <div
            className={`p-2  ${
              location?.pathname.includes("/club/info/")
                ? "navSidebarTabActive"
                : ""
            }`}
          >
            {(user?.role === "admin" || decoded?.role === "admin") &&
              params?.id && (
                <Link
                  to={`${
                    user?.role === "admin" || decoded?.role === "admin"
                      ? "/club/info/" + params?.id
                      : "/club-admin"
                  }`}
                  className={`text-black poppins fs-5 navSidebarTabText`}
                >
                  <img src={userIcon} alt="" className="navbarIconImg mr-3" />
                  Mentors
                </Link>
              )}
          </div>
          {(user?.role === "admin" || decoded?.role === "admin") &&
            params?.id && <hr className="navbar-line" />}
        </Sidebar>
      </div>
    </>
  );
};

export default NavSidebar;
