import React, { useEffect, useState } from "react";
import Input from "components/Input";
import Label from "components/Label";
import Textarea from "components/Textarea";
import { InputSwitch } from "primereact/inputswitch";
import "./TransferableSkills.css";
import FeatherIcon from "feather-icons-react";

const TransferableUnit = (props) => {
  const { label, name, id, contentEditable, handleCallBack, value, onDelete } =
    props;
  const [range, setRange] = useState(value?.point);
  const [isRename, setIsRename] = useState(false);
  const [rangeBackground, setRangeBackground] = useState(
    "linear-gradient(to right, #006b00 0%, #006b00 " +
      ((range - 1) / (10 - 1)) * 100 +
      "%, #fff " +
      ((range - 1) / (10 - 1)) * 100 +
      "%, white 100%)"
  );
  const [transferableObj, setTransferableObj] = useState(value);
  const handleChange = (event) => {
    setTransferableObj((prevState) => ({
      ...transferableObj,
      [event.target.name]: event.target.value,
    }));
  };

  const handleInput = (e) => {
    var value =
      ((e.target.value - e.target.min) / (e.target.max - e.target.min)) * 100;
    setRangeBackground(
      "linear-gradient(to right, #006b00 0%, #006b00 " +
        value +
        "%, #fff " +
        value +
        "%, white 100%)"
    );
    setRange(e.target.value);
  };
  transferableObj && handleCallBack(id, transferableObj);

  useEffect(() => {
    if (transferableObj !== value) {
      setTransferableObj(value);
      setRange(value?.point);
      setRangeBackground(
        "linear-gradient(to right, #006b00 0%, #006b00 " +
          ((value?.point - 1) / (10 - 1)) * 100 +
          "%, #fff " +
          ((value?.point - 1) / (10 - 1)) * 100 +
          "%, white 100%)"
      );
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <>
      <div className="transferableUnit">
        <div className="transferableLabelDiv">
          {!isRename ? (
            <>
              <Label
                className="transferableLabel"
                htmlFor={name}
                title={transferableObj?.name}
              />
              {contentEditable && (
                <>
                  <div
                    className="transferableEditIcon"
                    onClick={() => {
                      setIsRename(true);
                    }}>
                    <FeatherIcon
                      icon="edit-3"
                      size={20}
                      className="cursor-pointer"
                    />
                  </div>
                  <div
                    onClick={() => {
                      onDelete(id);
                    }}>
                    <FeatherIcon
                      icon="trash-2"
                      size={20}
                      className="cursor-pointer"
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <Input
                type="text"
                className="inputField px-2"
                id="title"
                name="name"
                divStyle={{ fontSize: "18px", margin: "0px" }}
                value={transferableObj?.name}
                onChange={(e) => handleChange(e)}
              />
              <div
                onClick={() => {
                  setIsRename(false);
                }}>
                <FeatherIcon
                  icon="check"
                  size={20}
                  className="cursor-pointer"
                />
              </div>
              <div
                onClick={() => {
                  setIsRename(false);
                }}>
                <FeatherIcon icon="x" size={20} className="cursor-pointer" />
              </div>
            </>
          )}
        </div>

        {contentEditable && (
          <Input
            type="range"
            className="slider"
            id={id}
            // name={name}
            name="point"
            min="1"
            max="10"
            oninput="this.nextElementSibling.value = this.value"
            onInput={handleInput}
            style={{ background: rangeBackground }}
            value={transferableObj?.point}
            onChange={handleChange}
            divclassName="rangeSection"
            divStyle={{ display: "inline-block", marginRight: "6px" }}
          />
        )}
        <output id={`${id}Val`}>{range}</output>
        <div>
          <InputSwitch
            checked={transferableObj?.isEnable}
            onChange={(e) =>
              setTransferableObj({ ...transferableObj, isEnable: e.value })
            }
            // style={{ height: "20px" }}
            disabled={contentEditable ? false : true}
          />
        </div>
        <Textarea
          id={`${id}Comment`}
          name="comment"
          className={`textareaBox ${contentEditable ? "edit" : ""}`}
          rows="4"
          cols="50"
          placeholder={`Comment about ${name} here...`}
          disabled={contentEditable ? false : true}
          value={transferableObj?.comment}
          handleChange={(e) => handleChange(e)}
        />
      </div>
    </>
  );
};

export default TransferableUnit;
