import React, { useEffect, useState } from "react";
import BoxItem from "./BoxItem";
import {
  INSTITUTE_ID_ACADEMIC_SPORT,
  INSTITUTE_TYPE_ID_FOOTBALL,
} from "utils/constants";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions as courseActions } from "redux/Course/action";

const BoxToBox = ({ contentEditable, player, setPlayer }) => {
  const dispatch = useDispatch();
  const [collapsible, setCollapsible] = useState(false);
  const [openId, setOpenId] = useState("");
  const { user } = useSelector((state) => state.user);
  let { clubs } = useSelector((state) => state.club);
  const { institutionTypes } = useSelector((state) => state.course);
  const params = useParams();
  let clubData =
    params?.id &&
    clubs?.find((club) => {
      return params?.id === club?.id;
    });

  const handleCallBack = (id, data) => {
    player.boxToBox[id] = data;
    setPlayer(player);
  };

  const handleCollapsible = () => {
    setCollapsible(!collapsible);
    const contents = document.getElementsByClassName("boxToBoxContent");
    Array.from(contents).forEach((content) => {
      if (content.style.maxHeight) {
        content.style.maxHeight = null;
      } else {
        content.style.maxHeight = content.scrollHeight + 30 + "px";
      }
    });
  };

  // console.log(
  //   institutionTypes
  //     ? institutionTypes.find((data) =>
  //         data.id === user?.club
  //           ? user?.club?.instituteTypeId
  //           : clubData?.instituteTypeId
  //       )?.name
  //     : ""
  // );

  useEffect(() => {
    if (user?.role === "tutor") {
      dispatch(
        courseActions.getInstitutionTypes({
          instituteId: user?.club?.instituteId,
        })
      );
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <>
      <div id="boxToBoxSection" className="">
        <div
          id="boxToBoxTitle"
          className={`sectionTitle collapsible ${collapsible ? "active2" : ""}`}
          onClick={handleCollapsible}
        >
          {(params?.id &&
            clubData?.instituteTypeId === INSTITUTE_TYPE_ID_FOOTBALL) ||
          user?.club?.instituteTypeId === INSTITUTE_TYPE_ID_FOOTBALL
            ? "Box to Box"
            : "CV Completion Status"}
        </div>
        <div className="boxToBoxContent content">
          <div className="boxRow">
            <BoxItem
              title="Profile"
              id="profile"
              handleCallBack={handleCallBack}
              contentEditable={contentEditable}
              value={player?.boxToBox?.profile}
              setOpenCollapseId={(id) => setOpenId(id)}
              addOpenClass={openId === "profile"}
            />
            <BoxItem
              title="Education"
              id="education"
              handleCallBack={handleCallBack}
              contentEditable={contentEditable}
              value={player?.boxToBox?.education}
              setOpenCollapseId={(id) => setOpenId(id)}
              addOpenClass={openId === "education"}
            />
          </div>
        </div>

        <div className="boxToBoxContent content">
          <div className="boxRow">
            <BoxItem
              title="Personal Development"
              id="personalDevelopment"
              handleCallBack={handleCallBack}
              contentEditable={contentEditable}
              value={player?.boxToBox?.personalDevelopment}
              setOpenCollapseId={(id) => setOpenId(id)}
              addOpenClass={openId === "personalDevelopment"}
            />
            {(user?.club?.instituteId === INSTITUTE_ID_ACADEMIC_SPORT ||
              clubData?.instituteId === INSTITUTE_ID_ACADEMIC_SPORT) && (
              <BoxItem
                title={
                  institutionTypes
                    ? user?.club
                      ? institutionTypes.find(
                          (data) => data.id === user?.club?.instituteTypeId
                        )?.name
                      : institutionTypes.find(
                          (data) => data.id === clubData?.instituteTypeId
                        )?.name
                    : ""
                }
                id="football"
                handleCallBack={handleCallBack}
                contentEditable={contentEditable}
                value={player?.boxToBox?.football}
                setOpenCollapseId={(id) => setOpenId(id)}
                addOpenClass={openId === "football"}
              />
            )}
            {user?.club?.instituteId !== INSTITUTE_ID_ACADEMIC_SPORT &&
              clubData?.instituteId !== INSTITUTE_ID_ACADEMIC_SPORT && (
                <BoxItem
                  title="References"
                  id="references"
                  handleCallBack={handleCallBack}
                  contentEditable={contentEditable}
                  value={player?.boxToBox?.references}
                  setOpenCollapseId={(id) => setOpenId(id)}
                  addOpenClass={openId === "references"}
                />
              )}
          </div>
        </div>
        {(user?.club?.instituteId === INSTITUTE_ID_ACADEMIC_SPORT ||
          clubData?.instituteId === INSTITUTE_ID_ACADEMIC_SPORT) && (
          <div className="boxToBoxContent content">
            <div className="boxRow">
              <BoxItem
                title="References"
                id="references"
                handleCallBack={handleCallBack}
                contentEditable={contentEditable}
                value={player?.boxToBox?.references}
                setOpenCollapseId={(id) => setOpenId(id)}
                addOpenClass={openId === "references"}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BoxToBox;
