export const actions = {
  GET_CLUBS_REQUEST: "GET_CLUBS_REQUEST",
  GET_CLUBS_SUCCESS: "GET_CLUBS_SUCCESS",

  CREATE_CLUB_REQUEST: "CREATE_CLUB_REQUEST",
  CREATE_CLUB_SUCCESS: "CREATE_CLUB_SUCCESS",

  UPDATE_CLUB_REQUEST: "UPDATE_CLUB_REQUEST",
  UPDATE_CLUB_SUCCESS: "UPDATE_CLUB_SUCCESS",

  REMOVE_CLUB_REQUEST: "REMOVE_CLUB_REQUEST",
  REMOVE_CLUB_SUCCESS: "REMOVE_CLUB_SUCCESS",

  UNREMOVE_CLUB_REQUEST: "UNREMOVE_CLUB_REQUEST",
  UNREMOVE_CLUB_SUCCESS: "UNREMOVE_CLUB_SUCCESS",

  CATCH_ERROR: "CATCH_ERROR",

  createClub: (data, navigate) => {
    return {
      type: actions.CREATE_CLUB_REQUEST,
      data,
      navigate,
    };
  },

  updateClub: (data, alert = true) => {
    return {
      type: actions.UPDATE_CLUB_REQUEST,
      data,
      alert,
    };
  },

  getClubs: () => {
    return {
      type: actions.GET_CLUBS_REQUEST,
    };
  },

  // getAllPlayers: (data) => {
  //   return {
  //     type: actions.GET_ALL_PLAYERS_REQUEST,
  //     data,
  //   };
  // },
};
