import { all, fork, put, takeLatest } from "redux-saga/effects";
import { getQuery, postQuery, putQuery } from "utils/api";
import { Toast } from "utils/toast";
import { actions } from "./action";
import { actions as loaderAction } from "redux/Loader/action";

export function* createCourse() {
  yield takeLatest(
    actions.CREATE_COURSE_REQUEST,
    function* ({ data, navigate }) {
      try {
        const response = yield postQuery(`course`, data);
        if (!response.data.error) {
          yield put({
            type: actions.CREATE_COURSE_SUCCESS,
            payload: response.data.result,
          });
          Toast("success", "Course Created Successfully!");
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
          navigate && navigate(true);
          localStorage.setItem(
            "unit",
            JSON.stringify(response.data.result.course.unit)
          );
          // navigate(
          //   "/courses/course/" + response.data.result.course.qualificationId
          // );
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    }
  );
}

export function* updateCourse() {
  yield takeLatest(
    actions.UPDATE_COURSE_REQUEST,
    function* ({ data, navigate }) {
      try {
        const response = yield putQuery(`course`, data);
        if (!response.data.error) {
          yield put({
            type: actions.UPDATE_COURSE_SUCCESS,
            payload: response.data.result,
          });
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
          Toast("success", "Course Updated Successfully!");
          navigate && navigate(true);
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    }
  );
}

export function* addToMyCourse() {
  yield takeLatest(actions.ADD_TO_MY_COURSE_REQUEST, function* ({ data }) {
    try {
      const response = yield postQuery(`course`, data);
      if (!response.data.error) {
        yield put({
          type: actions.ADD_TO_MY_COURSE_SUCCESS,
          payload: response.data.result,
        });
        Toast("success", "Course Added Successfully!");
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    } catch (e) {
      console.log(e);
      yield put({
        type: actions.CATCH_ERROR,
        payload: true,
      });
      Toast("error", e.response.data.message);
      yield put({
        type: loaderAction.CLOSE_LOADER,
      });
    }
  });
}

export function* getInstitutions() {
  yield takeLatest(actions.GET_INSTITUTION_REQUEST, function* () {
    try {
      const response = yield getQuery(`institutions`);

      if (!response.data.error) {
        // response.data.result.data.club.sort((a, b) => {
        //   return b.isEnable - a.isEnable;
        // });
        // response.data.result.data.club.sort((a, b) => {
        //   return a.name.localeCompare(b.name);
        // });
        yield put({
          type: actions.GET_INSTITUTION_SUCCESS,
          payload: response.data.result,
        });
      }
    } catch (e) {
      console.log(e);
    }
  });
}

export function* getInstitutionTypes() {
  yield takeLatest(actions.GET_INSTITUTION_TYPES_REQUEST, function* ({ data }) {
    try {
      const response = yield getQuery(
        `institutionType?instituteId=${data.instituteId}`
      );

      if (!response.data.error) {
        // response.data.result.data.club.sort((a, b) => {
        //   return b.isEnable - a.isEnable;
        // });
        // response.data.result.data.club.sort((a, b) => {
        //   return a.name.localeCompare(b.name);
        // });
        yield put({
          type: actions.GET_INSTITUTION_TYPES_SUCCESS,
          payload: response.data.result,
        });
        if (data?.closeLoader !== true) {
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
        }
      }
    } catch (e) {
      console.log(e);
      yield put({
        type: loaderAction.CLOSE_LOADER,
      });
    }
  });
}

export function* getQualifications() {
  yield takeLatest(actions.GET_QUALIFICATION_REQUEST, function* ({ data }) {
    try {
      let endPoint = `qualifications?id=${data.id}`;
      if (data.instituteTypeId) {
        endPoint = `qualifications?instituteTypeId=${data.instituteTypeId}`;
      }
      if (data.instituteId && data.instituteTypeId) {
        endPoint = `qualifications?instituteId=${data.instituteId}&instituteTypeId=${data.instituteTypeId}`;
      }

      const response = yield getQuery(endPoint);

      if (!response.data.error) {
        // response.data.result.data.club.sort((a, b) => {
        //   return b.isEnable - a.isEnable;
        // });
        // response.data.result.data.club.sort((a, b) => {
        //   return a.name.localeCompare(b.name);
        // });
        yield put({
          type: actions.GET_QUALIFICATION_SUCCESS,
          payload: response.data.result,
        });
        if (!data.loader) {
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
        }
      }
    } catch (e) {
      console.log(e);
      yield put({
        type: loaderAction.CLOSE_LOADER,
      });
    }
  });
}

export function* getGrades() {
  yield takeLatest(actions.GET_GRADE_REQUEST, function* ({ data }) {
    try {
      // const response = yield getQuery(
      //   `grade?instituteId=${data.instituteId}&qualificationId=${data.qualificationId}`
      // );
      const response = yield getQuery(`grade`);

      if (!response.data.error) {
        // response.data.result.data.club.sort((a, b) => {
        //   return b.isEnable - a.isEnable;
        // });
        // response.data.result.data.club.sort((a, b) => {
        //   return a.name.localeCompare(b.name);
        // });
        let copyGrades = structuredClone(response.data.result);
        let suggestionGrades = response.data.result.filter((item) => {
          return (
            data.qualificationId === item.qualificationId &&
            data.instituteId === item.instituteId
          );
        });

        let lessSuggestionGrades = copyGrades.filter((item) => {
          return data.qualificationId !== item.qualificationId;
        });
        // eslint-disable-next-line
        let grades = [...suggestionGrades, ...lessSuggestionGrades];
        yield put({
          type: actions.GET_GRADE_SUCCESS,
          payload: {
            allGrades: copyGrades,
            suggestedGrades: suggestionGrades,
            unSuggestedGrades: lessSuggestionGrades,
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  });
}

export function* getCourses() {
  yield takeLatest(actions.GET_COURSE_REQUEST, function* ({ data }) {
    try {
     

      let endPoint = "courses";
      if (data.instituteTypeId) {
        endPoint = `courses?instituteTypeId=${data.instituteTypeId}`;
      }
      if (data.instituteId) {
        endPoint = `courses?instituteId=${data.instituteId}`;
      }
      if (data.qualificationId) {
        endPoint = `courses?qualificationId=${data.qualificationId}`;
      }
      if (data.userId) {
        endPoint = `courses?userId=${data.userId}`;
      }
      if (data.instituteTypeId && data.userId) {
        endPoint = `courses?userId=${data.userId}&instituteTypeId=${data.instituteTypeId}`;
      }

      if (data.instituteTypeId && data.createdBySuperAdmin) {
        endPoint = `courses?instituteTypeId=${data.instituteTypeId}&createdBySuperAdmin=${data.createdBySuperAdmin}`;
      }

      if (data.instituteTypeId && data.instituteId && data.userId) {
        endPoint = `courses?instituteTypeId=${data.instituteTypeId}&instituteId=${data.instituteId}&userId=${data.userId}`;
      }
      if (data.instituteTypeId && data.instituteId) {
        endPoint = `courses?instituteTypeId=${data.instituteTypeId}&instituteId=${data.instituteId}&showPublic=${data.showPublic}`;
      }

      if (
        data.instituteTypeId &&
        data.createdBySuperAdmin &&
        data.instituteId
      ) {
        endPoint = `courses?instituteTypeId=${data.instituteTypeId}&createdBySuperAdmin=${data.createdBySuperAdmin}`;
      }
      if (data.instituteTypeId && data.instituteId && data.qualificationId) {
        endPoint = `courses?instituteTypeId=${data.instituteTypeId}&instituteId=${data.instituteId}&qualificationId=${data.qualificationId}`;
      }
      if (
        data.instituteTypeId &&
        data.instituteId &&
        data.userId &&
        data.clubId
      ) {
        endPoint = `courses?instituteTypeId=${data.instituteTypeId}&instituteId=${data.instituteId}&userId=${data.userId}&clubId=${data.clubId}`;
      }
      // if (data.clubId && data.myCourses) {
      //   endPoint = `courses?clubId=${data.clubId}`;
      // }
      const response = yield getQuery(endPoint);

      if (!response.data.error) {
        // response.data.result.data.club.sort((a, b) => {
        //   return b.isEnable - a.isEnable;
        // });
        // response.data.result.data.club.sort((a, b) => {
        //   return a.name.localeCompare(b.name);
        // });
        yield put({
          type: actions.GET_COURSE_SUCCESS,
          payload: response.data.result,
        });
        if (data.myCourses) {
          yield put({
            type: actions.GET_MY_COURSE_SUCCESS,
            payload: response.data.result,
          });
        }
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    } catch (e) {
      console.log(e);
      yield put({
        type: loaderAction.CLOSE_LOADER,
      });
    }
  });
}

export function* getSingleCourse() {
  yield takeLatest(actions.GET_SINGLE_COURSE_REQUEST, function* ({ data }) {
    try {
      let endPoint = "course";
      if (data.id) {
        endPoint = `course?id=${data.id}`;
      }
      if (data.instituteId) {
        endPoint = `course?instituteId=${data.instituteId}`;
      }
      const response = yield getQuery(endPoint);

      if (!response.data.error) {
        // response.data.result.data.club.sort((a, b) => {
        //   return b.isEnable - a.isEnable;
        // });
        // response.data.result.data.club.sort((a, b) => {
        //   return a.name.localeCompare(b.name);
        // });
        yield put({
          type: actions.GET_SINGLE_COURSE_SUCCESS,
          payload: response.data.result,
        });
        localStorage.setItem("unit", JSON.stringify(response.data.result.unit));
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    } catch (e) {
      console.log(e);
      yield put({
        type: loaderAction.CLOSE_LOADER,
      });
    }
  });
}

export function* clearCourse() {
  yield takeLatest(actions.CLEAR_COURSE_REQUEST, function* () {
    try {
      yield put({
        type: actions.CLEAR_COURSE_SUCCESS,
        payload: null,
      });
    } catch (e) {
      console.log(e);
    }
  });
}

export function* clearCourses() {
  yield takeLatest(actions.CLEAR_COURSES_REQUEST, function* () {
    try {
      yield put({
        type: actions.CLEAR_COURSES_SUCCESS,
        payload: [],
      });
    } catch (e) {
      console.log(e);
    }
  });
}

export function* getUnits() {
  yield takeLatest(actions.GET_UNIT_REQUEST, function* ({ data }) {
    try {
      const response = yield getQuery(`unit?courseId=${data.courseId}`);

      if (!response.data.error) {
        // response.data.result.data.club.sort((a, b) => {
        //   return b.isEnable - a.isEnable;
        // });
        // response.data.result.data.club.sort((a, b) => {
        //   return a.name.localeCompare(b.name);
        // });
        yield put({
          type: actions.GET_UNIT_SUCCESS,
          payload: response.data.result,
        });
      }
    } catch (e) {
      console.log(e);
    }
  });
}

export function* createInstitutionType() {
  yield takeLatest(
    actions.CREATE_INSTITUTION_TYPE_REQUEST,
    function* ({ data, navigate }) {
      try {
        const response = yield postQuery(`instituteType`, data);
        if (!response.data.error) {
          yield put({
            type: actions.CREATE_INSTITUTION_TYPE_SUCCESS,
            payload: response.data.result,
          });
          // Toast("success", "Course Created Successfully!");
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
          navigate && navigate(false);
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    }
  );
}

export function* updateInstitutionType() {
  yield takeLatest(
    actions.UPDATE_INSTITUTION_TYPE_REQUEST,
    function* ({ data }) {
      try {
        const response = yield putQuery(`instituteType`, {
          ...data,
          id: data.id,
          name: data.name,
        });
        if (!response.data.error) {
          yield put({
            type: actions.UPDATE_INSTITUTION_TYPE_SUCCESS,
            payload: response.data.result,
          });
          // Toast("success", "Course Created Successfully!");
          // yield put({
          //   type: loaderAction.CLOSE_LOADER,
          // });
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
        // yield put({
        //   type: loaderAction.CLOSE_LOADER,
        // });
      }
    }
  );
}

export function* createQualification() {
  yield takeLatest(
    actions.CREATE_QUALIFICATION_REQUEST,
    function* ({ data, navigate }) {
      try {
        const response = yield postQuery(`qualification`, data);
        if (!response.data.error) {
          yield put({
            type: actions.CREATE_QUALIFICATION_SUCCESS,
            payload: response.data.result,
          });
          // Toast("success", "Course Created Successfully!");
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });

          navigate && navigate(false);
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    }
  );
}

export function* updateQualification() {
  yield takeLatest(actions.UPDATE_QUALIFICATION_REQUEST, function* ({ data }) {
    try {
      const response = yield putQuery(`qualification`, {
        ...data,
        id: data.id,
        name: data.name,
      });
      if (!response.data.error) {
        yield put({
          type: actions.UPDATE_QUALIFICATION_SUCCESS,
          payload: response.data.result,
        });
        // Toast("success", "Course Created Successfully!");
        // yield put({
        //   type: loaderAction.CLOSE_LOADER,
        // });
      }
    } catch (e) {
      console.log(e);
      yield put({
        type: actions.CATCH_ERROR,
        payload: true,
      });
      Toast("error", e.response.data.message);
      // yield put({
      //   type: loaderAction.CLOSE_LOADER,
      // });
    }
  });
}

export function* createGrade() {
  yield takeLatest(
    actions.CREATE_GRADE_STRUCTURE_REQUEST,
    function* ({ data }) {
      try {
        const response = yield postQuery(`grade`, data);
        if (!response.data.error) {
          yield put({
            type: actions.CREATE_GRADE_STRUCTURE_SUCCESS,
            payload: response.data.result,
          });
          Toast("success", "Grade Created Successfully!");
          yield put({
            type: loaderAction.CLOSE_LOADER,
          });
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: actions.CATCH_ERROR,
          payload: true,
        });
        Toast("error", e.response.data.message);
        yield put({
          type: loaderAction.CLOSE_LOADER,
        });
      }
    }
  );
}

export function* addToPreviousFilterCorse() {
  yield takeLatest(
    actions.ADD_TO_PREVIOUS_FILTER_COURSE_REQUEST,
    function* ({ data }) {
      try {
        yield put({
          type: actions.ADD_TO_PREVIOUS_FILTER_COURSE_SUCCESS,
          payload: data,
        });
      } catch (e) {
        console.log(e);
      }
    }
  );
}

export default function* courseSaga() {
  yield all([
    fork(createCourse),
    fork(updateCourse),
    fork(getInstitutions),
    fork(getInstitutionTypes),
    fork(getQualifications),
    fork(getGrades),
    fork(getCourses),
    fork(getUnits),
    fork(getSingleCourse),
    fork(createInstitutionType),
    fork(createQualification),
    fork(createGrade),
    fork(addToMyCourse),
    fork(updateInstitutionType),
    fork(updateQualification),
    fork(clearCourse),
    fork(clearCourses),
    fork(addToPreviousFilterCorse),
  ]);
}
