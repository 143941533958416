import "./MyCourses.css";
import React, { useEffect } from "react";
import { CourseCardInfo } from "components/CourseCard";
import { useDispatch } from "react-redux";
import { actions as courseActions } from "redux/Course/action";

const MyCourses = ({ playerCentre, player, setPlayerCentre }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(courseActions.getInstitutions());
    // dispatch(
    //   courseActions.getCourses({
    //     instituteId: user?.club?.instituteId,
    //     instituteTypeId: user?.club?.instituteTypeId,
    //     userId: user?.id,
    //   })
    // );
  }, [dispatch]);

  return (
    <>
      <CourseCardInfo
        playerCentre={playerCentre}
        setPlayerCentre={setPlayerCentre}
      />
    </>
  );
};

export default MyCourses;
