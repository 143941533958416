import ControlButton from "components/ControlButton";
import React, { useContext } from "react";
import { actions as cvActions } from "redux/CV/action";
import { useDispatch } from "react-redux";
import "./MainControlButton.css";
import { UserContext } from "components/contexts/User.context";
import CryptoJS from "crypto-js";
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";

const MainControlButton = ({
  playerCentre,
  player,
  setPlayerCentre,
  setMessageSection,
  messageSection,
  workExperience,
  setWorkExperience,
}) => {
  const dispatch = useDispatch();
  const { user: loggedUser } = useSelector((state) => state.user);
  const { players } = useSelector((state) => state.players);
  const { user } = useContext(UserContext);

  let token = localStorage.getItem("TOKEN");

  let decoded;
  if (token) {
    decoded = jwt_decode(token);
  }

  const handleClick = () => {
    setMessageSection(!messageSection);
    setWorkExperience(false);
  };

  const encryptJson = (content) =>
    CryptoJS.AES.encrypt(content, "routes-control-centre").toString();

  const viewCV = () => {
    // window.open(
    //   `${process.env.REACT_APP_CV_TEMPLATE_BASE_URL}${
    //     player?.id
    //   }?expiry=null&type=${encryptJson("admin")}`
    // );
    // const encryptedUrl = encryptJson("admin")
    //   .replaceAll("+", "xMl3JkxMl3Jk")
    //   .replaceAll("/", "Por21LdPor21Ld")
    //   .replaceAll("=", "Ml32Ml32");

    // window.open(
    //   `${process.env.REACT_APP_CV_TEMPLATE_BASE_URL}${player?.id}?expiry=1&type=${encryptedUrl}`
    // );
    dispatch(cvActions.viewCV({ email: player?.email }));
  };

  const viewAllCV = () => {
    // window.open(
    //   `${process.env.REACT_APP_CV_TEMPLATE_BASE_URL}${
    //     player?.id
    //   }?expiry=null&type=${encryptJson("admin")}`
    // );
    // const encryptedUrl = encryptJson("admin")
    //   .replaceAll("+", "xMl3JkxMl3Jk")
    //   .replaceAll("/", "Por21LdPor21Ld")
    //   .replaceAll("=", "Ml32Ml32");

    // window.open(
    //   `${process.env.REACT_APP_CV_TEMPLATE_BASE_URL}${player?.id}?expiry=1&type=${encryptedUrl}`
    // );
    dispatch(
      cvActions.viewAllCV(
        players?.map((player) => {
          return { id: player?.id };
        })
      )
    );
  };

  // useEffect(() => {
  //   if (player) {
  //     setMessageHistory(false);
  //   }
  // }, [player]);

  return (
    <div className="MainControlButton">
      <div id="controlButtons" className="subControlBtn">
        {playerCentre === "viewPlayer" && (
          <>
            <ControlButton
              id="workExperience"
              className={`controlBtn m-0 click control-center-btn ${
                playerCentre === "viewPlayer" ? "" : "disable"
              }`}
              onClick={() => {
                setWorkExperience(!workExperience);
                setMessageSection(false);
              }}
              label="Work Experience"
            />
            <ControlButton
              id="viewCv"
              className={`controlBtn m-0 click control-center-btn ${
                playerCentre === "viewPlayer" ? "" : "disable"
              }`}
              onClick={viewCV}
              label="View CV"
            />
            {/* <ControlButton
              id="playerConfig"
              className={`controlBtn m-0 click control-center-btn ${
                playerCentre === "viewPlayer" ? "" : "disable"
              }`}
              // label="Player Config"
              label={`View All CV`}
              onClick={viewAllCV}
            /> */}
            <ControlButton
              id="messagePlayer"
              className={`controlBtn m-0 click control-center-btn ${
                playerCentre === "viewPlayer" ? "" : "disable"
              }`}
              label="Message"
              onClick={() => handleClick()}
            />
            {/* <ControlButton
              id="playerConfig"
              className="controlBtn m-0 click control-center-btn disable"
              // label="Player Config"
              label={`${user} Config`}
            /> */}
          </>
        )}

        {decoded?.role !== "tutor" && loggedUser?.role !== "tutor" && (
          <div className="dropdown MainBtnDropDown">
            <button
              className="dropbutton controlBtn click"
              onClick={() => {
                setPlayerCentre("myCourseCenter");
              }}>
              Courses
            </button>
            <div className="dropdown-content">
              <button
                // id="controlButtons"
                id="ViewExisting"
                className="dropdownbtn controlBtn click subDropDown"
                onClick={() => {
                  setPlayerCentre("courseCenter");
                }}
                style={{ fontSize: "15px" }}>
                View available
              </button>
              <button
                // id="controlButtons"
                className="dropdownbtn controlBtn click "
                onClick={() => {
                  setPlayerCentre("createCourseCenter");
                }}>
                Create New
              </button>
              <button
                // id="controlButtons"
                className="dropdownbtn controlBtn click "
                onClick={() => {
                  setPlayerCentre("workExperienceCenter");
                }}>
                Work Experience
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainControlButton;
