export const actions = {
  GET_PROFILE_FIELDS_REQUEST: "GET_PROFILE_FIELDS_REQUEST",
  GET_PROFILE_FIELDS_SUCCESS: "GET_PROFILE_FIELDS_SUCCESS",

  CREATE_PROFILE_FIELD_REQUEST: "CREATE_PROFILE_FIELD_REQUEST",
  CREATE_PROFILE_FIELD_SUCCESS: "CREATE_PROFILE_FIELD_SUCCESS",

  UPDATE_PROFILE_FIELD_REQUEST: "UPDATE_PROFILE_FIELD_REQUEST",
  UPDATE_PROFILE_FIELD_SUCCESS: "UPDATE_PROFILE_FIELD_SUCCESS",

  DELETE_PROFILE_FIELD_REQUEST: "DELETE_PROFILE_FIELD_REQUEST",
  DELETE_PROFILE_FIELD_SUCCESS: "DELETE_PROFILE_FIELD_SUCCESS",

  GET_DYNAMIC_PROFILE_FIELDS_REQUEST: "GET_DYNAMIC_PROFILE_FIELDS_REQUEST",
  GET_DYNAMIC_PROFILE_FIELDS_SUCCESS: "GET_DYNAMIC_PROFILE_FIELDS_SUCCESS",

  CREATE_NEW_DYNAMIC_PROFILE_REQUEST: "CREATE_NEW_DYNAMIC_PROFILE_REQUEST",
  CREATE_NEW_DYNAMIC_PROFILE_SUCCESS: "CREATE_NEW_DYNAMIC_PROFILE_SUCCESS",

  CREATE_DYNAMIC_PROFILE_FIELD_REQUEST: "CREATE_DYNAMIC_PROFILE_FIELD_REQUEST",
  CREATE_DYNAMIC_PROFILE_FIELD_SUCCESS: "CREATE_DYNAMIC_PROFILE_FIELD_SUCCESS",

  UPDATE_DYNAMIC_PROFILE_FIELD_REQUEST: "UPDATE_DYNAMIC_PROFILE_FIELD_REQUEST",
  UPDATE_DYNAMIC_PROFILE_FIELD_SUCCESS: "UPDATE_DYNAMIC_PROFILE_FIELD_SUCCESS",

  DELETE_DYNAMIC_PROFILE_FIELD_REQUEST: "DELETE_DYNAMIC_PROFILE_FIELD_REQUEST",
  DELETE_DYNAMIC_PROFILE_FIELD_SUCCESS: "DELETE_DYNAMIC_PROFILE_FIELD_SUCCESS",

  GET_ORGANIZATION_PROFILE_FIELDS_REQUEST:
    "GET_ORGANIZATION_PROFILE_FIELDS_REQUEST",
  GET_ORGANIZATION_PROFILE_FIELDS_SUCCESS:
    "GET_ORGANIZATION_PROFILE_FIELDS_SUCCESS",

  CREATE_NEW_ORGANIZATION_PROFILE_REQUEST:
    "CREATE_NEW_ORGANIZATION_PROFILE_REQUEST",
  CREATE_NEW_ORGANIZATION_PROFILE_SUCCESS:
    "CREATE_NEW_ORGANIZATION_PROFILE_SUCCESS",

  CREATE_ORGANIZATION_PROFILE_FIELD_REQUEST:
    "CREATE_ORGANIZATION_PROFILE_FIELD_REQUEST",
  CREATE_ORGANIZATION_PROFILE_FIELD_SUCCESS:
    "CREATE_ORGANIZATION_PROFILE_FIELD_SUCCESS",

  UPDATE_ORGANIZATION_PROFILE_FIELD_REQUEST:
    "UPDATE_ORGANIZATION_PROFILE_FIELD_REQUEST",
  UPDATE_ORGANIZATION_PROFILE_FIELD_SUCCESS:
    "UPDATE_ORGANIZATION_PROFILE_FIELD_SUCCESS",

  DELETE_ORGANIZATION_PROFILE_FIELD_REQUEST:
    "DELETE_ORGANIZATION_PROFILE_FIELD_REQUEST",
  DELETE_ORGANIZATION_PROFILE_FIELD_SUCCESS:
    "DELETE_ORGANIZATION_PROFILE_FIELD_SUCCESS",

  GET_PRIORITY_PROFILE_FIELDS_REQUEST: "GET_PRIORITY_PROFILE_FIELDS_REQUEST",
  GET_PRIORITY_PROFILE_FIELDS_SUCCESS: "GET_PRIORITY_PROFILE_FIELDS_SUCCESS",

  ADD_PRIORITY_PROFILE_FIELDS_SUCCESS: "ADD_PRIORITY_PROFILE_FIELDS_SUCCESS",
  REMOVE_PRIORITY_PROFILE_FIELDS_SUCCESS:
    "REMOVE_PRIORITY_PROFILE_FIELDS_SUCCESS",

  UPDATE_PRIORITY_PROFILE_FIELDS_REQUEST:
    "UPDATE_PRIORITY_PROFILE_FIELDS_REQUEST",
  UPDATE_PRIORITY_PROFILE_FIELDS_SUCCESS:
    "UPDATE_PRIORITY_PROFILE_FIELDS_SUCCESS",

  CATCH_ERROR: "CATCH_ERROR",

  getProfilesFields: () => {
    return {
      type: actions.GET_PROFILE_FIELDS_REQUEST,
    };
  },

  createProfileField: (data, navigate) => {
    return {
      type: actions.CREATE_PROFILE_FIELD_REQUEST,
      data,
      navigate,
    };
  },

  updateProfileField: (data) => {
    return {
      type: actions.UPDATE_PROFILE_FIELD_REQUEST,
      data,
    };
  },

  deleteProfileField: (data) => {
    return {
      type: actions.DELETE_PROFILE_FIELD_REQUEST,
      data,
    };
  },

  getDynamicProfilesFields: (data) => {
    return {
      type: actions.GET_DYNAMIC_PROFILE_FIELDS_REQUEST,
      data,
    };
  },

  createNewDynamicProfile: (data, navigate) => {
    return {
      type: actions.CREATE_NEW_DYNAMIC_PROFILE_REQUEST,
      data,
      navigate,
    };
  },

  createDynamicProfileField: (data, navigate) => {
    return {
      type: actions.CREATE_DYNAMIC_PROFILE_FIELD_REQUEST,
      data,
      navigate,
    };
  },

  updateDynamicProfileField: (data) => {
    return {
      type: actions.UPDATE_DYNAMIC_PROFILE_FIELD_REQUEST,
      data,
    };
  },

  deleteDynamicProfileField: (data) => {
    return {
      type: actions.DELETE_DYNAMIC_PROFILE_FIELD_REQUEST,
      data,
    };
  },

  getOrganizationProfilesFields: (data) => {
    return {
      type: actions.GET_ORGANIZATION_PROFILE_FIELDS_REQUEST,
      data,
    };
  },

  createNewOrganizationProfile: (data, navigate) => {
    return {
      type: actions.CREATE_NEW_ORGANIZATION_PROFILE_REQUEST,
      data,
      navigate,
    };
  },

  createOrganizationProfileField: (data, navigate) => {
    return {
      type: actions.CREATE_ORGANIZATION_PROFILE_FIELD_REQUEST,
      data,
      navigate,
    };
  },

  updateOrganizationProfileField: (data) => {
    return {
      type: actions.UPDATE_ORGANIZATION_PROFILE_FIELD_REQUEST,
      data,
    };
  },

  deleteOrganizationProfileField: (data) => {
    return {
      type: actions.DELETE_ORGANIZATION_PROFILE_FIELD_REQUEST,
      data,
    };
  },

  getPriorityProfilesFields: (data) => {
    return {
      type: actions.GET_PRIORITY_PROFILE_FIELDS_REQUEST,
      data,
    };
  },

  updatePriorityProfilesFields: (data) => {
    return {
      type: actions.UPDATE_PRIORITY_PROFILE_FIELDS_REQUEST,
      data,
    };
  },
};
