import React, { useEffect, useState } from "react";
import Header from "components/Header";
import Navbar from "components/Navbar";
import PlayersCard from "components/PlayersCard";
import { useDispatch, useSelector } from "react-redux";
import { actions as playersAction } from "redux/Player/action";
import jwt_decode from "jwt-decode";

const Players = () => {
  const dispatch = useDispatch();
  // const { allPlayers } = useSelector((state) => state.players);
  const { user } = useSelector((state) => state.user);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [selectedClubs, setSelectedClubs] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [operation, setOperation] = useState("and");
  const [selectedFilter, setSelectedFilter] = useState([
    {
      id: "aa8bae6a-b686-7be4-7558-65fe9174d006",
      status: [],
      startDate: null,
      endDate: null,
      positions: [],
      clubs: [],
    },
  ]);
  const [players, setPlayers] = useState([]);
  const token = localStorage.getItem("TOKEN");
  let decoded;
  if (token) {
    decoded = jwt_decode(token);
  }

  useEffect(() => {
    setPlayers([]);
    // user?.club?.id &&
    //   dispatch(
    //     actions.getAllPlayers({
    //       clubId: user.club.id,
    //       keyword: "",
    //       skip: 0,
    //       body: null,
    //     })
    //   );
    dispatch(playersAction.getClub());
    dispatch(
      playersAction.getArchivedPlayers({
        // clubId: user?.club?.id,
        keyword: "",
        skip: 0,
        body: { isArchived: true },
      })
    );
    // eslint-disable-next-line
  }, [user, dispatch]);

  return (
    <>
      <Navbar />
      <Header
        page="players"
        selectedPositions={selectedPositions}
        setSelectedPositions={setSelectedPositions}
        selectedClubs={selectedClubs}
        setSelectedClubs={setSelectedClubs}
        keyword={keyword}
        setKeyword={setKeyword}
        setPlayers={setPlayers}
        operation={operation}
        setOperation={setOperation}
        setSelectedFilter={setSelectedFilter}
        selectedFilter={selectedFilter}
      />
      {(user?.club || decoded?.role === "admin") && (
        <PlayersCard
          page="players"
          selectedPositions={selectedPositions}
          selectedClubs={selectedClubs}
          keyword={keyword}
          players={players}
          setPlayers={setPlayers}
          operation={operation}
          selectedFilter={selectedFilter}
        />
      )}
    </>
  );
};

export default Players;
