import React, { useEffect, useState } from "react";
import Button from "components/Button";
import Heading from "components/Heading";
import Input from "components/Input";
import Navbar from "components/Navbar";
import "components/CustomLogin/CustomLogin.css";
import FeatherIcon from "feather-icons-react";
//redux
import axios from "axios";
import { Toast } from "utils/toast";
import { useNavigate, useParams } from "react-router-dom";

function ResetPassword() {
  const [passwordData, setPasswordData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const [viewPassword, setViewPassword] = useState(false);
  const [passwordValid, setPasswordValid] = useState(true);
  const [validToken, setValidToken] = useState(false);
  const [loading, setLoading] = useState(true);
  const { userId, token } = useParams();
  const navigate = useNavigate();

  const handleChange = (event) => {
    setPasswordValid(checkPassword(event.target.value));
    setPasswordData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  function checkPassword(str) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&`:|.;*])(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
    return re.test(str);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = !passwordData?.password || !passwordData?.confirmPassword;
    if (errors) {
      setError("Email is required!");
    } else {
      if (passwordData?.confirmPassword === passwordData?.password) {
        delete !passwordData?.confirmPassword;
        axios
          .post(`/password-reset/${userId}/${token}`, passwordData)
          .then((res) => {
            if (!res.data.error)
              Toast("success", "Password Reset Successfully!");
            navigate("/auth/login");
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setError("Password does't match!");
      }
    }
  };

  useEffect(() => {
    axios
      .post(`/password-reset/token/valid/${userId}/${token}`)
      .then((res) => {
        if (!res.data.error) {
          setValidToken(true);
          setLoading(false);
        } else {
          setValidToken(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setValidToken(false);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Navbar page="login" />
      <div id="controlCentreContainer" className="routes_background">
        <div id="playerCentre" className="loginSection">
          {validToken && !loading && (
            <div className="shadow-sm rounded-2 w-50 p-5 my-5 bg-light mx-auto loginFormDiv">
              <Heading
                type="h3"
                label="Reset Password"
                className="alternate-gothi text-center"
              />
              <p className="text-muted text-center mb-4 poppins">
                Enter a new password
              </p>

              <form onSubmit={handleSubmit}>
                <div id="createSection" className="poppins">
                  <div className="createField" style={{ fontSize: "18px" }}>
                    <p>New Password</p>
                    <div className="input-group mt-2">
                      <input
                        type={viewPassword ? "text" : "password"}
                        className="form-control border-end-0"
                        name="password"
                        // placeholder="Password"
                        value={passwordData?.password}
                        // onChange={(e) => handleChange(e)}
                        onChange={handleChange}
                        maxLength="16"
                      />
                      <span className="input-group-text bg-white border-start-0 text-secondary">
                        <FeatherIcon
                          icon={!viewPassword ? "eye" : "eye-off"}
                          size={20}
                          onClick={() => setViewPassword(!viewPassword)}
                        />
                      </span>
                    </div>
                  </div>

                  <div className="w-100">
                    <Input
                      type="password"
                      className="inputField mt-2 px-2"
                      id="CPassword"
                      name="confirmPassword"
                      label="Confirm Password"
                      divStyle={{ fontSize: "18px" }}
                      value={passwordData?.confirmPassword}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {!passwordValid && (
                    <p
                      style={{ fontSize: "small" }}
                      className="fw-bold text-danger"
                    >
                      Your Password is must include at least one uppercase, one
                      lowercase, one special character and 8 to 16 characters
                      long.
                    </p>
                  )}
                  <p
                    id="login_validation"
                    style={{ color: "red", fontSize: "small" }}
                    className="fw-bold"
                  >
                    {error}
                  </p>
                  <Button
                    className="buttonClass greenButton mt-3 w-100"
                    label="Reset Password"
                  />
                </div>
              </form>
            </div>
          )}
          {!validToken && !loading && (
            <div className="shadow-sm rounded-2 w-50 p-5 my-5 bg-light mx-auto loginFormDiv">
              <Heading
                type="h3"
                label="Reset Password"
                className="alternate-gothi text-center"
              />
              <p className="text-center mb-4 poppins text-muted">
                Sorry, your reset password link has been expired. You can
                request another one below.
              </p>
              <Button
                className="buttonClass greenButton mt-3 w-100"
                label="Reset Password"
                handleClick={() => {
                  navigate("/auth/forgot-password");
                }}
              />
            </div>
          )}
          {loading && (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
