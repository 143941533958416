import ControlButton from "components/ControlButton";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React, { useState } from "react";
import "./NewCategory.css";
import { useSelector } from "react-redux";
import Button from "components/Button";

const NewCategory = ({
  setNewCategory,
  onSave,
  label,
  buttonLabel,
  error,
  setError,
  data,
  gradOption,
  setGradOption,
  gradingValue,
  setGradingValue,
  setDisableSave,
}) => {
  const { networkProgressDialog } = useSelector((state) => state.loader);
  const [category, setCategory] = useState({
    name: "",
  });
  // eslint-disable-next-line
  const [inputDisable, setInputDisable] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  const handleChangeCategory = (event) => {
    setCategory((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    setError({ message: "" });
    setDisableSaveButton(true);
  };

  const handleChangeGrading = (event) => {
    const CategoryIndex = gradOption?.findIndex((a) => a.id === data?.id);
    gradOption[CategoryIndex].name = event.target.value;
    gradingValue[CategoryIndex].value = event.target.value;
    setGradOption([...gradOption]);
    setDisableSave(true);
  };

  const handleRemove = () => {
    const GradingIndex = gradOption?.filter((a) => a.id !== data?.id);
    setGradOption(GradingIndex);
    if (GradingIndex?.length <= 0) {
      setDisableSave(false);
    }
  };

  return (
    <div className="newCategory">
      {label && <p className="CategoryTitle">{label}</p>}
      <div className="SaveButton">
        {!buttonLabel && (
          <FeatherIcon
            className={"Chevron_Right"}
            icon={`chevron-right`}
            size={35}
            strokeWidth={1}
          />
        )}

        {!gradOption && (
          <input
            // className="categoryInput"
            className={`categoryInput ${
              inputDisable ? "categoryDisabled" : ""
            }`}
            type="text"
            name="name"
            value={category?.name}
            onChange={handleChangeCategory}
            disabled={inputDisable}
          />
        )}

        {gradOption && (
          <input
            // className="categoryInput"
            className={`categoryInput ${
              inputDisable ? "categoryDisabled" : ""
            }`}
            type="text"
            name="name"
            value={gradingValue?.name}
            onChange={handleChangeGrading}
            disabled={inputDisable}
          />
        )}
        {!gradOption && (
          <>
            {!networkProgressDialog ? (
              <>
                <Button
                  // className="SaveControlBtn click"
                  className={`SaveControlBtn click ${
                    disableSaveButton === false ? "disabled" : ""
                  }`}
                  label={"SAVE"}
                  handleClick={() => {
                    onSave(category);
                  }}
                  disabled={
                    disableSaveButton === false
                      ? !disableSaveButton
                      : !disableSaveButton
                  }
                />
              </>
            ) : (
              <>
                <button className="categoryLoader">
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              </>
            )}
            {/* {!networkProgressDialog && (
              <ControlButton
                // className="SaveControlBtn click"
                className={`SaveControlBtn click ${
                  disableSaveButton === false ? "disabled" : ""
                }`}
                label={"SAVE"}
                onClick={() => {
                  onSave(category);
                }}
                disabled={
                  disableSaveButton === false
                    ? !disableSaveButton
                    : !disableSaveButton
                }
              />
            )}
            {networkProgressDialog && (
              <button className="categoryLoader">
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            )} */}
          </>
        )}

        {/* {!inputDisable && gradOption ? (
          <ControlButton
            className="SaveControlBtn click"
            label={buttonLabel ? buttonLabel : "SAVE"}
            onClick={() => {
              handleControlBtn();
            }}
          />
        ) : (
          gradOption && (
            <ControlButton
              className="EditControlBtn click"
              // label={buttonLabel ? buttonLabel : "SAVE"}
              label="Edit"
              onClick={handleEditBtn}
            />
          )
        )} */}

        {gradOption && (
          <ControlButton
            className="EditControlBtn click"
            label="✖"
            onClick={handleRemove}
          />
        )}
      </div>

      {gradOption ? (
        <>
          {error.id === data?.id && error?.message && (
            <p
              id="login_validation"
              style={{ fontSize: "small" }}
              className="text-danger mt-1 position-absolute"
            >
              {error.message}
            </p>
          )}
        </>
      ) : (
        <>
          {error?.message && (
            <p
              id="login_validation"
              style={{ fontSize: "small" }}
              className="text-danger mt-1 ml-5 position-absolute CategoryError"
            >
              {error.message}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default NewCategory;
