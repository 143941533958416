import Heading from "components/Heading";
import Navbar from "components/Navbar";
import React from "react";
import FeatherIcon from "feather-icons-react";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";

const Blocked = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Navbar page="login" />
      <div id="controlCentreContainer" className="routes_background">
        <div id="playerCentre" className="loginSection">
          <div className="shadow-sm rounded-2 w-50 p-5 my-5 bg-light mx-auto loginFormDiv">
            <div className="text-center">
              <FeatherIcon
                icon="alert-triangle"
                size={50}
                className="text-danger mb-3"
              />
            </div>
            <Heading
              type="h3"
              label="Account Blocked"
              className="alternate-gothi text-center text-danger"
            />

            <p className="text-dark text-center mb-4 poppins">
              Your account has been blocked. Contact your support person to
              unlock it, then try again.
            </p>

            <Button
              className="buttonClass greenButton mt-3 w-100 p-2"
              label="Go Back"
              handleClick={() => {
                navigate("/auth/login");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blocked;
